import data from '@emoji-mart/data';
import { Picker } from 'emoji-mart';
import { Plus, SmilePlus, X } from 'lucide-preact';
import { useEffect, useRef, useState } from 'preact/hooks';

import { formatDate } from '../../utils/index';
import shortnameToUnicode from '../Emoji/shortnameToUnicode';

export function isJsonString(str: any) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const EmojiPicker = (props: any) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    new Picker({ ...props, data, ref });
  }, [props]);

  return <div ref={ref} />;
};

interface IDataMessageType {
  id?: string;
  avatar?: string;
  content: string;
  createdAt?: string;
  from?: string;
  role?: string;
  attachments: any[];
}

const BoxChat = (props: any) => {
  const { socket } = props;
  const [show, setShow] = useState(false);
  const [dataMessages, setDataMessage] = useState<IDataMessageType[]>([]);
  const [textMessage, setTextMessage] = useState<string>('');
  const handleShowEmoji = () => {
    setShow(!show);
  };

  const handleSendMessage = (e: any) => {
    const file = e?.target?.files?.[0];
    if (!textMessage && !file) return;
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      socket.emit('send_message_from_livechat', { message: textMessage });
      setTextMessage('');
    }
    if (e?.target?.files?.[0]) {
      const file = e.target.files[0];
      socket.emit('send_message_from_livechat', {
        file: file,
        fileName: file.name,
        mineType: file.type,
      });
      setTextMessage('');
      e.target.value = '';
    }
  };

  useEffect(() => {
    socket?.on('new_message_conversation', async (message: any) => {
      setDataMessage((prev) => [...prev, message]);
    });
  }, [socket]);

  return (
    <div
      id="app"
      className="h-[calc(100vh - 113px)] fixed bottom-16 right-8 z-[20000] hidden max-h-[620px] w-[381px] rounded-lg bg-white"
    >
      <div className="boxchat flex h-full w-full flex-1 flex-col">
        <header className="boxchat-header flex flex-row items-center justify-between border-b-[1px] border-b-[#d1cece] p-4">
          <div className="title flex flex-auto flex-row items-center space-x-2">
            <div className="avatar h-10 w-10 flex-none cursor-pointer">
              <img
                alt="Avatar"
                className="h-full w-full rounded-full"
                src="https://media.truyenhinhdulich.vn/upload/news/7735_cuoc_thi_bao_chi_viet_ve_du_lich_nghe_an.jpg"
              />
            </div>
            <div className="title-content font-semibold">
              FCare chăm sóc khách hàng
            </div>
          </div>
          <div className="iconX flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-[#f3efef] text-black">
            <X color="black" size={19} />
          </div>
        </header>
        <section className="boxchat-content flex h-full w-full flex-auto flex-col justify-between">
          <div className="main-content max-h-[453px] min-h-[453px] flex-1 overflow-y-scroll p-4">
            {dataMessages?.length ? (
              dataMessages.map((item) => {
                if (item?.role === 'agent') {
                  return (
                    <div
                      key={item.id}
                      className="message-content-left anchor-none mb-6 flex w-full flex-auto flex-row items-start space-x-2"
                    >
                      <div className="avatar h-7 w-7 flex-none cursor-pointer">
                        <img
                          alt="Avatar"
                          className="h-full w-full rounded-full"
                          src="https://media.truyenhinhdulich.vn/upload/news/7735_cuoc_thi_bao_chi_viet_ve_du_lich_nghe_an.jpg"
                        />
                      </div>
                      <div className="main-message relative w-[70%] rounded-r-lg rounded-bl-lg bg-[#f0eded] p-3">
                        {item?.content && <>{item.content}</>}
                        {item?.attachments.map((i, index) => {
                          const attach = isJsonString(i) ? JSON.parse(i) : i;
                          if (attach?.type == 'image') {
                            return (
                              <img key={index} src={attach?.payload?.url} />
                            );
                          }
                          if (attach?.type == 'video') {
                            return (
                              <video key={index} src={attach?.payload?.url} />
                            );
                          }
                        })}
                        <p className="time-message absolute -bottom-4 right-1 text-xs opacity-80">
                          {formatDate(item.createdAt)}
                        </p>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={item.id}
                      className="message-content-right anchor-none mb-6 flex w-full flex-row-reverse items-start"
                    >
                      <div className="avatar ml-2 h-7 w-7 flex-none cursor-pointer">
                        <img
                          alt="Avatar"
                          className="h-full w-full rounded-full"
                          src="https://media.truyenhinhdulich.vn/upload/news/7735_cuoc_thi_bao_chi_viet_ve_du_lich_nghe_an.jpg"
                        />
                      </div>
                      <div className="main-message relative w-[70%] rounded-b-lg rounded-tl-lg bg-[#fac384] p-3">
                        {item?.content && <>{item.content}</>}
                        {item?.attachments.map((i, index) => {
                          const attach = isJsonString(i) ? JSON.parse(i) : i;
                          if (attach?.type == 'image') {
                            return (
                              <img key={index} src={attach?.payload?.url} />
                            );
                          }
                          if (attach?.type == 'video') {
                            return (
                              <video key={index} src={attach?.payload?.url} />
                            );
                          }
                        })}
                        <p className="time-message absolute -bottom-4 left-1 text-xs opacity-80">
                          {formatDate(item.createdAt)}
                        </p>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <div>Không có dữ liệu</div>
            )}
            <div className="anchor"></div>
          </div>
          <div className="footer-content flex flex-row items-center justify-between border-t-[1px] border-t-[#d1cece] p-4">
            <div className="input flex h-full w-[90%] items-center space-x-2">
              <div className="icon cursor-pointer p-1">
                <SmilePlus onClick={handleShowEmoji} color="black" size={19} />
                {show && (
                  <div className="absolute bottom-16 left-5 max-h-[90%] max-w-[90%] ">
                    <EmojiPicker
                      sheetSize={64}
                      onClickOutside={handleShowEmoji}
                      onEmojiSelect={(e: any) => {
                        setTextMessage((prev) => prev + e.shortcodes);
                        handleShowEmoji();
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="input-content w-full">
                <input
                  placeholder="Bạn hỏi gì đó đi..."
                  onInput={(e) => {
                    setTextMessage((e.target as any).value);
                  }}
                  className="h-full w-full cursor-text resize-none break-words border-none px-1 py-2 text-sm font-medium leading-5 text-[#2f343d] outline-none focus:border-none"
                  value={shortnameToUnicode(textMessage)}
                  onKeyDown={(e) => handleSendMessage(e)}
                />
              </div>
            </div>
            <div className="upload relative flex h-7 w-7 cursor-pointer items-center justify-center p-1 text-lg font-extrabold">
              <Plus color="black" size={28} />
              <input
                type="file"
                id="file"
                className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
                onChange={(e) => handleSendMessage(e)}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BoxChat;
