const IconBoxChat = () => {
  return (
    <div
      className="quick-alo-phone quick-alo-green quick-alo-show"
      id="quick-alo-phoneIcon"
    >
      <div title="Liên hệ nhanh">
        <div className="quick-alo-ph-circle"></div>
        <div className="quick-alo-ph-circle-fill"></div>
        <div className="quick-alo-ph-img-circle"></div>
      </div>
    </div>
  );
};

export default IconBoxChat;
