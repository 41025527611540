import { useEffect, useState } from 'preact/hooks';
import type { Socket } from 'socket.io-client';
import io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

const token: string = window.localStorage.getItem('ACCESS_TOKEN') || '';
const domainServer = process.env.APP_URL_SERVER_SOCKET!;
const clientUrl = process.env.APP_URL_CLIENT_SOCKET;
const customerName = window.localStorage.getItem('username') || 'AdminTest';
const customerId = uuidv4();
const sessionId = `${customerName}_${new Date().getTime()}`;

function useSocket(projectId: string) {
  const [socket, setSocket] = useState<Socket>();
  useEffect(() => window.localStorage.setItem('AdminTest', 'AdminTest'));

  useEffect(() => {
    const socketIO = io(domainServer, {
      transports: ['websocket'],
      path: '',
      auth: { token },
      query: {
        clientUrl,
        customerName,
        projectId,
        customerId,
        sessionId,
        type: 'liveChatCustomer',
      },
    });
    socketIO.connect();

    socketIO.on('connect', () => {
      console.log('socket.connected', projectId);
      setSocket(socketIO);
    });

    socketIO.on('disconnect', () => {
      setSocket(undefined);
    });

    return () => {
      socketIO.off('connect');
      socketIO.off('disconnect');
    };
  }, []);

  return {
    socket,
    isConnected: socket?.connected,
    customerId,
  };
}

export default useSocket;
