import '../src/style/app.css';
import '../src/style/iconBoxChat.css';

import { useEffect } from 'preact/hooks';

import BoxChat from './components/BoxChat';
import IconBoxChat from './components/IconBoxChat';
import useSocket from './hooks/useSocket';

type Props = { projectId: string };
const App = ({ projectId }: Props) => {
  const { socket, customerId } = useSocket(projectId);

  document.addEventListener('DOMContentLoaded', () => {
    const elementApp = document.getElementById('app');
    const elementPhoneIcon = document.getElementById('quick-alo-phoneIcon');
    document
      .querySelector('#app .boxchat .iconX')
      ?.addEventListener('click', () => {
        if (elementApp && elementPhoneIcon) {
          elementApp.style.display = 'none';
          elementPhoneIcon.style.display = 'block';
        }
      });

    document
      .querySelector('.quick-alo-ph-img-circle')
      ?.addEventListener('click', () => {
        if (elementApp && elementPhoneIcon) {
          elementApp.style.display = 'block';
          elementPhoneIcon.style.display = 'none';
        }
      });
  });

  useEffect(() => {
    socket?.emit('join_conversation_chatting', customerId);
  }, [socket, customerId]);

  return (
    <div className="container">
      <IconBoxChat />
      <BoxChat socket={socket} customerId={customerId} />
    </div>
  );
};

export default App;
