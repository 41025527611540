export const formatDate = (date: any): string => {
  const dateString = new Date(date);
  const dates =
    dateString.getHours().toString().padStart(2, '0') +
    ':' +
    dateString.getMinutes().toString().padStart(2, '0') +
    ' ' +
    dateString.getDate().toString().padStart(2, '0') +
    '/' +
    (dateString.getMonth() + 1).toString().padStart(2, '0');
  return dates;
};
