const emojis: Record<string, string> = {
  ':england:': '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
  ':scotland:': '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
  ':wales:': '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
  ':men_holding_hands_medium_light_skin_tone_light_skin_tone:': '👨🏼‍🤝‍👨🏻',
  ':men_holding_hands_tone2_tone1:': '👨🏼‍🤝‍👨🏻',
  ':men_holding_hands_medium_skin_tone_light_skin_tone:': '👨🏽‍🤝‍👨🏻',
  ':men_holding_hands_tone3_tone1:': '👨🏽‍🤝‍👨🏻',
  ':men_holding_hands_medium_skin_tone_medium_light_skin_tone:': '👨🏽‍🤝‍👨🏼',
  ':men_holding_hands_tone3_tone2:': '👨🏽‍🤝‍👨🏼',
  ':men_holding_hands_medium_dark_skin_tone_light_skin_tone:': '👨🏾‍🤝‍👨🏻',
  ':men_holding_hands_tone4_tone1:': '👨🏾‍🤝‍👨🏻',
  ':men_holding_hands_medium_dark_skin_tone_medium_light_skin_tone:': '👨🏾‍🤝‍👨🏼',
  ':men_holding_hands_tone4_tone2:': '👨🏾‍🤝‍👨🏼',
  ':men_holding_hands_medium_dark_skin_tone_medium_skin_tone:': '👨🏾‍🤝‍👨🏽',
  ':men_holding_hands_tone4_tone3:': '👨🏾‍🤝‍👨🏽',
  ':men_holding_hands_dark_skin_tone_light_skin_tone:': '👨🏿‍🤝‍👨🏻',
  ':men_holding_hands_tone5_tone1:': '👨🏿‍🤝‍👨🏻',
  ':men_holding_hands_dark_skin_tone_medium_light_skin_tone:': '👨🏿‍🤝‍👨🏼',
  ':men_holding_hands_tone5_tone2:': '👨🏿‍🤝‍👨🏼',
  ':men_holding_hands_dark_skin_tone_medium_skin_tone:': '👨🏿‍🤝‍👨🏽',
  ':men_holding_hands_tone5_tone3:': '👨🏿‍🤝‍👨🏽',
  ':men_holding_hands_dark_skin_tone_medium_dark_skin_tone:': '👨🏿‍🤝‍👨🏾',
  ':men_holding_hands_tone5_tone4:': '👨🏿‍🤝‍👨🏾',
  ':people_holding_hands_light_skin_tone:': '🧑🏻‍🤝‍🧑🏻',
  ':people_holding_hands_tone1:': '🧑🏻‍🤝‍🧑🏻',
  ':people_holding_hands_medium_light_skin_tone:': '🧑🏼‍🤝‍🧑🏼',
  ':people_holding_hands_tone2:': '🧑🏼‍🤝‍🧑🏼',
  ':people_holding_hands_medium_light_skin_tone_light_skin_tone:': '🧑🏼‍🤝‍🧑🏻',
  ':people_holding_hands_tone2_tone1:': '🧑🏼‍🤝‍🧑🏻',
  ':people_holding_hands_medium_skin_tone:': '🧑🏽‍🤝‍🧑🏽',
  ':people_holding_hands_tone3:': '🧑🏽‍🤝‍🧑🏽',
  ':people_holding_hands_medium_skin_tone_light_skin_tone:': '🧑🏽‍🤝‍🧑🏻',
  ':people_holding_hands_tone3_tone1:': '🧑🏽‍🤝‍🧑🏻',
  ':people_holding_hands_medium_skin_tone_medium_light_skin_tone:': '🧑🏽‍🤝‍🧑🏼',
  ':people_holding_hands_tone3_tone2:': '🧑🏽‍🤝‍🧑🏼',
  ':people_holding_hands_medium_dark_skin_tone:': '🧑🏾‍🤝‍🧑🏾',
  ':people_holding_hands_tone4:': '🧑🏾‍🤝‍🧑🏾',
  ':people_holding_hands_medium_dark_skin_tone_light_skin_tone:': '🧑🏾‍🤝‍🧑🏻',
  ':people_holding_hands_tone4_tone1:': '🧑🏾‍🤝‍🧑🏻',
  ':people_holding_hands_medium_dark_skin_tone_medium_light_skin_tone:': '🧑🏾‍🤝‍🧑🏼',
  ':people_holding_hands_tone4_tone2:': '🧑🏾‍🤝‍🧑🏼',
  ':people_holding_hands_medium_dark_skin_tone_medium_skin_tone:': '🧑🏾‍🤝‍🧑🏽',
  ':people_holding_hands_tone4_tone3:': '🧑🏾‍🤝‍🧑🏽',
  ':people_holding_hands_dark_skin_tone:': '🧑🏿‍🤝‍🧑🏿',
  ':people_holding_hands_tone5:': '🧑🏿‍🤝‍🧑🏿',
  ':people_holding_hands_dark_skin_tone_light_skin_tone:': '🧑🏿‍🤝‍🧑🏻',
  ':people_holding_hands_tone5_tone1:': '🧑🏿‍🤝‍🧑🏻',
  ':people_holding_hands_dark_skin_tone_medium_light_skin_tone:': '🧑🏿‍🤝‍🧑🏼',
  ':people_holding_hands_tone5_tone2:': '🧑🏿‍🤝‍🧑🏼',
  ':people_holding_hands_dark_skin_tone_medium_skin_tone:': '🧑🏿‍🤝‍🧑🏽',
  ':people_holding_hands_tone5_tone3:': '🧑🏿‍🤝‍🧑🏽',
  ':people_holding_hands_dark_skin_tone_medium_dark_skin_tone:': '🧑🏿‍🤝‍🧑🏾',
  ':people_holding_hands_tone5_tone4:': '🧑🏿‍🤝‍🧑🏾',
  ':woman_and_man_holding_hands_light_skin_tone_medium_light_skin_tone:': '👩🏻‍🤝‍👨🏼',
  ':woman_and_man_holding_hands_tone1_tone2:': '👩🏻‍🤝‍👨🏼',
  ':woman_and_man_holding_hands_light_skin_tone_medium_skin_tone:': '👩🏻‍🤝‍👨🏽',
  ':woman_and_man_holding_hands_tone1_tone3:': '👩🏻‍🤝‍👨🏽',
  ':woman_and_man_holding_hands_light_skin_tone_medium_dark_skin_tone:': '👩🏻‍🤝‍👨🏾',
  ':woman_and_man_holding_hands_tone1_tone4:': '👩🏻‍🤝‍👨🏾',
  ':woman_and_man_holding_hands_light_skin_tone_dark_skin_tone:': '👩🏻‍🤝‍👨🏿',
  ':woman_and_man_holding_hands_tone1_tone5:': '👩🏻‍🤝‍👨🏿',
  ':woman_and_man_holding_hands_medium_light_skin_tone_light_skin_tone:': '👩🏼‍🤝‍👨🏻',
  ':woman_and_man_holding_hands_tone2_tone1:': '👩🏼‍🤝‍👨🏻',
  ':woman_and_man_holding_hands_medium_light_skin_tone_medium_skin_tone:': '👩🏼‍🤝‍👨🏽',
  ':woman_and_man_holding_hands_tone2_tone3:': '👩🏼‍🤝‍👨🏽',
  ':woman_and_man_holding_hands_medium_light_skin_tone_medium_dark_skin_tone:':
    '👩🏼‍🤝‍👨🏾',
  ':woman_and_man_holding_hands_tone2_tone4:': '👩🏼‍🤝‍👨🏾',
  ':woman_and_man_holding_hands_medium_light_skin_tone_dark_skin_tone:': '👩🏼‍🤝‍👨🏿',
  ':woman_and_man_holding_hands_tone2_tone5:': '👩🏼‍🤝‍👨🏿',
  ':woman_and_man_holding_hands_medium_skin_tone_light_skin_tone:': '👩🏽‍🤝‍👨🏻',
  ':woman_and_man_holding_hands_tone3_tone1:': '👩🏽‍🤝‍👨🏻',
  ':woman_and_man_holding_hands_medium_skin_tone_medium_light_skin_tone:': '👩🏽‍🤝‍👨🏼',
  ':woman_and_man_holding_hands_tone3_tone2:': '👩🏽‍🤝‍👨🏼',
  ':woman_and_man_holding_hands_medium_skin_tone_medium_dark_skin_tone:': '👩🏽‍🤝‍👨🏾',
  ':woman_and_man_holding_hands_tone3_tone4:': '👩🏽‍🤝‍👨🏾',
  ':woman_and_man_holding_hands_medium_skin_tone_dark_skin_tone:': '👩🏽‍🤝‍👨🏿',
  ':woman_and_man_holding_hands_tone3_tone5:': '👩🏽‍🤝‍👨🏿',
  ':woman_and_man_holding_hands_medium_dark_skin_tone_light_skin_tone:': '👩🏾‍🤝‍👨🏻',
  ':woman_and_man_holding_hands_tone4_tone1:': '👩🏾‍🤝‍👨🏻',
  ':woman_and_man_holding_hands_medium_dark_skin_tone_medium_light_skin_tone:':
    '👩🏾‍🤝‍👨🏼',
  ':woman_and_man_holding_hands_tone4_tone2:': '👩🏾‍🤝‍👨🏼',
  ':woman_and_man_holding_hands_medium_dark_skin_tone_medium_skin_tone:': '👩🏾‍🤝‍👨🏽',
  ':woman_and_man_holding_hands_tone4_tone3:': '👩🏾‍🤝‍👨🏽',
  ':woman_and_man_holding_hands_medium_dark_skin_tone_dark_skin_tone:': '👩🏾‍🤝‍👨🏿',
  ':woman_and_man_holding_hands_tone4_tone5:': '👩🏾‍🤝‍👨🏿',
  ':woman_and_man_holding_hands_dark_skin_tone_light_skin_tone:': '👩🏿‍🤝‍👨🏻',
  ':woman_and_man_holding_hands_tone5_tone1:': '👩🏿‍🤝‍👨🏻',
  ':woman_and_man_holding_hands_dark_skin_tone_medium_light_skin_tone:': '👩🏿‍🤝‍👨🏼',
  ':woman_and_man_holding_hands_tone5_tone2:': '👩🏿‍🤝‍👨🏼',
  ':woman_and_man_holding_hands_dark_skin_tone_medium_skin_tone:': '👩🏿‍🤝‍👨🏽',
  ':woman_and_man_holding_hands_tone5_tone3:': '👩🏿‍🤝‍👨🏽',
  ':woman_and_man_holding_hands_dark_skin_tone_medium_dark_skin_tone:': '👩🏿‍🤝‍👨🏾',
  ':woman_and_man_holding_hands_tone5_tone4:': '👩🏿‍🤝‍👨🏾',
  ':women_holding_hands_medium_light_skin_tone_light_skin_tone:': '👩🏼‍🤝‍👩🏻',
  ':women_holding_hands_tone2_tone1:': '👩🏼‍🤝‍👩🏻',
  ':women_holding_hands_medium_skin_tone_light_skin_tone:': '👩🏽‍🤝‍👩🏻',
  ':women_holding_hands_tone3_tone1:': '👩🏽‍🤝‍👩🏻',
  ':women_holding_hands_medium_skin_tone_medium_light_skin_tone:': '👩🏽‍🤝‍👩🏼',
  ':women_holding_hands_tone3_tone2:': '👩🏽‍🤝‍👩🏼',
  ':women_holding_hands_medium_dark_skin_tone_light_skin_tone:': '👩🏾‍🤝‍👩🏻',
  ':women_holding_hands_tone4_tone1:': '👩🏾‍🤝‍👩🏻',
  ':women_holding_hands_medium_dark_skin_tone_medium_light_skin_tone:': '👩🏾‍🤝‍👩🏼',
  ':women_holding_hands_tone4_tone2:': '👩🏾‍🤝‍👩🏼',
  ':women_holding_hands_medium_dark_skin_tone_medium_skin_tone:': '👩🏾‍🤝‍👩🏽',
  ':women_holding_hands_tone4_tone3:': '👩🏾‍🤝‍👩🏽',
  ':women_holding_hands_dark_skin_tone_light_skin_tone:': '👩🏿‍🤝‍👩🏻',
  ':women_holding_hands_tone5_tone1:': '👩🏿‍🤝‍👩🏻',
  ':women_holding_hands_dark_skin_tone_medium_light_skin_tone:': '👩🏿‍🤝‍👩🏼',
  ':women_holding_hands_tone5_tone2:': '👩🏿‍🤝‍👩🏼',
  ':women_holding_hands_dark_skin_tone_medium_skin_tone:': '👩🏿‍🤝‍👩🏽',
  ':women_holding_hands_tone5_tone3:': '👩🏿‍🤝‍👩🏽',
  ':women_holding_hands_dark_skin_tone_medium_dark_skin_tone:': '👩🏿‍🤝‍👩🏾',
  ':women_holding_hands_tone5_tone4:': '👩🏿‍🤝‍👩🏾',
  ':family_mmbb:': '👨‍👨‍👦‍👦',
  ':family_mmgb:': '👨‍👨‍👧‍👦',
  ':family_mmgg:': '👨‍👨‍👧‍👧',
  ':family_mwbb:': '👨‍👩‍👦‍👦',
  ':family_mwgb:': '👨‍👩‍👧‍👦',
  ':family_mwgg:': '👨‍👩‍👧‍👧',
  ':family_wwbb:': '👩‍👩‍👦‍👦',
  ':family_wwgb:': '👩‍👩‍👧‍👦',
  ':family_wwgg:': '👩‍👩‍👧‍👧',
  ':couplekiss_mm:': '👨‍❤️‍💋👨',
  ':kiss_mm:': '👨‍❤️‍💋👨',
  ':kiss_woman_man:': '👩‍❤️‍💋👨',
  ':couplekiss_ww:': '👩‍❤️‍💋👩',
  ':kiss_ww:': '👩‍❤️‍💋👩',
  ':family_man_boy_boy:': '👨‍👦‍👦',
  ':family_man_girl_boy:': '👨‍👧‍👦',
  ':family_man_girl_girl:': '👨‍👧‍👧',
  ':family_man_woman_boy:': '👨‍👩‍👦',
  ':family_mmb:': '👨‍👨‍👦',
  ':family_mmg:': '👨‍👨‍👧',
  ':family_mwg:': '👨‍👩‍👧',
  ':family_woman_boy_boy:': '👩‍👦‍👦',
  ':family_woman_girl_boy:': '👩‍👧‍👦',
  ':family_woman_girl_girl:': '👩‍👧‍👧',
  ':family_wwb:': '👩‍👩‍👦',
  ':family_wwg:': '👩‍👩‍👧',
  ':man_artist_light_skin_tone:': '👨🏻‍🎨',
  ':man_artist_tone1:': '👨🏻‍🎨',
  ':man_artist_medium_light_skin_tone:': '👨🏼‍🎨',
  ':man_artist_tone2:': '👨🏼‍🎨',
  ':man_artist_medium_skin_tone:': '👨🏽‍🎨',
  ':man_artist_tone3:': '👨🏽‍🎨',
  ':man_artist_medium_dark_skin_tone:': '👨🏾‍🎨',
  ':man_artist_tone4:': '👨🏾‍🎨',
  ':man_artist_dark_skin_tone:': '👨🏿‍🎨',
  ':man_artist_tone5:': '👨🏿‍🎨',
  ':man_astronaut_light_skin_tone:': '👨🏻‍🚀',
  ':man_astronaut_tone1:': '👨🏻‍🚀',
  ':man_astronaut_medium_light_skin_tone:': '👨🏼‍🚀',
  ':man_astronaut_tone2:': '👨🏼‍🚀',
  ':man_astronaut_medium_skin_tone:': '👨🏽‍🚀',
  ':man_astronaut_tone3:': '👨🏽‍🚀',
  ':man_astronaut_medium_dark_skin_tone:': '👨🏾‍🚀',
  ':man_astronaut_tone4:': '👨🏾‍🚀',
  ':man_astronaut_dark_skin_tone:': '👨🏿‍🚀',
  ':man_astronaut_tone5:': '👨🏿‍🚀',
  ':man_bald_light_skin_tone:': '👨🏻‍🦲',
  ':man_bald_tone1:': '👨🏻‍🦲',
  ':man_bald_medium_light_skin_tone:': '👨🏼‍🦲',
  ':man_bald_tone2:': '👨🏼‍🦲',
  ':man_bald_medium_skin_tone:': '👨🏽‍🦲',
  ':man_bald_tone3:': '👨🏽‍🦲',
  ':man_bald_medium_dark_skin_tone:': '👨🏾‍🦲',
  ':man_bald_tone4:': '👨🏾‍🦲',
  ':man_bald_dark_skin_tone:': '👨🏿‍🦲',
  ':man_bald_tone5:': '👨🏿‍🦲',
  ':man_cook_light_skin_tone:': '👨🏻‍🍳',
  ':man_cook_tone1:': '👨🏻‍🍳',
  ':man_cook_medium_light_skin_tone:': '👨🏼‍🍳',
  ':man_cook_tone2:': '👨🏼‍🍳',
  ':man_cook_medium_skin_tone:': '👨🏽‍🍳',
  ':man_cook_tone3:': '👨🏽‍🍳',
  ':man_cook_medium_dark_skin_tone:': '👨🏾‍🍳',
  ':man_cook_tone4:': '👨🏾‍🍳',
  ':man_cook_dark_skin_tone:': '👨🏿‍🍳',
  ':man_cook_tone5:': '👨🏿‍🍳',
  ':man_curly_haired_light_skin_tone:': '👨🏻‍🦱',
  ':man_curly_haired_tone1:': '👨🏻‍🦱',
  ':man_curly_haired_medium_light_skin_tone:': '👨🏼‍🦱',
  ':man_curly_haired_tone2:': '👨🏼‍🦱',
  ':man_curly_haired_medium_skin_tone:': '👨🏽‍🦱',
  ':man_curly_haired_tone3:': '👨🏽‍🦱',
  ':man_curly_haired_medium_dark_skin_tone:': '👨🏾‍🦱',
  ':man_curly_haired_tone4:': '👨🏾‍🦱',
  ':man_curly_haired_dark_skin_tone:': '👨🏿‍🦱',
  ':man_curly_haired_tone5:': '👨🏿‍🦱',
  ':man_factory_worker_light_skin_tone:': '👨🏻‍🏭',
  ':man_factory_worker_tone1:': '👨🏻‍🏭',
  ':man_factory_worker_medium_light_skin_tone:': '👨🏼‍🏭',
  ':man_factory_worker_tone2:': '👨🏼‍🏭',
  ':man_factory_worker_medium_skin_tone:': '👨🏽‍🏭',
  ':man_factory_worker_tone3:': '👨🏽‍🏭',
  ':man_factory_worker_medium_dark_skin_tone:': '👨🏾‍🏭',
  ':man_factory_worker_tone4:': '👨🏾‍🏭',
  ':man_factory_worker_dark_skin_tone:': '👨🏿‍🏭',
  ':man_factory_worker_tone5:': '👨🏿‍🏭',
  ':man_farmer_light_skin_tone:': '👨🏻‍🌾',
  ':man_farmer_tone1:': '👨🏻‍🌾',
  ':man_farmer_medium_light_skin_tone:': '👨🏼‍🌾',
  ':man_farmer_tone2:': '👨🏼‍🌾',
  ':man_farmer_medium_skin_tone:': '👨🏽‍🌾',
  ':man_farmer_tone3:': '👨🏽‍🌾',
  ':man_farmer_medium_dark_skin_tone:': '👨🏾‍🌾',
  ':man_farmer_tone4:': '👨🏾‍🌾',
  ':man_farmer_dark_skin_tone:': '👨🏿‍🌾',
  ':man_farmer_tone5:': '👨🏿‍🌾',
  ':man_firefighter_light_skin_tone:': '👨🏻‍🚒',
  ':man_firefighter_tone1:': '👨🏻‍🚒',
  ':man_firefighter_medium_light_skin_tone:': '👨🏼‍🚒',
  ':man_firefighter_tone2:': '👨🏼‍🚒',
  ':man_firefighter_medium_skin_tone:': '👨🏽‍🚒',
  ':man_firefighter_tone3:': '👨🏽‍🚒',
  ':man_firefighter_medium_dark_skin_tone:': '👨🏾‍🚒',
  ':man_firefighter_tone4:': '👨🏾‍🚒',
  ':man_firefighter_dark_skin_tone:': '👨🏿‍🚒',
  ':man_firefighter_tone5:': '👨🏿‍🚒',
  ':man_in_manual_wheelchair_light_skin_tone:': '👨🏻‍🦽',
  ':man_in_manual_wheelchair_tone1:': '👨🏻‍🦽',
  ':man_in_manual_wheelchair_medium_light_skin_tone:': '👨🏼‍🦽',
  ':man_in_manual_wheelchair_tone2:': '👨🏼‍🦽',
  ':man_in_manual_wheelchair_medium_skin_tone:': '👨🏽‍🦽',
  ':man_in_manual_wheelchair_tone3:': '👨🏽‍🦽',
  ':man_in_manual_wheelchair_medium_dark_skin_tone:': '👨🏾‍🦽',
  ':man_in_manual_wheelchair_tone4:': '👨🏾‍🦽',
  ':man_in_manual_wheelchair_dark_skin_tone:': '👨🏿‍🦽',
  ':man_in_manual_wheelchair_tone5:': '👨🏿‍🦽',
  ':man_in_motorized_wheelchair_light_skin_tone:': '👨🏻‍🦼',
  ':man_in_motorized_wheelchair_tone1:': '👨🏻‍🦼',
  ':man_in_motorized_wheelchair_medium_light_skin_tone:': '👨🏼‍🦼',
  ':man_in_motorized_wheelchair_tone2:': '👨🏼‍🦼',
  ':man_in_motorized_wheelchair_medium_skin_tone:': '👨🏽‍🦼',
  ':man_in_motorized_wheelchair_tone3:': '👨🏽‍🦼',
  ':man_in_motorized_wheelchair_medium_dark_skin_tone:': '👨🏾‍🦼',
  ':man_in_motorized_wheelchair_tone4:': '👨🏾‍🦼',
  ':man_in_motorized_wheelchair_dark_skin_tone:': '👨🏿‍🦼',
  ':man_in_motorized_wheelchair_tone5:': '👨🏿‍🦼',
  ':man_mechanic_light_skin_tone:': '👨🏻‍🔧',
  ':man_mechanic_tone1:': '👨🏻‍🔧',
  ':man_mechanic_medium_light_skin_tone:': '👨🏼‍🔧',
  ':man_mechanic_tone2:': '👨🏼‍🔧',
  ':man_mechanic_medium_skin_tone:': '👨🏽‍🔧',
  ':man_mechanic_tone3:': '👨🏽‍🔧',
  ':man_mechanic_medium_dark_skin_tone:': '👨🏾‍🔧',
  ':man_mechanic_tone4:': '👨🏾‍🔧',
  ':man_mechanic_dark_skin_tone:': '👨🏿‍🔧',
  ':man_mechanic_tone5:': '👨🏿‍🔧',
  ':man_office_worker_light_skin_tone:': '👨🏻‍💼',
  ':man_office_worker_tone1:': '👨🏻‍💼',
  ':man_office_worker_medium_light_skin_tone:': '👨🏼‍💼',
  ':man_office_worker_tone2:': '👨🏼‍💼',
  ':man_office_worker_medium_skin_tone:': '👨🏽‍💼',
  ':man_office_worker_tone3:': '👨🏽‍💼',
  ':man_office_worker_medium_dark_skin_tone:': '👨🏾‍💼',
  ':man_office_worker_tone4:': '👨🏾‍💼',
  ':man_office_worker_dark_skin_tone:': '👨🏿‍💼',
  ':man_office_worker_tone5:': '👨🏿‍💼',
  ':man_red_haired_light_skin_tone:': '👨🏻‍🦰',
  ':man_red_haired_tone1:': '👨🏻‍🦰',
  ':man_red_haired_medium_light_skin_tone:': '👨🏼‍🦰',
  ':man_red_haired_tone2:': '👨🏼‍🦰',
  ':man_red_haired_medium_skin_tone:': '👨🏽‍🦰',
  ':man_red_haired_tone3:': '👨🏽‍🦰',
  ':man_red_haired_medium_dark_skin_tone:': '👨🏾‍🦰',
  ':man_red_haired_tone4:': '👨🏾‍🦰',
  ':man_red_haired_dark_skin_tone:': '👨🏿‍🦰',
  ':man_red_haired_tone5:': '👨🏿‍🦰',
  ':man_scientist_light_skin_tone:': '👨🏻‍🔬',
  ':man_scientist_tone1:': '👨🏻‍🔬',
  ':man_scientist_medium_light_skin_tone:': '👨🏼‍🔬',
  ':man_scientist_tone2:': '👨🏼‍🔬',
  ':man_scientist_medium_skin_tone:': '👨🏽‍🔬',
  ':man_scientist_tone3:': '👨🏽‍🔬',
  ':man_scientist_medium_dark_skin_tone:': '👨🏾‍🔬',
  ':man_scientist_tone4:': '👨🏾‍🔬',
  ':man_scientist_dark_skin_tone:': '👨🏿‍🔬',
  ':man_scientist_tone5:': '👨🏿‍🔬',
  ':man_singer_light_skin_tone:': '👨🏻‍🎤',
  ':man_singer_tone1:': '👨🏻‍🎤',
  ':man_singer_medium_light_skin_tone:': '👨🏼‍🎤',
  ':man_singer_tone2:': '👨🏼‍🎤',
  ':man_singer_medium_skin_tone:': '👨🏽‍🎤',
  ':man_singer_tone3:': '👨🏽‍🎤',
  ':man_singer_medium_dark_skin_tone:': '👨🏾‍🎤',
  ':man_singer_tone4:': '👨🏾‍🎤',
  ':man_singer_dark_skin_tone:': '👨🏿‍🎤',
  ':man_singer_tone5:': '👨🏿‍🎤',
  ':man_student_light_skin_tone:': '👨🏻‍🎓',
  ':man_student_tone1:': '👨🏻‍🎓',
  ':man_student_medium_light_skin_tone:': '👨🏼‍🎓',
  ':man_student_tone2:': '👨🏼‍🎓',
  ':man_student_medium_skin_tone:': '👨🏽‍🎓',
  ':man_student_tone3:': '👨🏽‍🎓',
  ':man_student_medium_dark_skin_tone:': '👨🏾‍🎓',
  ':man_student_tone4:': '👨🏾‍🎓',
  ':man_student_dark_skin_tone:': '👨🏿‍🎓',
  ':man_student_tone5:': '👨🏿‍🎓',
  ':man_teacher_light_skin_tone:': '👨🏻‍🏫',
  ':man_teacher_tone1:': '👨🏻‍🏫',
  ':man_teacher_medium_light_skin_tone:': '👨🏼‍🏫',
  ':man_teacher_tone2:': '👨🏼‍🏫',
  ':man_teacher_medium_skin_tone:': '👨🏽‍🏫',
  ':man_teacher_tone3:': '👨🏽‍🏫',
  ':man_teacher_medium_dark_skin_tone:': '👨🏾‍🏫',
  ':man_teacher_tone4:': '👨🏾‍🏫',
  ':man_teacher_dark_skin_tone:': '👨🏿‍🏫',
  ':man_teacher_tone5:': '👨🏿‍🏫',
  ':man_technologist_light_skin_tone:': '👨🏻‍💻',
  ':man_technologist_tone1:': '👨🏻‍💻',
  ':man_technologist_medium_light_skin_tone:': '👨🏼‍💻',
  ':man_technologist_tone2:': '👨🏼‍💻',
  ':man_technologist_medium_skin_tone:': '👨🏽‍💻',
  ':man_technologist_tone3:': '👨🏽‍💻',
  ':man_technologist_medium_dark_skin_tone:': '👨🏾‍💻',
  ':man_technologist_tone4:': '👨🏾‍💻',
  ':man_technologist_dark_skin_tone:': '👨🏿‍💻',
  ':man_technologist_tone5:': '👨🏿‍💻',
  ':man_white_haired_light_skin_tone:': '👨🏻‍🦳',
  ':man_white_haired_tone1:': '👨🏻‍🦳',
  ':man_white_haired_medium_light_skin_tone:': '👨🏼‍🦳',
  ':man_white_haired_tone2:': '👨🏼‍🦳',
  ':man_white_haired_medium_skin_tone:': '👨🏽‍🦳',
  ':man_white_haired_tone3:': '👨🏽‍🦳',
  ':man_white_haired_medium_dark_skin_tone:': '👨🏾‍🦳',
  ':man_white_haired_tone4:': '👨🏾‍🦳',
  ':man_white_haired_dark_skin_tone:': '👨🏿‍🦳',
  ':man_white_haired_tone5:': '👨🏿‍🦳',
  ':man_with_probing_cane_light_skin_tone:': '👨🏻‍🦯',
  ':man_with_probing_cane_tone1:': '👨🏻‍🦯',
  ':man_with_probing_cane_medium_light_skin_tone:': '👨🏼‍🦯',
  ':man_with_probing_cane_tone2:': '👨🏼‍🦯',
  ':man_with_probing_cane_medium_skin_tone:': '👨🏽‍🦯',
  ':man_with_probing_cane_tone3:': '👨🏽‍🦯',
  ':man_with_probing_cane_medium_dark_skin_tone:': '👨🏾‍🦯',
  ':man_with_probing_cane_tone4:': '👨🏾‍🦯',
  ':man_with_probing_cane_dark_skin_tone:': '👨🏿‍🦯',
  ':man_with_probing_cane_tone5:': '👨🏿‍🦯',
  ':people_holding_hands:': '🧑‍🤝‍🧑',
  ':woman_artist_light_skin_tone:': '👩🏻‍🎨',
  ':woman_artist_tone1:': '👩🏻‍🎨',
  ':woman_artist_medium_light_skin_tone:': '👩🏼‍🎨',
  ':woman_artist_tone2:': '👩🏼‍🎨',
  ':woman_artist_medium_skin_tone:': '👩🏽‍🎨',
  ':woman_artist_tone3:': '👩🏽‍🎨',
  ':woman_artist_medium_dark_skin_tone:': '👩🏾‍🎨',
  ':woman_artist_tone4:': '👩🏾‍🎨',
  ':woman_artist_dark_skin_tone:': '👩🏿‍🎨',
  ':woman_artist_tone5:': '👩🏿‍🎨',
  ':woman_astronaut_light_skin_tone:': '👩🏻‍🚀',
  ':woman_astronaut_tone1:': '👩🏻‍🚀',
  ':woman_astronaut_medium_light_skin_tone:': '👩🏼‍🚀',
  ':woman_astronaut_tone2:': '👩🏼‍🚀',
  ':woman_astronaut_medium_skin_tone:': '👩🏽‍🚀',
  ':woman_astronaut_tone3:': '👩🏽‍🚀',
  ':woman_astronaut_medium_dark_skin_tone:': '👩🏾‍🚀',
  ':woman_astronaut_tone4:': '👩🏾‍🚀',
  ':woman_astronaut_dark_skin_tone:': '👩🏿‍🚀',
  ':woman_astronaut_tone5:': '👩🏿‍🚀',
  ':woman_bald_light_skin_tone:': '👩🏻‍🦲',
  ':woman_bald_tone1:': '👩🏻‍🦲',
  ':woman_bald_medium_light_skin_tone:': '👩🏼‍🦲',
  ':woman_bald_tone2:': '👩🏼‍🦲',
  ':woman_bald_medium_skin_tone:': '👩🏽‍🦲',
  ':woman_bald_tone3:': '👩🏽‍🦲',
  ':woman_bald_medium_dark_skin_tone:': '👩🏾‍🦲',
  ':woman_bald_tone4:': '👩🏾‍🦲',
  ':woman_bald_dark_skin_tone:': '👩🏿‍🦲',
  ':woman_bald_tone5:': '👩🏿‍🦲',
  ':woman_cook_light_skin_tone:': '👩🏻‍🍳',
  ':woman_cook_tone1:': '👩🏻‍🍳',
  ':woman_cook_medium_light_skin_tone:': '👩🏼‍🍳',
  ':woman_cook_tone2:': '👩🏼‍🍳',
  ':woman_cook_medium_skin_tone:': '👩🏽‍🍳',
  ':woman_cook_tone3:': '👩🏽‍🍳',
  ':woman_cook_medium_dark_skin_tone:': '👩🏾‍🍳',
  ':woman_cook_tone4:': '👩🏾‍🍳',
  ':woman_cook_dark_skin_tone:': '👩🏿‍🍳',
  ':woman_cook_tone5:': '👩🏿‍🍳',
  ':woman_curly_haired_light_skin_tone:': '👩🏻‍🦱',
  ':woman_curly_haired_tone1:': '👩🏻‍🦱',
  ':woman_curly_haired_medium_light_skin_tone:': '👩🏼‍🦱',
  ':woman_curly_haired_tone2:': '👩🏼‍🦱',
  ':woman_curly_haired_medium_skin_tone:': '👩🏽‍🦱',
  ':woman_curly_haired_tone3:': '👩🏽‍🦱',
  ':woman_curly_haired_medium_dark_skin_tone:': '👩🏾‍🦱',
  ':woman_curly_haired_tone4:': '👩🏾‍🦱',
  ':woman_curly_haired_dark_skin_tone:': '👩🏿‍🦱',
  ':woman_curly_haired_tone5:': '👩🏿‍🦱',
  ':woman_factory_worker_light_skin_tone:': '👩🏻‍🏭',
  ':woman_factory_worker_tone1:': '👩🏻‍🏭',
  ':woman_factory_worker_medium_light_skin_tone:': '👩🏼‍🏭',
  ':woman_factory_worker_tone2:': '👩🏼‍🏭',
  ':woman_factory_worker_medium_skin_tone:': '👩🏽‍🏭',
  ':woman_factory_worker_tone3:': '👩🏽‍🏭',
  ':woman_factory_worker_medium_dark_skin_tone:': '👩🏾‍🏭',
  ':woman_factory_worker_tone4:': '👩🏾‍🏭',
  ':woman_factory_worker_dark_skin_tone:': '👩🏿‍🏭',
  ':woman_factory_worker_tone5:': '👩🏿‍🏭',
  ':woman_farmer_light_skin_tone:': '👩🏻‍🌾',
  ':woman_farmer_tone1:': '👩🏻‍🌾',
  ':woman_farmer_medium_light_skin_tone:': '👩🏼‍🌾',
  ':woman_farmer_tone2:': '👩🏼‍🌾',
  ':woman_farmer_medium_skin_tone:': '👩🏽‍🌾',
  ':woman_farmer_tone3:': '👩🏽‍🌾',
  ':woman_farmer_medium_dark_skin_tone:': '👩🏾‍🌾',
  ':woman_farmer_tone4:': '👩🏾‍🌾',
  ':woman_farmer_dark_skin_tone:': '👩🏿‍🌾',
  ':woman_farmer_tone5:': '👩🏿‍🌾',
  ':woman_firefighter_light_skin_tone:': '👩🏻‍🚒',
  ':woman_firefighter_tone1:': '👩🏻‍🚒',
  ':woman_firefighter_medium_light_skin_tone:': '👩🏼‍🚒',
  ':woman_firefighter_tone2:': '👩🏼‍🚒',
  ':woman_firefighter_medium_skin_tone:': '👩🏽‍🚒',
  ':woman_firefighter_tone3:': '👩🏽‍🚒',
  ':woman_firefighter_medium_dark_skin_tone:': '👩🏾‍🚒',
  ':woman_firefighter_tone4:': '👩🏾‍🚒',
  ':woman_firefighter_dark_skin_tone:': '👩🏿‍🚒',
  ':woman_firefighter_tone5:': '👩🏿‍🚒',
  ':woman_in_manual_wheelchair_light_skin_tone:': '👩🏻‍🦽',
  ':woman_in_manual_wheelchair_tone1:': '👩🏻‍🦽',
  ':woman_in_manual_wheelchair_medium_light_skin_tone:': '👩🏼‍🦽',
  ':woman_in_manual_wheelchair_tone2:': '👩🏼‍🦽',
  ':woman_in_manual_wheelchair_medium_skin_tone:': '👩🏽‍🦽',
  ':woman_in_manual_wheelchair_tone3:': '👩🏽‍🦽',
  ':woman_in_manual_wheelchair_medium_dark_skin_tone:': '👩🏾‍🦽',
  ':woman_in_manual_wheelchair_tone4:': '👩🏾‍🦽',
  ':woman_in_manual_wheelchair_dark_skin_tone:': '👩🏿‍🦽',
  ':woman_in_manual_wheelchair_tone5:': '👩🏿‍🦽',
  ':woman_in_motorized_wheelchair_light_skin_tone:': '👩🏻‍🦼',
  ':woman_in_motorized_wheelchair_tone1:': '👩🏻‍🦼',
  ':woman_in_motorized_wheelchair_medium_light_skin_tone:': '👩🏼‍🦼',
  ':woman_in_motorized_wheelchair_tone2:': '👩🏼‍🦼',
  ':woman_in_motorized_wheelchair_medium_skin_tone:': '👩🏽‍🦼',
  ':woman_in_motorized_wheelchair_tone3:': '👩🏽‍🦼',
  ':woman_in_motorized_wheelchair_medium_dark_skin_tone:': '👩🏾‍🦼',
  ':woman_in_motorized_wheelchair_tone4:': '👩🏾‍🦼',
  ':woman_in_motorized_wheelchair_dark_skin_tone:': '👩🏿‍🦼',
  ':woman_in_motorized_wheelchair_tone5:': '👩🏿‍🦼',
  ':woman_mechanic_light_skin_tone:': '👩🏻‍🔧',
  ':woman_mechanic_tone1:': '👩🏻‍🔧',
  ':woman_mechanic_medium_light_skin_tone:': '👩🏼‍🔧',
  ':woman_mechanic_tone2:': '👩🏼‍🔧',
  ':woman_mechanic_medium_skin_tone:': '👩🏽‍🔧',
  ':woman_mechanic_tone3:': '👩🏽‍🔧',
  ':woman_mechanic_medium_dark_skin_tone:': '👩🏾‍🔧',
  ':woman_mechanic_tone4:': '👩🏾‍🔧',
  ':woman_mechanic_dark_skin_tone:': '👩🏿‍🔧',
  ':woman_mechanic_tone5:': '👩🏿‍🔧',
  ':woman_office_worker_light_skin_tone:': '👩🏻‍💼',
  ':woman_office_worker_tone1:': '👩🏻‍💼',
  ':woman_office_worker_medium_light_skin_tone:': '👩🏼‍💼',
  ':woman_office_worker_tone2:': '👩🏼‍💼',
  ':woman_office_worker_medium_skin_tone:': '👩🏽‍💼',
  ':woman_office_worker_tone3:': '👩🏽‍💼',
  ':woman_office_worker_medium_dark_skin_tone:': '👩🏾‍💼',
  ':woman_office_worker_tone4:': '👩🏾‍💼',
  ':woman_office_worker_dark_skin_tone:': '👩🏿‍💼',
  ':woman_office_worker_tone5:': '👩🏿‍💼',
  ':woman_red_haired_light_skin_tone:': '👩🏻‍🦰',
  ':woman_red_haired_tone1:': '👩🏻‍🦰',
  ':woman_red_haired_medium_light_skin_tone:': '👩🏼‍🦰',
  ':woman_red_haired_tone2:': '👩🏼‍🦰',
  ':woman_red_haired_medium_skin_tone:': '👩🏽‍🦰',
  ':woman_red_haired_tone3:': '👩🏽‍🦰',
  ':woman_red_haired_medium_dark_skin_tone:': '👩🏾‍🦰',
  ':woman_red_haired_tone4:': '👩🏾‍🦰',
  ':woman_red_haired_dark_skin_tone:': '👩🏿‍🦰',
  ':woman_red_haired_tone5:': '👩🏿‍🦰',
  ':woman_scientist_light_skin_tone:': '👩🏻‍🔬',
  ':woman_scientist_tone1:': '👩🏻‍🔬',
  ':woman_scientist_medium_light_skin_tone:': '👩🏼‍🔬',
  ':woman_scientist_tone2:': '👩🏼‍🔬',
  ':woman_scientist_medium_skin_tone:': '👩🏽‍🔬',
  ':woman_scientist_tone3:': '👩🏽‍🔬',
  ':woman_scientist_medium_dark_skin_tone:': '👩🏾‍🔬',
  ':woman_scientist_tone4:': '👩🏾‍🔬',
  ':woman_scientist_dark_skin_tone:': '👩🏿‍🔬',
  ':woman_scientist_tone5:': '👩🏿‍🔬',
  ':woman_singer_light_skin_tone:': '👩🏻‍🎤',
  ':woman_singer_tone1:': '👩🏻‍🎤',
  ':woman_singer_medium_light_skin_tone:': '👩🏼‍🎤',
  ':woman_singer_tone2:': '👩🏼‍🎤',
  ':woman_singer_medium_skin_tone:': '👩🏽‍🎤',
  ':woman_singer_tone3:': '👩🏽‍🎤',
  ':woman_singer_medium_dark_skin_tone:': '👩🏾‍🎤',
  ':woman_singer_tone4:': '👩🏾‍🎤',
  ':woman_singer_dark_skin_tone:': '👩🏿‍🎤',
  ':woman_singer_tone5:': '👩🏿‍🎤',
  ':woman_student_light_skin_tone:': '👩🏻‍🎓',
  ':woman_student_tone1:': '👩🏻‍🎓',
  ':woman_student_medium_light_skin_tone:': '👩🏼‍🎓',
  ':woman_student_tone2:': '👩🏼‍🎓',
  ':woman_student_medium_skin_tone:': '👩🏽‍🎓',
  ':woman_student_tone3:': '👩🏽‍🎓',
  ':woman_student_medium_dark_skin_tone:': '👩🏾‍🎓',
  ':woman_student_tone4:': '👩🏾‍🎓',
  ':woman_student_dark_skin_tone:': '👩🏿‍🎓',
  ':woman_student_tone5:': '👩🏿‍🎓',
  ':woman_teacher_light_skin_tone:': '👩🏻‍🏫',
  ':woman_teacher_tone1:': '👩🏻‍🏫',
  ':woman_teacher_medium_light_skin_tone:': '👩🏼‍🏫',
  ':woman_teacher_tone2:': '👩🏼‍🏫',
  ':woman_teacher_medium_skin_tone:': '👩🏽‍🏫',
  ':woman_teacher_tone3:': '👩🏽‍🏫',
  ':woman_teacher_medium_dark_skin_tone:': '👩🏾‍🏫',
  ':woman_teacher_tone4:': '👩🏾‍🏫',
  ':woman_teacher_dark_skin_tone:': '👩🏿‍🏫',
  ':woman_teacher_tone5:': '👩🏿‍🏫',
  ':woman_technologist_light_skin_tone:': '👩🏻‍💻',
  ':woman_technologist_tone1:': '👩🏻‍💻',
  ':woman_technologist_medium_light_skin_tone:': '👩🏼‍💻',
  ':woman_technologist_tone2:': '👩🏼‍💻',
  ':woman_technologist_medium_skin_tone:': '👩🏽‍💻',
  ':woman_technologist_tone3:': '👩🏽‍💻',
  ':woman_technologist_medium_dark_skin_tone:': '👩🏾‍💻',
  ':woman_technologist_tone4:': '👩🏾‍💻',
  ':woman_technologist_dark_skin_tone:': '👩🏿‍💻',
  ':woman_technologist_tone5:': '👩🏿‍💻',
  ':woman_white_haired_light_skin_tone:': '👩🏻‍🦳',
  ':woman_white_haired_tone1:': '👩🏻‍🦳',
  ':woman_white_haired_medium_light_skin_tone:': '👩🏼‍🦳',
  ':woman_white_haired_tone2:': '👩🏼‍🦳',
  ':woman_white_haired_medium_skin_tone:': '👩🏽‍🦳',
  ':woman_white_haired_tone3:': '👩🏽‍🦳',
  ':woman_white_haired_medium_dark_skin_tone:': '👩🏾‍🦳',
  ':woman_white_haired_tone4:': '👩🏾‍🦳',
  ':woman_white_haired_dark_skin_tone:': '👩🏿‍🦳',
  ':woman_white_haired_tone5:': '👩🏿‍🦳',
  ':woman_with_probing_cane_light_skin_tone:': '👩🏻‍🦯',
  ':woman_with_probing_cane_tone1:': '👩🏻‍🦯',
  ':woman_with_probing_cane_medium_light_skin_tone:': '👩🏼‍🦯',
  ':woman_with_probing_cane_tone2:': '👩🏼‍🦯',
  ':woman_with_probing_cane_medium_skin_tone:': '👩🏽‍🦯',
  ':woman_with_probing_cane_tone3:': '👩🏽‍🦯',
  ':woman_with_probing_cane_medium_dark_skin_tone:': '👩🏾‍🦯',
  ':woman_with_probing_cane_tone4:': '👩🏾‍🦯',
  ':woman_with_probing_cane_dark_skin_tone:': '👩🏿‍🦯',
  ':woman_with_probing_cane_tone5:': '👩🏿‍🦯',
  ':blond-haired_man_light_skin_tone:': '👱🏻‍♂️',
  ':blond-haired_man_tone1:': '👱🏻‍♂️',
  ':blond-haired_man_medium_light_skin_tone:': '👱🏼‍♂️',
  ':blond-haired_man_tone2:': '👱🏼‍♂️',
  ':blond-haired_man_medium_skin_tone:': '👱🏽‍♂️',
  ':blond-haired_man_tone3:': '👱🏽‍♂️',
  ':blond-haired_man_medium_dark_skin_tone:': '👱🏾‍♂️',
  ':blond-haired_man_tone4:': '👱🏾‍♂️',
  ':blond-haired_man_dark_skin_tone:': '👱🏿‍♂️',
  ':blond-haired_man_tone5:': '👱🏿‍♂️',
  ':blond-haired_woman_light_skin_tone:': '👱🏻‍♀️',
  ':blond-haired_woman_tone1:': '👱🏻‍♀️',
  ':blond-haired_woman_medium_light_skin_tone:': '👱🏼‍♀️',
  ':blond-haired_woman_tone2:': '👱🏼‍♀️',
  ':blond-haired_woman_medium_skin_tone:': '👱🏽‍♀️',
  ':blond-haired_woman_tone3:': '👱🏽‍♀️',
  ':blond-haired_woman_medium_dark_skin_tone:': '👱🏾‍♀️',
  ':blond-haired_woman_tone4:': '👱🏾‍♀️',
  ':blond-haired_woman_dark_skin_tone:': '👱🏿‍♀️',
  ':blond-haired_woman_tone5:': '👱🏿‍♀️',
  ':couple_with_heart_mm:': '👨‍❤️‍👨',
  ':couple_mm:': '👨‍❤️‍👨',
  ':couple_with_heart_woman_man:': '👩‍❤️‍👨',
  ':couple_with_heart_ww:': '👩‍❤️‍👩',
  ':couple_ww:': '👩‍❤️‍👩',
  ':deaf_man_light_skin_tone:': '🧏🏻‍♂️',
  ':deaf_man_tone1:': '🧏🏻‍♂️',
  ':deaf_man_medium_light_skin_tone:': '🧏🏼‍♂️',
  ':deaf_man_tone2:': '🧏🏼‍♂️',
  ':deaf_man_medium_skin_tone:': '🧏🏽‍♂️',
  ':deaf_man_tone3:': '🧏🏽‍♂️',
  ':deaf_man_medium_dark_skin_tone:': '🧏🏾‍♂️',
  ':deaf_man_tone4:': '🧏🏾‍♂️',
  ':deaf_man_dark_skin_tone:': '🧏🏿‍♂️',
  ':deaf_man_tone5:': '🧏🏿‍♂️',
  ':deaf_woman_light_skin_tone:': '🧏🏻‍♀️',
  ':deaf_woman_tone1:': '🧏🏻‍♀️',
  ':deaf_woman_medium_light_skin_tone:': '🧏🏼‍♀️',
  ':deaf_woman_tone2:': '🧏🏼‍♀️',
  ':deaf_woman_medium_skin_tone:': '🧏🏽‍♀️',
  ':deaf_woman_tone3:': '🧏🏽‍♀️',
  ':deaf_woman_medium_dark_skin_tone:': '🧏🏾‍♀️',
  ':deaf_woman_tone4:': '🧏🏾‍♀️',
  ':deaf_woman_dark_skin_tone:': '🧏🏿‍♀️',
  ':deaf_woman_tone5:': '🧏🏿‍♀️',
  ':man_biking_light_skin_tone:': '🚴🏻‍♂️',
  ':man_biking_tone1:': '🚴🏻‍♂️',
  ':man_biking_medium_light_skin_tone:': '🚴🏼‍♂️',
  ':man_biking_tone2:': '🚴🏼‍♂️',
  ':man_biking_medium_skin_tone:': '🚴🏽‍♂️',
  ':man_biking_tone3:': '🚴🏽‍♂️',
  ':man_biking_medium_dark_skin_tone:': '🚴🏾‍♂️',
  ':man_biking_tone4:': '🚴🏾‍♂️',
  ':man_biking_dark_skin_tone:': '🚴🏿‍♂️',
  ':man_biking_tone5:': '🚴🏿‍♂️',
  ':man_bowing_light_skin_tone:': '🙇🏻‍♂️',
  ':man_bowing_tone1:': '🙇🏻‍♂️',
  ':man_bowing_medium_light_skin_tone:': '🙇🏼‍♂️',
  ':man_bowing_tone2:': '🙇🏼‍♂️',
  ':man_bowing_medium_skin_tone:': '🙇🏽‍♂️',
  ':man_bowing_tone3:': '🙇🏽‍♂️',
  ':man_bowing_medium_dark_skin_tone:': '🙇🏾‍♂️',
  ':man_bowing_tone4:': '🙇🏾‍♂️',
  ':man_bowing_dark_skin_tone:': '🙇🏿‍♂️',
  ':man_bowing_tone5:': '🙇🏿‍♂️',
  ':man_cartwheeling_light_skin_tone:': '🤸🏻‍♂️',
  ':man_cartwheeling_tone1:': '🤸🏻‍♂️',
  ':man_cartwheeling_medium_light_skin_tone:': '🤸🏼‍♂️',
  ':man_cartwheeling_tone2:': '🤸🏼‍♂️',
  ':man_cartwheeling_medium_skin_tone:': '🤸🏽‍♂️',
  ':man_cartwheeling_tone3:': '🤸🏽‍♂️',
  ':man_cartwheeling_medium_dark_skin_tone:': '🤸🏾‍♂️',
  ':man_cartwheeling_tone4:': '🤸🏾‍♂️',
  ':man_cartwheeling_dark_skin_tone:': '🤸🏿‍♂️',
  ':man_cartwheeling_tone5:': '🤸🏿‍♂️',
  ':man_climbing_light_skin_tone:': '🧗🏻‍♂️',
  ':man_climbing_tone1:': '🧗🏻‍♂️',
  ':man_climbing_medium_light_skin_tone:': '🧗🏼‍♂️',
  ':man_climbing_tone2:': '🧗🏼‍♂️',
  ':man_climbing_medium_skin_tone:': '🧗🏽‍♂️',
  ':man_climbing_tone3:': '🧗🏽‍♂️',
  ':man_climbing_medium_dark_skin_tone:': '🧗🏾‍♂️',
  ':man_climbing_tone4:': '🧗🏾‍♂️',
  ':man_climbing_dark_skin_tone:': '🧗🏿‍♂️',
  ':man_climbing_tone5:': '🧗🏿‍♂️',
  ':man_construction_worker_light_skin_tone:': '👷🏻‍♂️',
  ':man_construction_worker_tone1:': '👷🏻‍♂️',
  ':man_construction_worker_medium_light_skin_tone:': '👷🏼‍♂️',
  ':man_construction_worker_tone2:': '👷🏼‍♂️',
  ':man_construction_worker_medium_skin_tone:': '👷🏽‍♂️',
  ':man_construction_worker_tone3:': '👷🏽‍♂️',
  ':man_construction_worker_medium_dark_skin_tone:': '👷🏾‍♂️',
  ':man_construction_worker_tone4:': '👷🏾‍♂️',
  ':man_construction_worker_dark_skin_tone:': '👷🏿‍♂️',
  ':man_construction_worker_tone5:': '👷🏿‍♂️',
  ':man_detective_light_skin_tone:': '🕵️🏻‍♂️',
  ':man_detective_tone1:': '🕵️🏻‍♂️',
  ':man_detective_medium_light_skin_tone:': '🕵️🏼‍♂️',
  ':man_detective_tone2:': '🕵️🏼‍♂️',
  ':man_detective_medium_skin_tone:': '🕵️🏽‍♂️',
  ':man_detective_tone3:': '🕵️🏽‍♂️',
  ':man_detective_medium_dark_skin_tone:': '🕵️🏾‍♂️',
  ':man_detective_tone4:': '🕵️🏾‍♂️',
  ':man_detective_dark_skin_tone:': '🕵️🏿‍♂️',
  ':man_detective_tone5:': '🕵️🏿‍♂️',
  ':man_elf_light_skin_tone:': '🧝🏻‍♂️',
  ':man_elf_tone1:': '🧝🏻‍♂️',
  ':man_elf_medium_light_skin_tone:': '🧝🏼‍♂️',
  ':man_elf_tone2:': '🧝🏼‍♂️',
  ':man_elf_medium_skin_tone:': '🧝🏽‍♂️',
  ':man_elf_tone3:': '🧝🏽‍♂️',
  ':man_elf_medium_dark_skin_tone:': '🧝🏾‍♂️',
  ':man_elf_tone4:': '🧝🏾‍♂️',
  ':man_elf_dark_skin_tone:': '🧝🏿‍♂️',
  ':man_elf_tone5:': '🧝🏿‍♂️',
  ':man_facepalming_light_skin_tone:': '🤦🏻‍♂️',
  ':man_facepalming_tone1:': '🤦🏻‍♂️',
  ':man_facepalming_medium_light_skin_tone:': '🤦🏼‍♂️',
  ':man_facepalming_tone2:': '🤦🏼‍♂️',
  ':man_facepalming_medium_skin_tone:': '🤦🏽‍♂️',
  ':man_facepalming_tone3:': '🤦🏽‍♂️',
  ':man_facepalming_medium_dark_skin_tone:': '🤦🏾‍♂️',
  ':man_facepalming_tone4:': '🤦🏾‍♂️',
  ':man_facepalming_dark_skin_tone:': '🤦🏿‍♂️',
  ':man_facepalming_tone5:': '🤦🏿‍♂️',
  ':man_fairy_light_skin_tone:': '🧚🏻‍♂️',
  ':man_fairy_tone1:': '🧚🏻‍♂️',
  ':man_fairy_medium_light_skin_tone:': '🧚🏼‍♂️',
  ':man_fairy_tone2:': '🧚🏼‍♂️',
  ':man_fairy_medium_skin_tone:': '🧚🏽‍♂️',
  ':man_fairy_tone3:': '🧚🏽‍♂️',
  ':man_fairy_medium_dark_skin_tone:': '🧚🏾‍♂️',
  ':man_fairy_tone4:': '🧚🏾‍♂️',
  ':man_fairy_dark_skin_tone:': '🧚🏿‍♂️',
  ':man_fairy_tone5:': '🧚🏿‍♂️',
  ':man_frowning_light_skin_tone:': '🙍🏻‍♂️',
  ':man_frowning_tone1:': '🙍🏻‍♂️',
  ':man_frowning_medium_light_skin_tone:': '🙍🏼‍♂️',
  ':man_frowning_tone2:': '🙍🏼‍♂️',
  ':man_frowning_medium_skin_tone:': '🙍🏽‍♂️',
  ':man_frowning_tone3:': '🙍🏽‍♂️',
  ':man_frowning_medium_dark_skin_tone:': '🙍🏾‍♂️',
  ':man_frowning_tone4:': '🙍🏾‍♂️',
  ':man_frowning_dark_skin_tone:': '🙍🏿‍♂️',
  ':man_frowning_tone5:': '🙍🏿‍♂️',
  ':man_gesturing_no_light_skin_tone:': '🙅🏻‍♂️',
  ':man_gesturing_no_tone1:': '🙅🏻‍♂️',
  ':man_gesturing_no_medium_light_skin_tone:': '🙅🏼‍♂️',
  ':man_gesturing_no_tone2:': '🙅🏼‍♂️',
  ':man_gesturing_no_medium_skin_tone:': '🙅🏽‍♂️',
  ':man_gesturing_no_tone3:': '🙅🏽‍♂️',
  ':man_gesturing_no_medium_dark_skin_tone:': '🙅🏾‍♂️',
  ':man_gesturing_no_tone4:': '🙅🏾‍♂️',
  ':man_gesturing_no_dark_skin_tone:': '🙅🏿‍♂️',
  ':man_gesturing_no_tone5:': '🙅🏿‍♂️',
  ':man_gesturing_ok_light_skin_tone:': '🙆🏻‍♂️',
  ':man_gesturing_ok_tone1:': '🙆🏻‍♂️',
  ':man_gesturing_ok_medium_light_skin_tone:': '🙆🏼‍♂️',
  ':man_gesturing_ok_tone2:': '🙆🏼‍♂️',
  ':man_gesturing_ok_medium_skin_tone:': '🙆🏽‍♂️',
  ':man_gesturing_ok_tone3:': '🙆🏽‍♂️',
  ':man_gesturing_ok_medium_dark_skin_tone:': '🙆🏾‍♂️',
  ':man_gesturing_ok_tone4:': '🙆🏾‍♂️',
  ':man_gesturing_ok_dark_skin_tone:': '🙆🏿‍♂️',
  ':man_gesturing_ok_tone5:': '🙆🏿‍♂️',
  ':man_getting_face_massage_light_skin_tone:': '💆🏻‍♂️',
  ':man_getting_face_massage_tone1:': '💆🏻‍♂️',
  ':man_getting_face_massage_medium_light_skin_tone:': '💆🏼‍♂️',
  ':man_getting_face_massage_tone2:': '💆🏼‍♂️',
  ':man_getting_face_massage_medium_skin_tone:': '💆🏽‍♂️',
  ':man_getting_face_massage_tone3:': '💆🏽‍♂️',
  ':man_getting_face_massage_medium_dark_skin_tone:': '💆🏾‍♂️',
  ':man_getting_face_massage_tone4:': '💆🏾‍♂️',
  ':man_getting_face_massage_dark_skin_tone:': '💆🏿‍♂️',
  ':man_getting_face_massage_tone5:': '💆🏿‍♂️',
  ':man_getting_haircut_light_skin_tone:': '💇🏻‍♂️',
  ':man_getting_haircut_tone1:': '💇🏻‍♂️',
  ':man_getting_haircut_medium_light_skin_tone:': '💇🏼‍♂️',
  ':man_getting_haircut_tone2:': '💇🏼‍♂️',
  ':man_getting_haircut_medium_skin_tone:': '💇🏽‍♂️',
  ':man_getting_haircut_tone3:': '💇🏽‍♂️',
  ':man_getting_haircut_medium_dark_skin_tone:': '💇🏾‍♂️',
  ':man_getting_haircut_tone4:': '💇🏾‍♂️',
  ':man_getting_haircut_dark_skin_tone:': '💇🏿‍♂️',
  ':man_getting_haircut_tone5:': '💇🏿‍♂️',
  ':man_golfing_light_skin_tone:': '🏌️🏻‍♂️',
  ':man_golfing_tone1:': '🏌️🏻‍♂️',
  ':man_golfing_medium_light_skin_tone:': '🏌️🏼‍♂️',
  ':man_golfing_tone2:': '🏌️🏼‍♂️',
  ':man_golfing_medium_skin_tone:': '🏌️🏽‍♂️',
  ':man_golfing_tone3:': '🏌️🏽‍♂️',
  ':man_golfing_medium_dark_skin_tone:': '🏌️🏾‍♂️',
  ':man_golfing_tone4:': '🏌️🏾‍♂️',
  ':man_golfing_dark_skin_tone:': '🏌️🏿‍♂️',
  ':man_golfing_tone5:': '🏌️🏿‍♂️',
  ':man_guard_light_skin_tone:': '💂🏻‍♂️',
  ':man_guard_tone1:': '💂🏻‍♂️',
  ':man_guard_medium_light_skin_tone:': '💂🏼‍♂️',
  ':man_guard_tone2:': '💂🏼‍♂️',
  ':man_guard_medium_skin_tone:': '💂🏽‍♂️',
  ':man_guard_tone3:': '💂🏽‍♂️',
  ':man_guard_medium_dark_skin_tone:': '💂🏾‍♂️',
  ':man_guard_tone4:': '💂🏾‍♂️',
  ':man_guard_dark_skin_tone:': '💂🏿‍♂️',
  ':man_guard_tone5:': '💂🏿‍♂️',
  ':man_health_worker_light_skin_tone:': '👨🏻‍⚕️',
  ':man_health_worker_tone1:': '👨🏻‍⚕️',
  ':man_health_worker_medium_light_skin_tone:': '👨🏼‍⚕️',
  ':man_health_worker_tone2:': '👨🏼‍⚕️',
  ':man_health_worker_medium_skin_tone:': '👨🏽‍⚕️',
  ':man_health_worker_tone3:': '👨🏽‍⚕️',
  ':man_health_worker_medium_dark_skin_tone:': '👨🏾‍⚕️',
  ':man_health_worker_tone4:': '👨🏾‍⚕️',
  ':man_health_worker_dark_skin_tone:': '👨🏿‍⚕️',
  ':man_health_worker_tone5:': '👨🏿‍⚕️',
  ':man_in_lotus_position_light_skin_tone:': '🧘🏻‍♂️',
  ':man_in_lotus_position_tone1:': '🧘🏻‍♂️',
  ':man_in_lotus_position_medium_light_skin_tone:': '🧘🏼‍♂️',
  ':man_in_lotus_position_tone2:': '🧘🏼‍♂️',
  ':man_in_lotus_position_medium_skin_tone:': '🧘🏽‍♂️',
  ':man_in_lotus_position_tone3:': '🧘🏽‍♂️',
  ':man_in_lotus_position_medium_dark_skin_tone:': '🧘🏾‍♂️',
  ':man_in_lotus_position_tone4:': '🧘🏾‍♂️',
  ':man_in_lotus_position_dark_skin_tone:': '🧘🏿‍♂️',
  ':man_in_lotus_position_tone5:': '🧘🏿‍♂️',
  ':man_in_steamy_room_light_skin_tone:': '🧖🏻‍♂️',
  ':man_in_steamy_room_tone1:': '🧖🏻‍♂️',
  ':man_in_steamy_room_medium_light_skin_tone:': '🧖🏼‍♂️',
  ':man_in_steamy_room_tone2:': '🧖🏼‍♂️',
  ':man_in_steamy_room_medium_skin_tone:': '🧖🏽‍♂️',
  ':man_in_steamy_room_tone3:': '🧖🏽‍♂️',
  ':man_in_steamy_room_medium_dark_skin_tone:': '🧖🏾‍♂️',
  ':man_in_steamy_room_tone4:': '🧖🏾‍♂️',
  ':man_in_steamy_room_dark_skin_tone:': '🧖🏿‍♂️',
  ':man_in_steamy_room_tone5:': '🧖🏿‍♂️',
  ':man_judge_light_skin_tone:': '👨🏻‍⚖️',
  ':man_judge_tone1:': '👨🏻‍⚖️',
  ':man_judge_medium_light_skin_tone:': '👨🏼‍⚖️',
  ':man_judge_tone2:': '👨🏼‍⚖️',
  ':man_judge_medium_skin_tone:': '👨🏽‍⚖️',
  ':man_judge_tone3:': '👨🏽‍⚖️',
  ':man_judge_medium_dark_skin_tone:': '👨🏾‍⚖️',
  ':man_judge_tone4:': '👨🏾‍⚖️',
  ':man_judge_dark_skin_tone:': '👨🏿‍⚖️',
  ':man_judge_tone5:': '👨🏿‍⚖️',
  ':man_juggling_light_skin_tone:': '🤹🏻‍♂️',
  ':man_juggling_tone1:': '🤹🏻‍♂️',
  ':man_juggling_medium_light_skin_tone:': '🤹🏼‍♂️',
  ':man_juggling_tone2:': '🤹🏼‍♂️',
  ':man_juggling_medium_skin_tone:': '🤹🏽‍♂️',
  ':man_juggling_tone3:': '🤹🏽‍♂️',
  ':man_juggling_medium_dark_skin_tone:': '🤹🏾‍♂️',
  ':man_juggling_tone4:': '🤹🏾‍♂️',
  ':man_juggling_dark_skin_tone:': '🤹🏿‍♂️',
  ':man_juggling_tone5:': '🤹🏿‍♂️',
  ':man_kneeling_light_skin_tone:': '🧎🏻‍♂️',
  ':man_kneeling_tone1:': '🧎🏻‍♂️',
  ':man_kneeling_medium_light_skin_tone:': '🧎🏼‍♂️',
  ':man_kneeling_tone2:': '🧎🏼‍♂️',
  ':man_kneeling_medium_skin_tone:': '🧎🏽‍♂️',
  ':man_kneeling_tone3:': '🧎🏽‍♂️',
  ':man_kneeling_medium_dark_skin_tone:': '🧎🏾‍♂️',
  ':man_kneeling_tone4:': '🧎🏾‍♂️',
  ':man_kneeling_dark_skin_tone:': '🧎🏿‍♂️',
  ':man_kneeling_tone5:': '🧎🏿‍♂️',
  ':man_lifting_weights_light_skin_tone:': '🏋️🏻‍♂️',
  ':man_lifting_weights_tone1:': '🏋️🏻‍♂️',
  ':man_lifting_weights_medium_light_skin_tone:': '🏋️🏼‍♂️',
  ':man_lifting_weights_tone2:': '🏋️🏼‍♂️',
  ':man_lifting_weights_medium_skin_tone:': '🏋️🏽‍♂️',
  ':man_lifting_weights_tone3:': '🏋️🏽‍♂️',
  ':man_lifting_weights_medium_dark_skin_tone:': '🏋️🏾‍♂️',
  ':man_lifting_weights_tone4:': '🏋️🏾‍♂️',
  ':man_lifting_weights_dark_skin_tone:': '🏋️🏿‍♂️',
  ':man_lifting_weights_tone5:': '🏋️🏿‍♂️',
  ':man_mage_light_skin_tone:': '🧙🏻‍♂️',
  ':man_mage_tone1:': '🧙🏻‍♂️',
  ':man_mage_medium_light_skin_tone:': '🧙🏼‍♂️',
  ':man_mage_tone2:': '🧙🏼‍♂️',
  ':man_mage_medium_skin_tone:': '🧙🏽‍♂️',
  ':man_mage_tone3:': '🧙🏽‍♂️',
  ':man_mage_medium_dark_skin_tone:': '🧙🏾‍♂️',
  ':man_mage_tone4:': '🧙🏾‍♂️',
  ':man_mage_dark_skin_tone:': '🧙🏿‍♂️',
  ':man_mage_tone5:': '🧙🏿‍♂️',
  ':man_mountain_biking_light_skin_tone:': '🚵🏻‍♂️',
  ':man_mountain_biking_tone1:': '🚵🏻‍♂️',
  ':man_mountain_biking_medium_light_skin_tone:': '🚵🏼‍♂️',
  ':man_mountain_biking_tone2:': '🚵🏼‍♂️',
  ':man_mountain_biking_medium_skin_tone:': '🚵🏽‍♂️',
  ':man_mountain_biking_tone3:': '🚵🏽‍♂️',
  ':man_mountain_biking_medium_dark_skin_tone:': '🚵🏾‍♂️',
  ':man_mountain_biking_tone4:': '🚵🏾‍♂️',
  ':man_mountain_biking_dark_skin_tone:': '🚵🏿‍♂️',
  ':man_mountain_biking_tone5:': '🚵🏿‍♂️',
  ':man_pilot_light_skin_tone:': '👨🏻‍✈️',
  ':man_pilot_tone1:': '👨🏻‍✈️',
  ':man_pilot_medium_light_skin_tone:': '👨🏼‍✈️',
  ':man_pilot_tone2:': '👨🏼‍✈️',
  ':man_pilot_medium_skin_tone:': '👨🏽‍✈️',
  ':man_pilot_tone3:': '👨🏽‍✈️',
  ':man_pilot_medium_dark_skin_tone:': '👨🏾‍✈️',
  ':man_pilot_tone4:': '👨🏾‍✈️',
  ':man_pilot_dark_skin_tone:': '👨🏿‍✈️',
  ':man_pilot_tone5:': '👨🏿‍✈️',
  ':man_playing_handball_light_skin_tone:': '🤾🏻‍♂️',
  ':man_playing_handball_tone1:': '🤾🏻‍♂️',
  ':man_playing_handball_medium_light_skin_tone:': '🤾🏼‍♂️',
  ':man_playing_handball_tone2:': '🤾🏼‍♂️',
  ':man_playing_handball_medium_skin_tone:': '🤾🏽‍♂️',
  ':man_playing_handball_tone3:': '🤾🏽‍♂️',
  ':man_playing_handball_medium_dark_skin_tone:': '🤾🏾‍♂️',
  ':man_playing_handball_tone4:': '🤾🏾‍♂️',
  ':man_playing_handball_dark_skin_tone:': '🤾🏿‍♂️',
  ':man_playing_handball_tone5:': '🤾🏿‍♂️',
  ':man_playing_water_polo_light_skin_tone:': '🤽🏻‍♂️',
  ':man_playing_water_polo_tone1:': '🤽🏻‍♂️',
  ':man_playing_water_polo_medium_light_skin_tone:': '🤽🏼‍♂️',
  ':man_playing_water_polo_tone2:': '🤽🏼‍♂️',
  ':man_playing_water_polo_medium_skin_tone:': '🤽🏽‍♂️',
  ':man_playing_water_polo_tone3:': '🤽🏽‍♂️',
  ':man_playing_water_polo_medium_dark_skin_tone:': '🤽🏾‍♂️',
  ':man_playing_water_polo_tone4:': '🤽🏾‍♂️',
  ':man_playing_water_polo_dark_skin_tone:': '🤽🏿‍♂️',
  ':man_playing_water_polo_tone5:': '🤽🏿‍♂️',
  ':man_police_officer_light_skin_tone:': '👮🏻‍♂️',
  ':man_police_officer_tone1:': '👮🏻‍♂️',
  ':man_police_officer_medium_light_skin_tone:': '👮🏼‍♂️',
  ':man_police_officer_tone2:': '👮🏼‍♂️',
  ':man_police_officer_medium_skin_tone:': '👮🏽‍♂️',
  ':man_police_officer_tone3:': '👮🏽‍♂️',
  ':man_police_officer_medium_dark_skin_tone:': '👮🏾‍♂️',
  ':man_police_officer_tone4:': '👮🏾‍♂️',
  ':man_police_officer_dark_skin_tone:': '👮🏿‍♂️',
  ':man_police_officer_tone5:': '👮🏿‍♂️',
  ':man_pouting_light_skin_tone:': '🙎🏻‍♂️',
  ':man_pouting_tone1:': '🙎🏻‍♂️',
  ':man_pouting_medium_light_skin_tone:': '🙎🏼‍♂️',
  ':man_pouting_tone2:': '🙎🏼‍♂️',
  ':man_pouting_medium_skin_tone:': '🙎🏽‍♂️',
  ':man_pouting_tone3:': '🙎🏽‍♂️',
  ':man_pouting_medium_dark_skin_tone:': '🙎🏾‍♂️',
  ':man_pouting_tone4:': '🙎🏾‍♂️',
  ':man_pouting_dark_skin_tone:': '🙎🏿‍♂️',
  ':man_pouting_tone5:': '🙎🏿‍♂️',
  ':man_raising_hand_light_skin_tone:': '🙋🏻‍♂️',
  ':man_raising_hand_tone1:': '🙋🏻‍♂️',
  ':man_raising_hand_medium_light_skin_tone:': '🙋🏼‍♂️',
  ':man_raising_hand_tone2:': '🙋🏼‍♂️',
  ':man_raising_hand_medium_skin_tone:': '🙋🏽‍♂️',
  ':man_raising_hand_tone3:': '🙋🏽‍♂️',
  ':man_raising_hand_medium_dark_skin_tone:': '🙋🏾‍♂️',
  ':man_raising_hand_tone4:': '🙋🏾‍♂️',
  ':man_raising_hand_dark_skin_tone:': '🙋🏿‍♂️',
  ':man_raising_hand_tone5:': '🙋🏿‍♂️',
  ':man_rowing_boat_light_skin_tone:': '🚣🏻‍♂️',
  ':man_rowing_boat_tone1:': '🚣🏻‍♂️',
  ':man_rowing_boat_medium_light_skin_tone:': '🚣🏼‍♂️',
  ':man_rowing_boat_tone2:': '🚣🏼‍♂️',
  ':man_rowing_boat_medium_skin_tone:': '🚣🏽‍♂️',
  ':man_rowing_boat_tone3:': '🚣🏽‍♂️',
  ':man_rowing_boat_medium_dark_skin_tone:': '🚣🏾‍♂️',
  ':man_rowing_boat_tone4:': '🚣🏾‍♂️',
  ':man_rowing_boat_dark_skin_tone:': '🚣🏿‍♂️',
  ':man_rowing_boat_tone5:': '🚣🏿‍♂️',
  ':man_running_light_skin_tone:': '🏃🏻‍♂️',
  ':man_running_tone1:': '🏃🏻‍♂️',
  ':man_running_medium_light_skin_tone:': '🏃🏼‍♂️',
  ':man_running_tone2:': '🏃🏼‍♂️',
  ':man_running_medium_skin_tone:': '🏃🏽‍♂️',
  ':man_running_tone3:': '🏃🏽‍♂️',
  ':man_running_medium_dark_skin_tone:': '🏃🏾‍♂️',
  ':man_running_tone4:': '🏃🏾‍♂️',
  ':man_running_dark_skin_tone:': '🏃🏿‍♂️',
  ':man_running_tone5:': '🏃🏿‍♂️',
  ':man_shrugging_light_skin_tone:': '🤷🏻‍♂️',
  ':man_shrugging_tone1:': '🤷🏻‍♂️',
  ':man_shrugging_medium_light_skin_tone:': '🤷🏼‍♂️',
  ':man_shrugging_tone2:': '🤷🏼‍♂️',
  ':man_shrugging_medium_skin_tone:': '🤷🏽‍♂️',
  ':man_shrugging_tone3:': '🤷🏽‍♂️',
  ':man_shrugging_medium_dark_skin_tone:': '🤷🏾‍♂️',
  ':man_shrugging_tone4:': '🤷🏾‍♂️',
  ':man_shrugging_dark_skin_tone:': '🤷🏿‍♂️',
  ':man_shrugging_tone5:': '🤷🏿‍♂️',
  ':man_standing_light_skin_tone:': '🧍🏻‍♂️',
  ':man_standing_tone1:': '🧍🏻‍♂️',
  ':man_standing_medium_light_skin_tone:': '🧍🏼‍♂️',
  ':man_standing_tone2:': '🧍🏼‍♂️',
  ':man_standing_medium_skin_tone:': '🧍🏽‍♂️',
  ':man_standing_tone3:': '🧍🏽‍♂️',
  ':man_standing_medium_dark_skin_tone:': '🧍🏾‍♂️',
  ':man_standing_tone4:': '🧍🏾‍♂️',
  ':man_standing_dark_skin_tone:': '🧍🏿‍♂️',
  ':man_standing_tone5:': '🧍🏿‍♂️',
  ':man_superhero_light_skin_tone:': '🦸🏻‍♂️',
  ':man_superhero_tone1:': '🦸🏻‍♂️',
  ':man_superhero_medium_light_skin_tone:': '🦸🏼‍♂️',
  ':man_superhero_tone2:': '🦸🏼‍♂️',
  ':man_superhero_medium_skin_tone:': '🦸🏽‍♂️',
  ':man_superhero_tone3:': '🦸🏽‍♂️',
  ':man_superhero_medium_dark_skin_tone:': '🦸🏾‍♂️',
  ':man_superhero_tone4:': '🦸🏾‍♂️',
  ':man_superhero_dark_skin_tone:': '🦸🏿‍♂️',
  ':man_superhero_tone5:': '🦸🏿‍♂️',
  ':man_supervillain_light_skin_tone:': '🦹🏻‍♂️',
  ':man_supervillain_tone1:': '🦹🏻‍♂️',
  ':man_supervillain_medium_light_skin_tone:': '🦹🏼‍♂️',
  ':man_supervillain_tone2:': '🦹🏼‍♂️',
  ':man_supervillain_medium_skin_tone:': '🦹🏽‍♂️',
  ':man_supervillain_tone3:': '🦹🏽‍♂️',
  ':man_supervillain_medium_dark_skin_tone:': '🦹🏾‍♂️',
  ':man_supervillain_tone4:': '🦹🏾‍♂️',
  ':man_supervillain_dark_skin_tone:': '🦹🏿‍♂️',
  ':man_supervillain_tone5:': '🦹🏿‍♂️',
  ':man_surfing_light_skin_tone:': '🏄🏻‍♂️',
  ':man_surfing_tone1:': '🏄🏻‍♂️',
  ':man_surfing_medium_light_skin_tone:': '🏄🏼‍♂️',
  ':man_surfing_tone2:': '🏄🏼‍♂️',
  ':man_surfing_medium_skin_tone:': '🏄🏽‍♂️',
  ':man_surfing_tone3:': '🏄🏽‍♂️',
  ':man_surfing_medium_dark_skin_tone:': '🏄🏾‍♂️',
  ':man_surfing_tone4:': '🏄🏾‍♂️',
  ':man_surfing_dark_skin_tone:': '🏄🏿‍♂️',
  ':man_surfing_tone5:': '🏄🏿‍♂️',
  ':man_swimming_light_skin_tone:': '🏊🏻‍♂️',
  ':man_swimming_tone1:': '🏊🏻‍♂️',
  ':man_swimming_medium_light_skin_tone:': '🏊🏼‍♂️',
  ':man_swimming_tone2:': '🏊🏼‍♂️',
  ':man_swimming_medium_skin_tone:': '🏊🏽‍♂️',
  ':man_swimming_tone3:': '🏊🏽‍♂️',
  ':man_swimming_medium_dark_skin_tone:': '🏊🏾‍♂️',
  ':man_swimming_tone4:': '🏊🏾‍♂️',
  ':man_swimming_dark_skin_tone:': '🏊🏿‍♂️',
  ':man_swimming_tone5:': '🏊🏿‍♂️',
  ':man_tipping_hand_light_skin_tone:': '💁🏻‍♂️',
  ':man_tipping_hand_tone1:': '💁🏻‍♂️',
  ':man_tipping_hand_medium_light_skin_tone:': '💁🏼‍♂️',
  ':man_tipping_hand_tone2:': '💁🏼‍♂️',
  ':man_tipping_hand_medium_skin_tone:': '💁🏽‍♂️',
  ':man_tipping_hand_tone3:': '💁🏽‍♂️',
  ':man_tipping_hand_medium_dark_skin_tone:': '💁🏾‍♂️',
  ':man_tipping_hand_tone4:': '💁🏾‍♂️',
  ':man_tipping_hand_dark_skin_tone:': '💁🏿‍♂️',
  ':man_tipping_hand_tone5:': '💁🏿‍♂️',
  ':man_vampire_light_skin_tone:': '🧛🏻‍♂️',
  ':man_vampire_tone1:': '🧛🏻‍♂️',
  ':man_vampire_medium_light_skin_tone:': '🧛🏼‍♂️',
  ':man_vampire_tone2:': '🧛🏼‍♂️',
  ':man_vampire_medium_skin_tone:': '🧛🏽‍♂️',
  ':man_vampire_tone3:': '🧛🏽‍♂️',
  ':man_vampire_medium_dark_skin_tone:': '🧛🏾‍♂️',
  ':man_vampire_tone4:': '🧛🏾‍♂️',
  ':man_vampire_dark_skin_tone:': '🧛🏿‍♂️',
  ':man_vampire_tone5:': '🧛🏿‍♂️',
  ':man_walking_light_skin_tone:': '🚶🏻‍♂️',
  ':man_walking_tone1:': '🚶🏻‍♂️',
  ':man_walking_medium_light_skin_tone:': '🚶🏼‍♂️',
  ':man_walking_tone2:': '🚶🏼‍♂️',
  ':man_walking_medium_skin_tone:': '🚶🏽‍♂️',
  ':man_walking_tone3:': '🚶🏽‍♂️',
  ':man_walking_medium_dark_skin_tone:': '🚶🏾‍♂️',
  ':man_walking_tone4:': '🚶🏾‍♂️',
  ':man_walking_dark_skin_tone:': '🚶🏿‍♂️',
  ':man_walking_tone5:': '🚶🏿‍♂️',
  ':man_wearing_turban_light_skin_tone:': '👳🏻‍♂️',
  ':man_wearing_turban_tone1:': '👳🏻‍♂️',
  ':man_wearing_turban_medium_light_skin_tone:': '👳🏼‍♂️',
  ':man_wearing_turban_tone2:': '👳🏼‍♂️',
  ':man_wearing_turban_medium_skin_tone:': '👳🏽‍♂️',
  ':man_wearing_turban_tone3:': '👳🏽‍♂️',
  ':man_wearing_turban_medium_dark_skin_tone:': '👳🏾‍♂️',
  ':man_wearing_turban_tone4:': '👳🏾‍♂️',
  ':man_wearing_turban_dark_skin_tone:': '👳🏿‍♂️',
  ':man_wearing_turban_tone5:': '👳🏿‍♂️',
  ':mermaid_light_skin_tone:': '🧜🏻‍♀️',
  ':mermaid_tone1:': '🧜🏻‍♀️',
  ':mermaid_medium_light_skin_tone:': '🧜🏼‍♀️',
  ':mermaid_tone2:': '🧜🏼‍♀️',
  ':mermaid_medium_skin_tone:': '🧜🏽‍♀️',
  ':mermaid_tone3:': '🧜🏽‍♀️',
  ':mermaid_medium_dark_skin_tone:': '🧜🏾‍♀️',
  ':mermaid_tone4:': '🧜🏾‍♀️',
  ':mermaid_dark_skin_tone:': '🧜🏿‍♀️',
  ':mermaid_tone5:': '🧜🏿‍♀️',
  ':merman_light_skin_tone:': '🧜🏻‍♂️',
  ':merman_tone1:': '🧜🏻‍♂️',
  ':merman_medium_light_skin_tone:': '🧜🏼‍♂️',
  ':merman_tone2:': '🧜🏼‍♂️',
  ':merman_medium_skin_tone:': '🧜🏽‍♂️',
  ':merman_tone3:': '🧜🏽‍♂️',
  ':merman_medium_dark_skin_tone:': '🧜🏾‍♂️',
  ':merman_tone4:': '🧜🏾‍♂️',
  ':merman_dark_skin_tone:': '🧜🏿‍♂️',
  ':merman_tone5:': '🧜🏿‍♂️',
  ':woman_biking_light_skin_tone:': '🚴🏻‍♀️',
  ':woman_biking_tone1:': '🚴🏻‍♀️',
  ':woman_biking_medium_light_skin_tone:': '🚴🏼‍♀️',
  ':woman_biking_tone2:': '🚴🏼‍♀️',
  ':woman_biking_medium_skin_tone:': '🚴🏽‍♀️',
  ':woman_biking_tone3:': '🚴🏽‍♀️',
  ':woman_biking_medium_dark_skin_tone:': '🚴🏾‍♀️',
  ':woman_biking_tone4:': '🚴🏾‍♀️',
  ':woman_biking_dark_skin_tone:': '🚴🏿‍♀️',
  ':woman_biking_tone5:': '🚴🏿‍♀️',
  ':woman_bowing_light_skin_tone:': '🙇🏻‍♀️',
  ':woman_bowing_tone1:': '🙇🏻‍♀️',
  ':woman_bowing_medium_light_skin_tone:': '🙇🏼‍♀️',
  ':woman_bowing_tone2:': '🙇🏼‍♀️',
  ':woman_bowing_medium_skin_tone:': '🙇🏽‍♀️',
  ':woman_bowing_tone3:': '🙇🏽‍♀️',
  ':woman_bowing_medium_dark_skin_tone:': '🙇🏾‍♀️',
  ':woman_bowing_tone4:': '🙇🏾‍♀️',
  ':woman_bowing_dark_skin_tone:': '🙇🏿‍♀️',
  ':woman_bowing_tone5:': '🙇🏿‍♀️',
  ':woman_cartwheeling_light_skin_tone:': '🤸🏻‍♀️',
  ':woman_cartwheeling_tone1:': '🤸🏻‍♀️',
  ':woman_cartwheeling_medium_light_skin_tone:': '🤸🏼‍♀️',
  ':woman_cartwheeling_tone2:': '🤸🏼‍♀️',
  ':woman_cartwheeling_medium_skin_tone:': '🤸🏽‍♀️',
  ':woman_cartwheeling_tone3:': '🤸🏽‍♀️',
  ':woman_cartwheeling_medium_dark_skin_tone:': '🤸🏾‍♀️',
  ':woman_cartwheeling_tone4:': '🤸🏾‍♀️',
  ':woman_cartwheeling_dark_skin_tone:': '🤸🏿‍♀️',
  ':woman_cartwheeling_tone5:': '🤸🏿‍♀️',
  ':woman_climbing_light_skin_tone:': '🧗🏻‍♀️',
  ':woman_climbing_tone1:': '🧗🏻‍♀️',
  ':woman_climbing_medium_light_skin_tone:': '🧗🏼‍♀️',
  ':woman_climbing_tone2:': '🧗🏼‍♀️',
  ':woman_climbing_medium_skin_tone:': '🧗🏽‍♀️',
  ':woman_climbing_tone3:': '🧗🏽‍♀️',
  ':woman_climbing_medium_dark_skin_tone:': '🧗🏾‍♀️',
  ':woman_climbing_tone4:': '🧗🏾‍♀️',
  ':woman_climbing_dark_skin_tone:': '🧗🏿‍♀️',
  ':woman_climbing_tone5:': '🧗🏿‍♀️',
  ':woman_construction_worker_light_skin_tone:': '👷🏻‍♀️',
  ':woman_construction_worker_tone1:': '👷🏻‍♀️',
  ':woman_construction_worker_medium_light_skin_tone:': '👷🏼‍♀️',
  ':woman_construction_worker_tone2:': '👷🏼‍♀️',
  ':woman_construction_worker_medium_skin_tone:': '👷🏽‍♀️',
  ':woman_construction_worker_tone3:': '👷🏽‍♀️',
  ':woman_construction_worker_medium_dark_skin_tone:': '👷🏾‍♀️',
  ':woman_construction_worker_tone4:': '👷🏾‍♀️',
  ':woman_construction_worker_dark_skin_tone:': '👷🏿‍♀️',
  ':woman_construction_worker_tone5:': '👷🏿‍♀️',
  ':woman_detective_light_skin_tone:': '🕵️🏻‍♀️',
  ':woman_detective_tone1:': '🕵️🏻‍♀️',
  ':woman_detective_medium_light_skin_tone:': '🕵️🏼‍♀️',
  ':woman_detective_tone2:': '🕵️🏼‍♀️',
  ':woman_detective_medium_skin_tone:': '🕵️🏽‍♀️',
  ':woman_detective_tone3:': '🕵️🏽‍♀️',
  ':woman_detective_medium_dark_skin_tone:': '🕵️🏾‍♀️',
  ':woman_detective_tone4:': '🕵️🏾‍♀️',
  ':woman_detective_dark_skin_tone:': '🕵️🏿‍♀️',
  ':woman_detective_tone5:': '🕵️🏿‍♀️',
  ':woman_elf_light_skin_tone:': '🧝🏻‍♀️',
  ':woman_elf_tone1:': '🧝🏻‍♀️',
  ':woman_elf_medium_light_skin_tone:': '🧝🏼‍♀️',
  ':woman_elf_tone2:': '🧝🏼‍♀️',
  ':woman_elf_medium_skin_tone:': '🧝🏽‍♀️',
  ':woman_elf_tone3:': '🧝🏽‍♀️',
  ':woman_elf_medium_dark_skin_tone:': '🧝🏾‍♀️',
  ':woman_elf_tone4:': '🧝🏾‍♀️',
  ':woman_elf_dark_skin_tone:': '🧝🏿‍♀️',
  ':woman_elf_tone5:': '🧝🏿‍♀️',
  ':woman_facepalming_light_skin_tone:': '🤦🏻‍♀️',
  ':woman_facepalming_tone1:': '🤦🏻‍♀️',
  ':woman_facepalming_medium_light_skin_tone:': '🤦🏼‍♀️',
  ':woman_facepalming_tone2:': '🤦🏼‍♀️',
  ':woman_facepalming_medium_skin_tone:': '🤦🏽‍♀️',
  ':woman_facepalming_tone3:': '🤦🏽‍♀️',
  ':woman_facepalming_medium_dark_skin_tone:': '🤦🏾‍♀️',
  ':woman_facepalming_tone4:': '🤦🏾‍♀️',
  ':woman_facepalming_dark_skin_tone:': '🤦🏿‍♀️',
  ':woman_facepalming_tone5:': '🤦🏿‍♀️',
  ':woman_fairy_light_skin_tone:': '🧚🏻‍♀️',
  ':woman_fairy_tone1:': '🧚🏻‍♀️',
  ':woman_fairy_medium_light_skin_tone:': '🧚🏼‍♀️',
  ':woman_fairy_tone2:': '🧚🏼‍♀️',
  ':woman_fairy_medium_skin_tone:': '🧚🏽‍♀️',
  ':woman_fairy_tone3:': '🧚🏽‍♀️',
  ':woman_fairy_medium_dark_skin_tone:': '🧚🏾‍♀️',
  ':woman_fairy_tone4:': '🧚🏾‍♀️',
  ':woman_fairy_dark_skin_tone:': '🧚🏿‍♀️',
  ':woman_fairy_tone5:': '🧚🏿‍♀️',
  ':woman_frowning_light_skin_tone:': '🙍🏻‍♀️',
  ':woman_frowning_tone1:': '🙍🏻‍♀️',
  ':woman_frowning_medium_light_skin_tone:': '🙍🏼‍♀️',
  ':woman_frowning_tone2:': '🙍🏼‍♀️',
  ':woman_frowning_medium_skin_tone:': '🙍🏽‍♀️',
  ':woman_frowning_tone3:': '🙍🏽‍♀️',
  ':woman_frowning_medium_dark_skin_tone:': '🙍🏾‍♀️',
  ':woman_frowning_tone4:': '🙍🏾‍♀️',
  ':woman_frowning_dark_skin_tone:': '🙍🏿‍♀️',
  ':woman_frowning_tone5:': '🙍🏿‍♀️',
  ':woman_gesturing_no_light_skin_tone:': '🙅🏻‍♀️',
  ':woman_gesturing_no_tone1:': '🙅🏻‍♀️',
  ':woman_gesturing_no_medium_light_skin_tone:': '🙅🏼‍♀️',
  ':woman_gesturing_no_tone2:': '🙅🏼‍♀️',
  ':woman_gesturing_no_medium_skin_tone:': '🙅🏽‍♀️',
  ':woman_gesturing_no_tone3:': '🙅🏽‍♀️',
  ':woman_gesturing_no_medium_dark_skin_tone:': '🙅🏾‍♀️',
  ':woman_gesturing_no_tone4:': '🙅🏾‍♀️',
  ':woman_gesturing_no_dark_skin_tone:': '🙅🏿‍♀️',
  ':woman_gesturing_no_tone5:': '🙅🏿‍♀️',
  ':woman_gesturing_ok_light_skin_tone:': '🙆🏻‍♀️',
  ':woman_gesturing_ok_tone1:': '🙆🏻‍♀️',
  ':woman_gesturing_ok_medium_light_skin_tone:': '🙆🏼‍♀️',
  ':woman_gesturing_ok_tone2:': '🙆🏼‍♀️',
  ':woman_gesturing_ok_medium_skin_tone:': '🙆🏽‍♀️',
  ':woman_gesturing_ok_tone3:': '🙆🏽‍♀️',
  ':woman_gesturing_ok_medium_dark_skin_tone:': '🙆🏾‍♀️',
  ':woman_gesturing_ok_tone4:': '🙆🏾‍♀️',
  ':woman_gesturing_ok_dark_skin_tone:': '🙆🏿‍♀️',
  ':woman_gesturing_ok_tone5:': '🙆🏿‍♀️',
  ':woman_getting_face_massage_light_skin_tone:': '💆🏻‍♀️',
  ':woman_getting_face_massage_tone1:': '💆🏻‍♀️',
  ':woman_getting_face_massage_medium_light_skin_tone:': '💆🏼‍♀️',
  ':woman_getting_face_massage_tone2:': '💆🏼‍♀️',
  ':woman_getting_face_massage_medium_skin_tone:': '💆🏽‍♀️',
  ':woman_getting_face_massage_tone3:': '💆🏽‍♀️',
  ':woman_getting_face_massage_medium_dark_skin_tone:': '💆🏾‍♀️',
  ':woman_getting_face_massage_tone4:': '💆🏾‍♀️',
  ':woman_getting_face_massage_dark_skin_tone:': '💆🏿‍♀️',
  ':woman_getting_face_massage_tone5:': '💆🏿‍♀️',
  ':woman_getting_haircut_light_skin_tone:': '💇🏻‍♀️',
  ':woman_getting_haircut_tone1:': '💇🏻‍♀️',
  ':woman_getting_haircut_medium_light_skin_tone:': '💇🏼‍♀️',
  ':woman_getting_haircut_tone2:': '💇🏼‍♀️',
  ':woman_getting_haircut_medium_skin_tone:': '💇🏽‍♀️',
  ':woman_getting_haircut_tone3:': '💇🏽‍♀️',
  ':woman_getting_haircut_medium_dark_skin_tone:': '💇🏾‍♀️',
  ':woman_getting_haircut_tone4:': '💇🏾‍♀️',
  ':woman_getting_haircut_dark_skin_tone:': '💇🏿‍♀️',
  ':woman_getting_haircut_tone5:': '💇🏿‍♀️',
  ':woman_golfing_light_skin_tone:': '🏌️🏻‍♀️',
  ':woman_golfing_tone1:': '🏌️🏻‍♀️',
  ':woman_golfing_medium_light_skin_tone:': '🏌️🏼‍♀️',
  ':woman_golfing_tone2:': '🏌️🏼‍♀️',
  ':woman_golfing_medium_skin_tone:': '🏌️🏽‍♀️',
  ':woman_golfing_tone3:': '🏌️🏽‍♀️',
  ':woman_golfing_medium_dark_skin_tone:': '🏌️🏾‍♀️',
  ':woman_golfing_tone4:': '🏌️🏾‍♀️',
  ':woman_golfing_dark_skin_tone:': '🏌️🏿‍♀️',
  ':woman_golfing_tone5:': '🏌️🏿‍♀️',
  ':woman_guard_light_skin_tone:': '💂🏻‍♀️',
  ':woman_guard_tone1:': '💂🏻‍♀️',
  ':woman_guard_medium_light_skin_tone:': '💂🏼‍♀️',
  ':woman_guard_tone2:': '💂🏼‍♀️',
  ':woman_guard_medium_skin_tone:': '💂🏽‍♀️',
  ':woman_guard_tone3:': '💂🏽‍♀️',
  ':woman_guard_medium_dark_skin_tone:': '💂🏾‍♀️',
  ':woman_guard_tone4:': '💂🏾‍♀️',
  ':woman_guard_dark_skin_tone:': '💂🏿‍♀️',
  ':woman_guard_tone5:': '💂🏿‍♀️',
  ':woman_health_worker_light_skin_tone:': '👩🏻‍⚕️',
  ':woman_health_worker_tone1:': '👩🏻‍⚕️',
  ':woman_health_worker_medium_light_skin_tone:': '👩🏼‍⚕️',
  ':woman_health_worker_tone2:': '👩🏼‍⚕️',
  ':woman_health_worker_medium_skin_tone:': '👩🏽‍⚕️',
  ':woman_health_worker_tone3:': '👩🏽‍⚕️',
  ':woman_health_worker_medium_dark_skin_tone:': '👩🏾‍⚕️',
  ':woman_health_worker_tone4:': '👩🏾‍⚕️',
  ':woman_health_worker_dark_skin_tone:': '👩🏿‍⚕️',
  ':woman_health_worker_tone5:': '👩🏿‍⚕️',
  ':woman_in_lotus_position_light_skin_tone:': '🧘🏻‍♀️',
  ':woman_in_lotus_position_tone1:': '🧘🏻‍♀️',
  ':woman_in_lotus_position_medium_light_skin_tone:': '🧘🏼‍♀️',
  ':woman_in_lotus_position_tone2:': '🧘🏼‍♀️',
  ':woman_in_lotus_position_medium_skin_tone:': '🧘🏽‍♀️',
  ':woman_in_lotus_position_tone3:': '🧘🏽‍♀️',
  ':woman_in_lotus_position_medium_dark_skin_tone:': '🧘🏾‍♀️',
  ':woman_in_lotus_position_tone4:': '🧘🏾‍♀️',
  ':woman_in_lotus_position_dark_skin_tone:': '🧘🏿‍♀️',
  ':woman_in_lotus_position_tone5:': '🧘🏿‍♀️',
  ':woman_in_steamy_room_light_skin_tone:': '🧖🏻‍♀️',
  ':woman_in_steamy_room_tone1:': '🧖🏻‍♀️',
  ':woman_in_steamy_room_medium_light_skin_tone:': '🧖🏼‍♀️',
  ':woman_in_steamy_room_tone2:': '🧖🏼‍♀️',
  ':woman_in_steamy_room_medium_skin_tone:': '🧖🏽‍♀️',
  ':woman_in_steamy_room_tone3:': '🧖🏽‍♀️',
  ':woman_in_steamy_room_medium_dark_skin_tone:': '🧖🏾‍♀️',
  ':woman_in_steamy_room_tone4:': '🧖🏾‍♀️',
  ':woman_in_steamy_room_dark_skin_tone:': '🧖🏿‍♀️',
  ':woman_in_steamy_room_tone5:': '🧖🏿‍♀️',
  ':woman_judge_light_skin_tone:': '👩🏻‍⚖️',
  ':woman_judge_tone1:': '👩🏻‍⚖️',
  ':woman_judge_medium_light_skin_tone:': '👩🏼‍⚖️',
  ':woman_judge_tone2:': '👩🏼‍⚖️',
  ':woman_judge_medium_skin_tone:': '👩🏽‍⚖️',
  ':woman_judge_tone3:': '👩🏽‍⚖️',
  ':woman_judge_medium_dark_skin_tone:': '👩🏾‍⚖️',
  ':woman_judge_tone4:': '👩🏾‍⚖️',
  ':woman_judge_dark_skin_tone:': '👩🏿‍⚖️',
  ':woman_judge_tone5:': '👩🏿‍⚖️',
  ':woman_juggling_light_skin_tone:': '🤹🏻‍♀️',
  ':woman_juggling_tone1:': '🤹🏻‍♀️',
  ':woman_juggling_medium_light_skin_tone:': '🤹🏼‍♀️',
  ':woman_juggling_tone2:': '🤹🏼‍♀️',
  ':woman_juggling_medium_skin_tone:': '🤹🏽‍♀️',
  ':woman_juggling_tone3:': '🤹🏽‍♀️',
  ':woman_juggling_medium_dark_skin_tone:': '🤹🏾‍♀️',
  ':woman_juggling_tone4:': '🤹🏾‍♀️',
  ':woman_juggling_dark_skin_tone:': '🤹🏿‍♀️',
  ':woman_juggling_tone5:': '🤹🏿‍♀️',
  ':woman_kneeling_light_skin_tone:': '🧎🏻‍♀️',
  ':woman_kneeling_tone1:': '🧎🏻‍♀️',
  ':woman_kneeling_medium_light_skin_tone:': '🧎🏼‍♀️',
  ':woman_kneeling_tone2:': '🧎🏼‍♀️',
  ':woman_kneeling_medium_skin_tone:': '🧎🏽‍♀️',
  ':woman_kneeling_tone3:': '🧎🏽‍♀️',
  ':woman_kneeling_medium_dark_skin_tone:': '🧎🏾‍♀️',
  ':woman_kneeling_tone4:': '🧎🏾‍♀️',
  ':woman_kneeling_dark_skin_tone:': '🧎🏿‍♀️',
  ':woman_kneeling_tone5:': '🧎🏿‍♀️',
  ':woman_lifting_weights_light_skin_tone:': '🏋️🏻‍♀️',
  ':woman_lifting_weights_tone1:': '🏋️🏻‍♀️',
  ':woman_lifting_weights_medium_light_skin_tone:': '🏋️🏼‍♀️',
  ':woman_lifting_weights_tone2:': '🏋️🏼‍♀️',
  ':woman_lifting_weights_medium_skin_tone:': '🏋️🏽‍♀️',
  ':woman_lifting_weights_tone3:': '🏋️🏽‍♀️',
  ':woman_lifting_weights_medium_dark_skin_tone:': '🏋️🏾‍♀️',
  ':woman_lifting_weights_tone4:': '🏋️🏾‍♀️',
  ':woman_lifting_weights_dark_skin_tone:': '🏋️🏿‍♀️',
  ':woman_lifting_weights_tone5:': '🏋️🏿‍♀️',
  ':woman_mage_light_skin_tone:': '🧙🏻‍♀️',
  ':woman_mage_tone1:': '🧙🏻‍♀️',
  ':woman_mage_medium_light_skin_tone:': '🧙🏼‍♀️',
  ':woman_mage_tone2:': '🧙🏼‍♀️',
  ':woman_mage_medium_skin_tone:': '🧙🏽‍♀️',
  ':woman_mage_tone3:': '🧙🏽‍♀️',
  ':woman_mage_medium_dark_skin_tone:': '🧙🏾‍♀️',
  ':woman_mage_tone4:': '🧙🏾‍♀️',
  ':woman_mage_dark_skin_tone:': '🧙🏿‍♀️',
  ':woman_mage_tone5:': '🧙🏿‍♀️',
  ':woman_mountain_biking_light_skin_tone:': '🚵🏻‍♀️',
  ':woman_mountain_biking_tone1:': '🚵🏻‍♀️',
  ':woman_mountain_biking_medium_light_skin_tone:': '🚵🏼‍♀️',
  ':woman_mountain_biking_tone2:': '🚵🏼‍♀️',
  ':woman_mountain_biking_medium_skin_tone:': '🚵🏽‍♀️',
  ':woman_mountain_biking_tone3:': '🚵🏽‍♀️',
  ':woman_mountain_biking_medium_dark_skin_tone:': '🚵🏾‍♀️',
  ':woman_mountain_biking_tone4:': '🚵🏾‍♀️',
  ':woman_mountain_biking_dark_skin_tone:': '🚵🏿‍♀️',
  ':woman_mountain_biking_tone5:': '🚵🏿‍♀️',
  ':woman_pilot_light_skin_tone:': '👩🏻‍✈️',
  ':woman_pilot_tone1:': '👩🏻‍✈️',
  ':woman_pilot_medium_light_skin_tone:': '👩🏼‍✈️',
  ':woman_pilot_tone2:': '👩🏼‍✈️',
  ':woman_pilot_medium_skin_tone:': '👩🏽‍✈️',
  ':woman_pilot_tone3:': '👩🏽‍✈️',
  ':woman_pilot_medium_dark_skin_tone:': '👩🏾‍✈️',
  ':woman_pilot_tone4:': '👩🏾‍✈️',
  ':woman_pilot_dark_skin_tone:': '👩🏿‍✈️',
  ':woman_pilot_tone5:': '👩🏿‍✈️',
  ':woman_playing_handball_light_skin_tone:': '🤾🏻‍♀️',
  ':woman_playing_handball_tone1:': '🤾🏻‍♀️',
  ':woman_playing_handball_medium_light_skin_tone:': '🤾🏼‍♀️',
  ':woman_playing_handball_tone2:': '🤾🏼‍♀️',
  ':woman_playing_handball_medium_skin_tone:': '🤾🏽‍♀️',
  ':woman_playing_handball_tone3:': '🤾🏽‍♀️',
  ':woman_playing_handball_medium_dark_skin_tone:': '🤾🏾‍♀️',
  ':woman_playing_handball_tone4:': '🤾🏾‍♀️',
  ':woman_playing_handball_dark_skin_tone:': '🤾🏿‍♀️',
  ':woman_playing_handball_tone5:': '🤾🏿‍♀️',
  ':woman_playing_water_polo_light_skin_tone:': '🤽🏻‍♀️',
  ':woman_playing_water_polo_tone1:': '🤽🏻‍♀️',
  ':woman_playing_water_polo_medium_light_skin_tone:': '🤽🏼‍♀️',
  ':woman_playing_water_polo_tone2:': '🤽🏼‍♀️',
  ':woman_playing_water_polo_medium_skin_tone:': '🤽🏽‍♀️',
  ':woman_playing_water_polo_tone3:': '🤽🏽‍♀️',
  ':woman_playing_water_polo_medium_dark_skin_tone:': '🤽🏾‍♀️',
  ':woman_playing_water_polo_tone4:': '🤽🏾‍♀️',
  ':woman_playing_water_polo_dark_skin_tone:': '🤽🏿‍♀️',
  ':woman_playing_water_polo_tone5:': '🤽🏿‍♀️',
  ':woman_police_officer_light_skin_tone:': '👮🏻‍♀️',
  ':woman_police_officer_tone1:': '👮🏻‍♀️',
  ':woman_police_officer_medium_light_skin_tone:': '👮🏼‍♀️',
  ':woman_police_officer_tone2:': '👮🏼‍♀️',
  ':woman_police_officer_medium_skin_tone:': '👮🏽‍♀️',
  ':woman_police_officer_tone3:': '👮🏽‍♀️',
  ':woman_police_officer_medium_dark_skin_tone:': '👮🏾‍♀️',
  ':woman_police_officer_tone4:': '👮🏾‍♀️',
  ':woman_police_officer_dark_skin_tone:': '👮🏿‍♀️',
  ':woman_police_officer_tone5:': '👮🏿‍♀️',
  ':woman_pouting_light_skin_tone:': '🙎🏻‍♀️',
  ':woman_pouting_tone1:': '🙎🏻‍♀️',
  ':woman_pouting_medium_light_skin_tone:': '🙎🏼‍♀️',
  ':woman_pouting_tone2:': '🙎🏼‍♀️',
  ':woman_pouting_medium_skin_tone:': '🙎🏽‍♀️',
  ':woman_pouting_tone3:': '🙎🏽‍♀️',
  ':woman_pouting_medium_dark_skin_tone:': '🙎🏾‍♀️',
  ':woman_pouting_tone4:': '🙎🏾‍♀️',
  ':woman_pouting_dark_skin_tone:': '🙎🏿‍♀️',
  ':woman_pouting_tone5:': '🙎🏿‍♀️',
  ':woman_raising_hand_light_skin_tone:': '🙋🏻‍♀️',
  ':woman_raising_hand_tone1:': '🙋🏻‍♀️',
  ':woman_raising_hand_medium_light_skin_tone:': '🙋🏼‍♀️',
  ':woman_raising_hand_tone2:': '🙋🏼‍♀️',
  ':woman_raising_hand_medium_skin_tone:': '🙋🏽‍♀️',
  ':woman_raising_hand_tone3:': '🙋🏽‍♀️',
  ':woman_raising_hand_medium_dark_skin_tone:': '🙋🏾‍♀️',
  ':woman_raising_hand_tone4:': '🙋🏾‍♀️',
  ':woman_raising_hand_dark_skin_tone:': '🙋🏿‍♀️',
  ':woman_raising_hand_tone5:': '🙋🏿‍♀️',
  ':woman_rowing_boat_light_skin_tone:': '🚣🏻‍♀️',
  ':woman_rowing_boat_tone1:': '🚣🏻‍♀️',
  ':woman_rowing_boat_medium_light_skin_tone:': '🚣🏼‍♀️',
  ':woman_rowing_boat_tone2:': '🚣🏼‍♀️',
  ':woman_rowing_boat_medium_skin_tone:': '🚣🏽‍♀️',
  ':woman_rowing_boat_tone3:': '🚣🏽‍♀️',
  ':woman_rowing_boat_medium_dark_skin_tone:': '🚣🏾‍♀️',
  ':woman_rowing_boat_tone4:': '🚣🏾‍♀️',
  ':woman_rowing_boat_dark_skin_tone:': '🚣🏿‍♀️',
  ':woman_rowing_boat_tone5:': '🚣🏿‍♀️',
  ':woman_running_light_skin_tone:': '🏃🏻‍♀️',
  ':woman_running_tone1:': '🏃🏻‍♀️',
  ':woman_running_medium_light_skin_tone:': '🏃🏼‍♀️',
  ':woman_running_tone2:': '🏃🏼‍♀️',
  ':woman_running_medium_skin_tone:': '🏃🏽‍♀️',
  ':woman_running_tone3:': '🏃🏽‍♀️',
  ':woman_running_medium_dark_skin_tone:': '🏃🏾‍♀️',
  ':woman_running_tone4:': '🏃🏾‍♀️',
  ':woman_running_dark_skin_tone:': '🏃🏿‍♀️',
  ':woman_running_tone5:': '🏃🏿‍♀️',
  ':woman_shrugging_light_skin_tone:': '🤷🏻‍♀️',
  ':woman_shrugging_tone1:': '🤷🏻‍♀️',
  ':woman_shrugging_medium_light_skin_tone:': '🤷🏼‍♀️',
  ':woman_shrugging_tone2:': '🤷🏼‍♀️',
  ':woman_shrugging_medium_skin_tone:': '🤷🏽‍♀️',
  ':woman_shrugging_tone3:': '🤷🏽‍♀️',
  ':woman_shrugging_medium_dark_skin_tone:': '🤷🏾‍♀️',
  ':woman_shrugging_tone4:': '🤷🏾‍♀️',
  ':woman_shrugging_dark_skin_tone:': '🤷🏿‍♀️',
  ':woman_shrugging_tone5:': '🤷🏿‍♀️',
  ':woman_standing_light_skin_tone:': '🧍🏻‍♀️',
  ':woman_standing_tone1:': '🧍🏻‍♀️',
  ':woman_standing_medium_light_skin_tone:': '🧍🏼‍♀️',
  ':woman_standing_tone2:': '🧍🏼‍♀️',
  ':woman_standing_medium_skin_tone:': '🧍🏽‍♀️',
  ':woman_standing_tone3:': '🧍🏽‍♀️',
  ':woman_standing_medium_dark_skin_tone:': '🧍🏾‍♀️',
  ':woman_standing_tone4:': '🧍🏾‍♀️',
  ':woman_standing_dark_skin_tone:': '🧍🏿‍♀️',
  ':woman_standing_tone5:': '🧍🏿‍♀️',
  ':woman_superhero_light_skin_tone:': '🦸🏻‍♀️',
  ':woman_superhero_tone1:': '🦸🏻‍♀️',
  ':woman_superhero_medium_light_skin_tone:': '🦸🏼‍♀️',
  ':woman_superhero_tone2:': '🦸🏼‍♀️',
  ':woman_superhero_medium_skin_tone:': '🦸🏽‍♀️',
  ':woman_superhero_tone3:': '🦸🏽‍♀️',
  ':woman_superhero_medium_dark_skin_tone:': '🦸🏾‍♀️',
  ':woman_superhero_tone4:': '🦸🏾‍♀️',
  ':woman_superhero_dark_skin_tone:': '🦸🏿‍♀️',
  ':woman_superhero_tone5:': '🦸🏿‍♀️',
  ':woman_supervillain_light_skin_tone:': '🦹🏻‍♀️',
  ':woman_supervillain_tone1:': '🦹🏻‍♀️',
  ':woman_supervillain_medium_light_skin_tone:': '🦹🏼‍♀️',
  ':woman_supervillain_tone2:': '🦹🏼‍♀️',
  ':woman_supervillain_medium_skin_tone:': '🦹🏽‍♀️',
  ':woman_supervillain_tone3:': '🦹🏽‍♀️',
  ':woman_supervillain_medium_dark_skin_tone:': '🦹🏾‍♀️',
  ':woman_supervillain_tone4:': '🦹🏾‍♀️',
  ':woman_supervillain_dark_skin_tone:': '🦹🏿‍♀️',
  ':woman_supervillain_tone5:': '🦹🏿‍♀️',
  ':woman_surfing_light_skin_tone:': '🏄🏻‍♀️',
  ':woman_surfing_tone1:': '🏄🏻‍♀️',
  ':woman_surfing_medium_light_skin_tone:': '🏄🏼‍♀️',
  ':woman_surfing_tone2:': '🏄🏼‍♀️',
  ':woman_surfing_medium_skin_tone:': '🏄🏽‍♀️',
  ':woman_surfing_tone3:': '🏄🏽‍♀️',
  ':woman_surfing_medium_dark_skin_tone:': '🏄🏾‍♀️',
  ':woman_surfing_tone4:': '🏄🏾‍♀️',
  ':woman_surfing_dark_skin_tone:': '🏄🏿‍♀️',
  ':woman_surfing_tone5:': '🏄🏿‍♀️',
  ':woman_swimming_light_skin_tone:': '🏊🏻‍♀️',
  ':woman_swimming_tone1:': '🏊🏻‍♀️',
  ':woman_swimming_medium_light_skin_tone:': '🏊🏼‍♀️',
  ':woman_swimming_tone2:': '🏊🏼‍♀️',
  ':woman_swimming_medium_skin_tone:': '🏊🏽‍♀️',
  ':woman_swimming_tone3:': '🏊🏽‍♀️',
  ':woman_swimming_medium_dark_skin_tone:': '🏊🏾‍♀️',
  ':woman_swimming_tone4:': '🏊🏾‍♀️',
  ':woman_swimming_dark_skin_tone:': '🏊🏿‍♀️',
  ':woman_swimming_tone5:': '🏊🏿‍♀️',
  ':woman_tipping_hand_light_skin_tone:': '💁🏻‍♀️',
  ':woman_tipping_hand_tone1:': '💁🏻‍♀️',
  ':woman_tipping_hand_medium_light_skin_tone:': '💁🏼‍♀️',
  ':woman_tipping_hand_tone2:': '💁🏼‍♀️',
  ':woman_tipping_hand_medium_skin_tone:': '💁🏽‍♀️',
  ':woman_tipping_hand_tone3:': '💁🏽‍♀️',
  ':woman_tipping_hand_medium_dark_skin_tone:': '💁🏾‍♀️',
  ':woman_tipping_hand_tone4:': '💁🏾‍♀️',
  ':woman_tipping_hand_dark_skin_tone:': '💁🏿‍♀️',
  ':woman_tipping_hand_tone5:': '💁🏿‍♀️',
  ':woman_vampire_light_skin_tone:': '🧛🏻‍♀️',
  ':woman_vampire_tone1:': '🧛🏻‍♀️',
  ':woman_vampire_medium_light_skin_tone:': '🧛🏼‍♀️',
  ':woman_vampire_tone2:': '🧛🏼‍♀️',
  ':woman_vampire_medium_skin_tone:': '🧛🏽‍♀️',
  ':woman_vampire_tone3:': '🧛🏽‍♀️',
  ':woman_vampire_medium_dark_skin_tone:': '🧛🏾‍♀️',
  ':woman_vampire_tone4:': '🧛🏾‍♀️',
  ':woman_vampire_dark_skin_tone:': '🧛🏿‍♀️',
  ':woman_vampire_tone5:': '🧛🏿‍♀️',
  ':woman_walking_light_skin_tone:': '🚶🏻‍♀️',
  ':woman_walking_tone1:': '🚶🏻‍♀️',
  ':woman_walking_medium_light_skin_tone:': '🚶🏼‍♀️',
  ':woman_walking_tone2:': '🚶🏼‍♀️',
  ':woman_walking_medium_skin_tone:': '🚶🏽‍♀️',
  ':woman_walking_tone3:': '🚶🏽‍♀️',
  ':woman_walking_medium_dark_skin_tone:': '🚶🏾‍♀️',
  ':woman_walking_tone4:': '🚶🏾‍♀️',
  ':woman_walking_dark_skin_tone:': '🚶🏿‍♀️',
  ':woman_walking_tone5:': '🚶🏿‍♀️',
  ':woman_wearing_turban_light_skin_tone:': '👳🏻‍♀️',
  ':woman_wearing_turban_tone1:': '👳🏻‍♀️',
  ':woman_wearing_turban_medium_light_skin_tone:': '👳🏼‍♀️',
  ':woman_wearing_turban_tone2:': '👳🏼‍♀️',
  ':woman_wearing_turban_medium_skin_tone:': '👳🏽‍♀️',
  ':woman_wearing_turban_tone3:': '👳🏽‍♀️',
  ':woman_wearing_turban_medium_dark_skin_tone:': '👳🏾‍♀️',
  ':woman_wearing_turban_tone4:': '👳🏾‍♀️',
  ':woman_wearing_turban_dark_skin_tone:': '👳🏿‍♀️',
  ':woman_wearing_turban_tone5:': '👳🏿‍♀️',
  ':man_bouncing_ball_light_skin_tone:': '⛹️🏻‍♂️',
  ':man_bouncing_ball_tone1:': '⛹️🏻‍♂️',
  ':man_bouncing_ball_medium_light_skin_tone:': '⛹️🏼‍♂️',
  ':man_bouncing_ball_tone2:': '⛹️🏼‍♂️',
  ':man_bouncing_ball_medium_skin_tone:': '⛹️🏽‍♂️',
  ':man_bouncing_ball_tone3:': '⛹️🏽‍♂️',
  ':man_bouncing_ball_medium_dark_skin_tone:': '⛹️🏾‍♂️',
  ':man_bouncing_ball_tone4:': '⛹️🏾‍♂️',
  ':man_bouncing_ball_dark_skin_tone:': '⛹️🏿‍♂️',
  ':man_bouncing_ball_tone5:': '⛹️🏿‍♂️',
  ':woman_bouncing_ball_light_skin_tone:': '⛹️🏻‍♀️',
  ':woman_bouncing_ball_tone1:': '⛹️🏻‍♀️',
  ':woman_bouncing_ball_medium_light_skin_tone:': '⛹️🏼‍♀️',
  ':woman_bouncing_ball_tone2:': '⛹️🏼‍♀️',
  ':woman_bouncing_ball_medium_skin_tone:': '⛹️🏽‍♀️',
  ':woman_bouncing_ball_tone3:': '⛹️🏽‍♀️',
  ':woman_bouncing_ball_medium_dark_skin_tone:': '⛹️🏾‍♀️',
  ':woman_bouncing_ball_tone4:': '⛹️🏾‍♀️',
  ':woman_bouncing_ball_dark_skin_tone:': '⛹️🏿‍♀️',
  ':woman_bouncing_ball_tone5:': '⛹️🏿‍♀️',
  ':adult_light_skin_tone:': '🧑🏻',
  ':adult_tone1:': '🧑🏻',
  ':adult_medium_light_skin_tone:': '🧑🏼',
  ':adult_tone2:': '🧑🏼',
  ':adult_medium_skin_tone:': '🧑🏽',
  ':adult_tone3:': '🧑🏽',
  ':adult_medium_dark_skin_tone:': '🧑🏾',
  ':adult_tone4:': '🧑🏾',
  ':adult_dark_skin_tone:': '🧑🏿',
  ':adult_tone5:': '🧑🏿',
  ':angel_tone1:': '👼🏻',
  ':angel_tone2:': '👼🏼',
  ':angel_tone3:': '👼🏽',
  ':angel_tone4:': '👼🏾',
  ':angel_tone5:': '👼🏿',
  ':baby_tone1:': '👶🏻',
  ':baby_tone2:': '👶🏼',
  ':baby_tone3:': '👶🏽',
  ':baby_tone4:': '👶🏾',
  ':baby_tone5:': '👶🏿',
  ':bath_tone1:': '🛀🏻',
  ':bath_tone2:': '🛀🏼',
  ':bath_tone3:': '🛀🏽',
  ':bath_tone4:': '🛀🏾',
  ':bath_tone5:': '🛀🏿',
  ':bearded_person_light_skin_tone:': '🧔🏻',
  ':bearded_person_tone1:': '🧔🏻',
  ':bearded_person_medium_light_skin_tone:': '🧔🏼',
  ':bearded_person_tone2:': '🧔🏼',
  ':bearded_person_medium_skin_tone:': '🧔🏽',
  ':bearded_person_tone3:': '🧔🏽',
  ':bearded_person_medium_dark_skin_tone:': '🧔🏾',
  ':bearded_person_tone4:': '🧔🏾',
  ':bearded_person_dark_skin_tone:': '🧔🏿',
  ':bearded_person_tone5:': '🧔🏿',
  ':person_with_blond_hair_tone1:': '👱🏻',
  ':blond_haired_person_tone1:': '👱🏻',
  ':person_with_blond_hair_tone2:': '👱🏼',
  ':blond_haired_person_tone2:': '👱🏼',
  ':person_with_blond_hair_tone3:': '👱🏽',
  ':blond_haired_person_tone3:': '👱🏽',
  ':person_with_blond_hair_tone4:': '👱🏾',
  ':blond_haired_person_tone4:': '👱🏾',
  ':person_with_blond_hair_tone5:': '👱🏿',
  ':blond_haired_person_tone5:': '👱🏿',
  ':boy_tone1:': '👦🏻',
  ':boy_tone2:': '👦🏼',
  ':boy_tone3:': '👦🏽',
  ':boy_tone4:': '👦🏾',
  ':boy_tone5:': '👦🏿',
  ':breast_feeding_light_skin_tone:': '🤱🏻',
  ':breast_feeding_tone1:': '🤱🏻',
  ':breast_feeding_medium_light_skin_tone:': '🤱🏼',
  ':breast_feeding_tone2:': '🤱🏼',
  ':breast_feeding_medium_skin_tone:': '🤱🏽',
  ':breast_feeding_tone3:': '🤱🏽',
  ':breast_feeding_medium_dark_skin_tone:': '🤱🏾',
  ':breast_feeding_tone4:': '🤱🏾',
  ':breast_feeding_dark_skin_tone:': '🤱🏿',
  ':breast_feeding_tone5:': '🤱🏿',
  ':bride_with_veil_tone1:': '👰🏻',
  ':bride_with_veil_tone2:': '👰🏼',
  ':bride_with_veil_tone3:': '👰🏽',
  ':bride_with_veil_tone4:': '👰🏾',
  ':bride_with_veil_tone5:': '👰🏿',
  ':call_me_hand_tone1:': '🤙🏻',
  ':call_me_tone1:': '🤙🏻',
  ':call_me_hand_tone2:': '🤙🏼',
  ':call_me_tone2:': '🤙🏼',
  ':call_me_hand_tone3:': '🤙🏽',
  ':call_me_tone3:': '🤙🏽',
  ':call_me_hand_tone4:': '🤙🏾',
  ':call_me_tone4:': '🤙🏾',
  ':call_me_hand_tone5:': '🤙🏿',
  ':call_me_tone5:': '🤙🏿',
  ':child_light_skin_tone:': '🧒🏻',
  ':child_tone1:': '🧒🏻',
  ':child_medium_light_skin_tone:': '🧒🏼',
  ':child_tone2:': '🧒🏼',
  ':child_medium_skin_tone:': '🧒🏽',
  ':child_tone3:': '🧒🏽',
  ':child_medium_dark_skin_tone:': '🧒🏾',
  ':child_tone4:': '🧒🏾',
  ':child_dark_skin_tone:': '🧒🏿',
  ':child_tone5:': '🧒🏿',
  ':clap_tone1:': '👏🏻',
  ':clap_tone2:': '👏🏼',
  ':clap_tone3:': '👏🏽',
  ':clap_tone4:': '👏🏾',
  ':clap_tone5:': '👏🏿',
  ':construction_worker_tone1:': '👷🏻',
  ':construction_worker_tone2:': '👷🏼',
  ':construction_worker_tone3:': '👷🏽',
  ':construction_worker_tone4:': '👷🏾',
  ':construction_worker_tone5:': '👷🏿',
  ':dancer_tone1:': '💃🏻',
  ':dancer_tone2:': '💃🏼',
  ':dancer_tone3:': '💃🏽',
  ':dancer_tone4:': '💃🏾',
  ':dancer_tone5:': '💃🏿',
  ':deaf_person_light_skin_tone:': '🧏🏻',
  ':deaf_person_tone1:': '🧏🏻',
  ':deaf_person_medium_light_skin_tone:': '🧏🏼',
  ':deaf_person_tone2:': '🧏🏼',
  ':deaf_person_medium_skin_tone:': '🧏🏽',
  ':deaf_person_tone3:': '🧏🏽',
  ':deaf_person_medium_dark_skin_tone:': '🧏🏾',
  ':deaf_person_tone4:': '🧏🏾',
  ':deaf_person_dark_skin_tone:': '🧏🏿',
  ':deaf_person_tone5:': '🧏🏿',
  ':spy_tone1:': '🕵️🏻',
  ':sleuth_or_spy_tone1:': '🕵️🏻',
  ':detective_tone1:': '🕵️🏻',
  ':spy_tone2:': '🕵️🏼',
  ':sleuth_or_spy_tone2:': '🕵️🏼',
  ':detective_tone2:': '🕵️🏼',
  ':spy_tone3:': '🕵️🏽',
  ':sleuth_or_spy_tone3:': '🕵️🏽',
  ':detective_tone3:': '🕵️🏽',
  ':spy_tone4:': '🕵️🏾',
  ':sleuth_or_spy_tone4:': '🕵️🏾',
  ':detective_tone4:': '🕵️🏾',
  ':spy_tone5:': '🕵️🏿',
  ':sleuth_or_spy_tone5:': '🕵️🏿',
  ':detective_tone5:': '🕵️🏿',
  ':ear_tone1:': '👂🏻',
  ':ear_tone2:': '👂🏼',
  ':ear_tone3:': '👂🏽',
  ':ear_tone4:': '👂🏾',
  ':ear_tone5:': '👂🏿',
  ':ear_with_hearing_aid_light_skin_tone:': '🦻🏻',
  ':ear_with_hearing_aid_tone1:': '🦻🏻',
  ':ear_with_hearing_aid_medium_light_skin_tone:': '🦻🏼',
  ':ear_with_hearing_aid_tone2:': '🦻🏼',
  ':ear_with_hearing_aid_medium_skin_tone:': '🦻🏽',
  ':ear_with_hearing_aid_tone3:': '🦻🏽',
  ':ear_with_hearing_aid_medium_dark_skin_tone:': '🦻🏾',
  ':ear_with_hearing_aid_tone4:': '🦻🏾',
  ':ear_with_hearing_aid_dark_skin_tone:': '🦻🏿',
  ':ear_with_hearing_aid_tone5:': '🦻🏿',
  ':elf_light_skin_tone:': '🧝🏻',
  ':elf_tone1:': '🧝🏻',
  ':elf_medium_light_skin_tone:': '🧝🏼',
  ':elf_tone2:': '🧝🏼',
  ':elf_medium_skin_tone:': '🧝🏽',
  ':elf_tone3:': '🧝🏽',
  ':elf_medium_dark_skin_tone:': '🧝🏾',
  ':elf_tone4:': '🧝🏾',
  ':elf_dark_skin_tone:': '🧝🏿',
  ':elf_tone5:': '🧝🏿',
  ':eye_in_speech_bubble:': '👁️‍🗨️',
  ':fairy_light_skin_tone:': '🧚🏻',
  ':fairy_tone1:': '🧚🏻',
  ':fairy_medium_light_skin_tone:': '🧚🏼',
  ':fairy_tone2:': '🧚🏼',
  ':fairy_medium_skin_tone:': '🧚🏽',
  ':fairy_tone3:': '🧚🏽',
  ':fairy_medium_dark_skin_tone:': '🧚🏾',
  ':fairy_tone4:': '🧚🏾',
  ':fairy_dark_skin_tone:': '🧚🏿',
  ':fairy_tone5:': '🧚🏿',
  ':family_man_boy:': '👨‍👦',
  ':family_man_girl:': '👨‍👧',
  ':family_woman_boy:': '👩‍👦',
  ':family_woman_girl:': '👩‍👧',
  ':hand_with_index_and_middle_fingers_crossed_tone1:': '🤞🏻',
  ':fingers_crossed_tone1:': '🤞🏻',
  ':hand_with_index_and_middle_fingers_crossed_tone2:': '🤞🏼',
  ':fingers_crossed_tone2:': '🤞🏼',
  ':hand_with_index_and_middle_fingers_crossed_tone3:': '🤞🏽',
  ':fingers_crossed_tone3:': '🤞🏽',
  ':hand_with_index_and_middle_fingers_crossed_tone4:': '🤞🏾',
  ':fingers_crossed_tone4:': '🤞🏾',
  ':hand_with_index_and_middle_fingers_crossed_tone5:': '🤞🏿',
  ':fingers_crossed_tone5:': '🤞🏿',
  ':ac:': '🇦🇨',
  ':flag_ac:': '🇦🇨',
  ':ad:': '🇦🇩',
  ':flag_ad:': '🇦🇩',
  ':ae:': '🇦🇪',
  ':flag_ae:': '🇦🇪',
  ':af:': '🇦🇫',
  ':flag_af:': '🇦🇫',
  ':ag:': '🇦🇬',
  ':flag_ag:': '🇦🇬',
  ':ai:': '🇦🇮',
  ':flag_ai:': '🇦🇮',
  ':al:': '🇦🇱',
  ':flag_al:': '🇦🇱',
  ':am:': '🇦🇲',
  ':flag_am:': '🇦🇲',
  ':ao:': '🇦🇴',
  ':flag_ao:': '🇦🇴',
  ':aq:': '🇦🇶',
  ':flag_aq:': '🇦🇶',
  ':ar:': '🇦🇷',
  ':flag_ar:': '🇦🇷',
  ':as:': '🇦🇸',
  ':flag_as:': '🇦🇸',
  ':at:': '🇦🇹',
  ':flag_at:': '🇦🇹',
  ':au:': '🇦🇺',
  ':flag_au:': '🇦🇺',
  ':aw:': '🇦🇼',
  ':flag_aw:': '🇦🇼',
  ':ax:': '🇦🇽',
  ':flag_ax:': '🇦🇽',
  ':az:': '🇦🇿',
  ':flag_az:': '🇦🇿',
  ':ba:': '🇧🇦',
  ':flag_ba:': '🇧🇦',
  ':bb:': '🇧🇧',
  ':flag_bb:': '🇧🇧',
  ':bd:': '🇧🇩',
  ':flag_bd:': '🇧🇩',
  ':be:': '🇧🇪',
  ':flag_be:': '🇧🇪',
  ':bf:': '🇧🇫',
  ':flag_bf:': '🇧🇫',
  ':bg:': '🇧🇬',
  ':flag_bg:': '🇧🇬',
  ':bh:': '🇧🇭',
  ':flag_bh:': '🇧🇭',
  ':bi:': '🇧🇮',
  ':flag_bi:': '🇧🇮',
  ':bj:': '🇧🇯',
  ':flag_bj:': '🇧🇯',
  ':bm:': '🇧🇲',
  ':flag_bm:': '🇧🇲',
  ':bn:': '🇧🇳',
  ':flag_bn:': '🇧🇳',
  ':bo:': '🇧🇴',
  ':flag_bo:': '🇧🇴',
  ':br:': '🇧🇷',
  ':flag_br:': '🇧🇷',
  ':bs:': '🇧🇸',
  ':flag_bs:': '🇧🇸',
  ':bt:': '🇧🇹',
  ':flag_bt:': '🇧🇹',
  ':bv:': '🇧🇻',
  ':flag_bv:': '🇧🇻',
  ':bw:': '🇧🇼',
  ':flag_bw:': '🇧🇼',
  ':by:': '🇧🇾',
  ':flag_by:': '🇧🇾',
  ':bz:': '🇧🇿',
  ':flag_bz:': '🇧🇿',
  ':ca:': '🇨🇦',
  ':flag_ca:': '🇨🇦',
  ':cc:': '🇨🇨',
  ':flag_cc:': '🇨🇨',
  ':congo:': '🇨🇩',
  ':flag_cd:': '🇨🇩',
  ':cf:': '🇨🇫',
  ':flag_cf:': '🇨🇫',
  ':cg:': '🇨🇬',
  ':flag_cg:': '🇨🇬',
  ':ch:': '🇨🇭',
  ':flag_ch:': '🇨🇭',
  ':ci:': '🇨🇮',
  ':flag_ci:': '🇨🇮',
  ':ck:': '🇨🇰',
  ':flag_ck:': '🇨🇰',
  ':chile:': '🇨🇱',
  ':flag_cl:': '🇨🇱',
  ':cm:': '🇨🇲',
  ':flag_cm:': '🇨🇲',
  ':cn:': '🇨🇳',
  ':flag_cn:': '🇨🇳',
  ':co:': '🇨🇴',
  ':flag_co:': '🇨🇴',
  ':cp:': '🇨🇵',
  ':flag_cp:': '🇨🇵',
  ':cr:': '🇨🇷',
  ':flag_cr:': '🇨🇷',
  ':cu:': '🇨🇺',
  ':flag_cu:': '🇨🇺',
  ':cv:': '🇨🇻',
  ':flag_cv:': '🇨🇻',
  ':cw:': '🇨🇼',
  ':flag_cw:': '🇨🇼',
  ':cx:': '🇨🇽',
  ':flag_cx:': '🇨🇽',
  ':cy:': '🇨🇾',
  ':flag_cy:': '🇨🇾',
  ':cz:': '🇨🇿',
  ':flag_cz:': '🇨🇿',
  ':de:': '🇩🇪',
  ':flag_de:': '🇩🇪',
  ':dj:': '🇩🇯',
  ':flag_dj:': '🇩🇯',
  ':dk:': '🇩🇰',
  ':flag_dk:': '🇩🇰',
  ':dm:': '🇩🇲',
  ':flag_dm:': '🇩🇲',
  ':do:': '🇩🇴',
  ':flag_do:': '🇩🇴',
  ':dz:': '🇩🇿',
  ':flag_dz:': '🇩🇿',
  ':ec:': '🇪🇨',
  ':flag_ec:': '🇪🇨',
  ':ee:': '🇪🇪',
  ':flag_ee:': '🇪🇪',
  ':eg:': '🇪🇬',
  ':flag_eg:': '🇪🇬',
  ':er:': '🇪🇷',
  ':flag_er:': '🇪🇷',
  ':es:': '🇪🇸',
  ':flag_es:': '🇪🇸',
  ':et:': '🇪🇹',
  ':flag_et:': '🇪🇹',
  ':eu:': '🇪🇺',
  ':flag_eu:': '🇪🇺',
  ':fi:': '🇫🇮',
  ':flag_fi:': '🇫🇮',
  ':fj:': '🇫🇯',
  ':flag_fj:': '🇫🇯',
  ':fm:': '🇫🇲',
  ':flag_fm:': '🇫🇲',
  ':fo:': '🇫🇴',
  ':flag_fo:': '🇫🇴',
  ':fr:': '🇫🇷',
  ':flag_fr:': '🇫🇷',
  ':ga:': '🇬🇦',
  ':flag_ga:': '🇬🇦',
  ':gb:': '🇬🇧',
  ':flag_gb:': '🇬🇧',
  ':gd:': '🇬🇩',
  ':flag_gd:': '🇬🇩',
  ':ge:': '🇬🇪',
  ':flag_ge:': '🇬🇪',
  ':gg:': '🇬🇬',
  ':flag_gg:': '🇬🇬',
  ':gh:': '🇬🇭',
  ':flag_gh:': '🇬🇭',
  ':gi:': '🇬🇮',
  ':flag_gi:': '🇬🇮',
  ':gl:': '🇬🇱',
  ':flag_gl:': '🇬🇱',
  ':gm:': '🇬🇲',
  ':flag_gm:': '🇬🇲',
  ':gn:': '🇬🇳',
  ':flag_gn:': '🇬🇳',
  ':gq:': '🇬🇶',
  ':flag_gq:': '🇬🇶',
  ':gr:': '🇬🇷',
  ':flag_gr:': '🇬🇷',
  ':gt:': '🇬🇹',
  ':flag_gt:': '🇬🇹',
  ':gu:': '🇬🇺',
  ':flag_gu:': '🇬🇺',
  ':gw:': '🇬🇼',
  ':flag_gw:': '🇬🇼',
  ':gy:': '🇬🇾',
  ':flag_gy:': '🇬🇾',
  ':hk:': '🇭🇰',
  ':flag_hk:': '🇭🇰',
  ':hm:': '🇭🇲',
  ':flag_hm:': '🇭🇲',
  ':hn:': '🇭🇳',
  ':flag_hn:': '🇭🇳',
  ':hr:': '🇭🇷',
  ':flag_hr:': '🇭🇷',
  ':ht:': '🇭🇹',
  ':flag_ht:': '🇭🇹',
  ':hu:': '🇭🇺',
  ':flag_hu:': '🇭🇺',
  ':ic:': '🇮🇨',
  ':flag_ic:': '🇮🇨',
  ':indonesia:': '🇮🇩',
  ':flag_id:': '🇮🇩',
  ':ie:': '🇮🇪',
  ':flag_ie:': '🇮🇪',
  ':il:': '🇮🇱',
  ':flag_il:': '🇮🇱',
  ':im:': '🇮🇲',
  ':flag_im:': '🇮🇲',
  ':in:': '🇮🇳',
  ':flag_in:': '🇮🇳',
  ':io:': '🇮🇴',
  ':flag_io:': '🇮🇴',
  ':iq:': '🇮🇶',
  ':flag_iq:': '🇮🇶',
  ':ir:': '🇮🇷',
  ':flag_ir:': '🇮🇷',
  ':is:': '🇮🇸',
  ':flag_is:': '🇮🇸',
  ':it:': '🇮🇹',
  ':flag_it:': '🇮🇹',
  ':je:': '🇯🇪',
  ':flag_je:': '🇯🇪',
  ':jm:': '🇯🇲',
  ':flag_jm:': '🇯🇲',
  ':jo:': '🇯🇴',
  ':flag_jo:': '🇯🇴',
  ':jp:': '🇯🇵',
  ':flag_jp:': '🇯🇵',
  ':ke:': '🇰🇪',
  ':flag_ke:': '🇰🇪',
  ':kg:': '🇰🇬',
  ':flag_kg:': '🇰🇬',
  ':kh:': '🇰🇭',
  ':flag_kh:': '🇰🇭',
  ':ki:': '🇰🇮',
  ':flag_ki:': '🇰🇮',
  ':km:': '🇰🇲',
  ':flag_km:': '🇰🇲',
  ':kn:': '🇰🇳',
  ':flag_kn:': '🇰🇳',
  ':kp:': '🇰🇵',
  ':flag_kp:': '🇰🇵',
  ':kr:': '🇰🇷',
  ':flag_kr:': '🇰🇷',
  ':kw:': '🇰🇼',
  ':flag_kw:': '🇰🇼',
  ':ky:': '🇰🇾',
  ':flag_ky:': '🇰🇾',
  ':kz:': '🇰🇿',
  ':flag_kz:': '🇰🇿',
  ':la:': '🇱🇦',
  ':flag_la:': '🇱🇦',
  ':lb:': '🇱🇧',
  ':flag_lb:': '🇱🇧',
  ':lc:': '🇱🇨',
  ':flag_lc:': '🇱🇨',
  ':li:': '🇱🇮',
  ':flag_li:': '🇱🇮',
  ':lk:': '🇱🇰',
  ':flag_lk:': '🇱🇰',
  ':lr:': '🇱🇷',
  ':flag_lr:': '🇱🇷',
  ':ls:': '🇱🇸',
  ':flag_ls:': '🇱🇸',
  ':lt:': '🇱🇹',
  ':flag_lt:': '🇱🇹',
  ':lu:': '🇱🇺',
  ':flag_lu:': '🇱🇺',
  ':lv:': '🇱🇻',
  ':flag_lv:': '🇱🇻',
  ':ly:': '🇱🇾',
  ':flag_ly:': '🇱🇾',
  ':ma:': '🇲🇦',
  ':flag_ma:': '🇲🇦',
  ':mc:': '🇲🇨',
  ':flag_mc:': '🇲🇨',
  ':md:': '🇲🇩',
  ':flag_md:': '🇲🇩',
  ':me:': '🇲🇪',
  ':flag_me:': '🇲🇪',
  ':mg:': '🇲🇬',
  ':flag_mg:': '🇲🇬',
  ':mh:': '🇲🇭',
  ':flag_mh:': '🇲🇭',
  ':mk:': '🇲🇰',
  ':flag_mk:': '🇲🇰',
  ':ml:': '🇲🇱',
  ':flag_ml:': '🇲🇱',
  ':mm:': '🇲🇲',
  ':flag_mm:': '🇲🇲',
  ':mn:': '🇲🇳',
  ':flag_mn:': '🇲🇳',
  ':mo:': '🇲🇴',
  ':flag_mo:': '🇲🇴',
  ':mp:': '🇲🇵',
  ':flag_mp:': '🇲🇵',
  ':mr:': '🇲🇷',
  ':flag_mr:': '🇲🇷',
  ':ms:': '🇲🇸',
  ':flag_ms:': '🇲🇸',
  ':mt:': '🇲🇹',
  ':flag_mt:': '🇲🇹',
  ':mu:': '🇲🇺',
  ':flag_mu:': '🇲🇺',
  ':mv:': '🇲🇻',
  ':flag_mv:': '🇲🇻',
  ':mw:': '🇲🇼',
  ':flag_mw:': '🇲🇼',
  ':mx:': '🇲🇽',
  ':flag_mx:': '🇲🇽',
  ':my:': '🇲🇾',
  ':flag_my:': '🇲🇾',
  ':mz:': '🇲🇿',
  ':flag_mz:': '🇲🇿',
  ':na:': '🇳🇦',
  ':flag_na:': '🇳🇦',
  ':ne:': '🇳🇪',
  ':flag_ne:': '🇳🇪',
  ':nf:': '🇳🇫',
  ':flag_nf:': '🇳🇫',
  ':nigeria:': '🇳🇬',
  ':flag_ng:': '🇳🇬',
  ':ni:': '🇳🇮',
  ':flag_ni:': '🇳🇮',
  ':nl:': '🇳🇱',
  ':flag_nl:': '🇳🇱',
  ':no:': '🇳🇴',
  ':flag_no:': '🇳🇴',
  ':np:': '🇳🇵',
  ':flag_np:': '🇳🇵',
  ':nr:': '🇳🇷',
  ':flag_nr:': '🇳🇷',
  ':nu:': '🇳🇺',
  ':flag_nu:': '🇳🇺',
  ':nz:': '🇳🇿',
  ':flag_nz:': '🇳🇿',
  ':om:': '🇴🇲',
  ':flag_om:': '🇴🇲',
  ':pa:': '🇵🇦',
  ':flag_pa:': '🇵🇦',
  ':pe:': '🇵🇪',
  ':flag_pe:': '🇵🇪',
  ':pf:': '🇵🇫',
  ':flag_pf:': '🇵🇫',
  ':pg:': '🇵🇬',
  ':flag_pg:': '🇵🇬',
  ':ph:': '🇵🇭',
  ':flag_ph:': '🇵🇭',
  ':pk:': '🇵🇰',
  ':flag_pk:': '🇵🇰',
  ':pl:': '🇵🇱',
  ':flag_pl:': '🇵🇱',
  ':pn:': '🇵🇳',
  ':flag_pn:': '🇵🇳',
  ':pr:': '🇵🇷',
  ':flag_pr:': '🇵🇷',
  ':ps:': '🇵🇸',
  ':flag_ps:': '🇵🇸',
  ':pt:': '🇵🇹',
  ':flag_pt:': '🇵🇹',
  ':pw:': '🇵🇼',
  ':flag_pw:': '🇵🇼',
  ':py:': '🇵🇾',
  ':flag_py:': '🇵🇾',
  ':qa:': '🇶🇦',
  ':flag_qa:': '🇶🇦',
  ':ro:': '🇷🇴',
  ':flag_ro:': '🇷🇴',
  ':rs:': '🇷🇸',
  ':flag_rs:': '🇷🇸',
  ':ru:': '🇷🇺',
  ':flag_ru:': '🇷🇺',
  ':rw:': '🇷🇼',
  ':flag_rw:': '🇷🇼',
  ':saudiarabia:': '🇸🇦',
  ':saudi:': '🇸🇦',
  ':flag_sa:': '🇸🇦',
  ':sb:': '🇸🇧',
  ':flag_sb:': '🇸🇧',
  ':sc:': '🇸🇨',
  ':flag_sc:': '🇸🇨',
  ':sd:': '🇸🇩',
  ':flag_sd:': '🇸🇩',
  ':se:': '🇸🇪',
  ':flag_se:': '🇸🇪',
  ':sg:': '🇸🇬',
  ':flag_sg:': '🇸🇬',
  ':sh:': '🇸🇭',
  ':flag_sh:': '🇸🇭',
  ':si:': '🇸🇮',
  ':flag_si:': '🇸🇮',
  ':sj:': '🇸🇯',
  ':flag_sj:': '🇸🇯',
  ':sk:': '🇸🇰',
  ':flag_sk:': '🇸🇰',
  ':sl:': '🇸🇱',
  ':flag_sl:': '🇸🇱',
  ':sm:': '🇸🇲',
  ':flag_sm:': '🇸🇲',
  ':sn:': '🇸🇳',
  ':flag_sn:': '🇸🇳',
  ':so:': '🇸🇴',
  ':flag_so:': '🇸🇴',
  ':sr:': '🇸🇷',
  ':flag_sr:': '🇸🇷',
  ':ss:': '🇸🇸',
  ':flag_ss:': '🇸🇸',
  ':st:': '🇸🇹',
  ':flag_st:': '🇸🇹',
  ':sv:': '🇸🇻',
  ':flag_sv:': '🇸🇻',
  ':sx:': '🇸🇽',
  ':flag_sx:': '🇸🇽',
  ':sy:': '🇸🇾',
  ':flag_sy:': '🇸🇾',
  ':sz:': '🇸🇿',
  ':flag_sz:': '🇸🇿',
  ':ta:': '🇹🇦',
  ':flag_ta:': '🇹🇦',
  ':tc:': '🇹🇨',
  ':flag_tc:': '🇹🇨',
  ':td:': '🇹🇩',
  ':flag_td:': '🇹🇩',
  ':tg:': '🇹🇬',
  ':flag_tg:': '🇹🇬',
  ':th:': '🇹🇭',
  ':flag_th:': '🇹🇭',
  ':tj:': '🇹🇯',
  ':flag_tj:': '🇹🇯',
  ':tk:': '🇹🇰',
  ':flag_tk:': '🇹🇰',
  ':tl:': '🇹🇱',
  ':flag_tl:': '🇹🇱',
  ':turkmenistan:': '🇹🇲',
  ':flag_tm:': '🇹🇲',
  ':tn:': '🇹🇳',
  ':flag_tn:': '🇹🇳',
  ':to:': '🇹🇴',
  ':flag_to:': '🇹🇴',
  ':tr:': '🇹🇷',
  ':flag_tr:': '🇹🇷',
  ':tt:': '🇹🇹',
  ':flag_tt:': '🇹🇹',
  ':tuvalu:': '🇹🇻',
  ':flag_tv:': '🇹🇻',
  ':tw:': '🇹🇼',
  ':flag_tw:': '🇹🇼',
  ':tz:': '🇹🇿',
  ':flag_tz:': '🇹🇿',
  ':ua:': '🇺🇦',
  ':flag_ua:': '🇺🇦',
  ':ug:': '🇺🇬',
  ':flag_ug:': '🇺🇬',
  ':um:': '🇺🇲',
  ':flag_um:': '🇺🇲',
  ':us:': '🇺🇸',
  ':flag_us:': '🇺🇸',
  ':uy:': '🇺🇾',
  ':flag_uy:': '🇺🇾',
  ':uz:': '🇺🇿',
  ':flag_uz:': '🇺🇿',
  ':va:': '🇻🇦',
  ':flag_va:': '🇻🇦',
  ':vc:': '🇻🇨',
  ':flag_vc:': '🇻🇨',
  ':ve:': '🇻🇪',
  ':flag_ve:': '🇻🇪',
  ':vg:': '🇻🇬',
  ':flag_vg:': '🇻🇬',
  ':vi:': '🇻🇮',
  ':flag_vi:': '🇻🇮',
  ':vn:': '🇻🇳',
  ':flag_vn:': '🇻🇳',
  ':vu:': '🇻🇺',
  ':flag_vu:': '🇻🇺',
  ':ws:': '🇼🇸',
  ':flag_ws:': '🇼🇸',
  ':ye:': '🇾🇪',
  ':flag_ye:': '🇾🇪',
  ':za:': '🇿🇦',
  ':flag_za:': '🇿🇦',
  ':zm:': '🇿🇲',
  ':flag_zm:': '🇿🇲',
  ':zw:': '🇿🇼',
  ':flag_zw:': '🇿🇼',
  ':foot_light_skin_tone:': '🦶🏻',
  ':foot_tone1:': '🦶🏻',
  ':foot_medium_light_skin_tone:': '🦶🏼',
  ':foot_tone2:': '🦶🏼',
  ':foot_medium_skin_tone:': '🦶🏽',
  ':foot_tone3:': '🦶🏽',
  ':foot_medium_dark_skin_tone:': '🦶🏾',
  ':foot_tone4:': '🦶🏾',
  ':foot_dark_skin_tone:': '🦶🏿',
  ':foot_tone5:': '🦶🏿',
  ':girl_tone1:': '👧🏻',
  ':girl_tone2:': '👧🏼',
  ':girl_tone3:': '👧🏽',
  ':girl_tone4:': '👧🏾',
  ':girl_tone5:': '👧🏿',
  ':guardsman_tone1:': '💂🏻',
  ':guard_tone1:': '💂🏻',
  ':guardsman_tone2:': '💂🏼',
  ':guard_tone2:': '💂🏼',
  ':guardsman_tone3:': '💂🏽',
  ':guard_tone3:': '💂🏽',
  ':guardsman_tone4:': '💂🏾',
  ':guard_tone4:': '💂🏾',
  ':guardsman_tone5:': '💂🏿',
  ':guard_tone5:': '💂🏿',
  ':raised_hand_with_fingers_splayed_tone1:': '🖐️🏻',
  ':hand_splayed_tone1:': '🖐️🏻',
  ':raised_hand_with_fingers_splayed_tone2:': '🖐️🏼',
  ':hand_splayed_tone2:': '🖐️🏼',
  ':raised_hand_with_fingers_splayed_tone3:': '🖐️🏽',
  ':hand_splayed_tone3:': '🖐️🏽',
  ':raised_hand_with_fingers_splayed_tone4:': '🖐️🏾',
  ':hand_splayed_tone4:': '🖐️🏾',
  ':raised_hand_with_fingers_splayed_tone5:': '🖐️🏿',
  ':hand_splayed_tone5:': '🖐️🏿',
  ':horse_racing_tone1:': '🏇🏻',
  ':horse_racing_tone2:': '🏇🏼',
  ':horse_racing_tone3:': '🏇🏽',
  ':horse_racing_tone4:': '🏇🏾',
  ':horse_racing_tone5:': '🏇🏿',
  ':left_fist_tone1:': '🤛🏻',
  ':left_facing_fist_tone1:': '🤛🏻',
  ':left_fist_tone2:': '🤛🏼',
  ':left_facing_fist_tone2:': '🤛🏼',
  ':left_fist_tone3:': '🤛🏽',
  ':left_facing_fist_tone3:': '🤛🏽',
  ':left_fist_tone4:': '🤛🏾',
  ':left_facing_fist_tone4:': '🤛🏾',
  ':left_fist_tone5:': '🤛🏿',
  ':left_facing_fist_tone5:': '🤛🏿',
  ':leg_light_skin_tone:': '🦵🏻',
  ':leg_tone1:': '🦵🏻',
  ':leg_medium_light_skin_tone:': '🦵🏼',
  ':leg_tone2:': '🦵🏼',
  ':leg_medium_skin_tone:': '🦵🏽',
  ':leg_tone3:': '🦵🏽',
  ':leg_medium_dark_skin_tone:': '🦵🏾',
  ':leg_tone4:': '🦵🏾',
  ':leg_dark_skin_tone:': '🦵🏿',
  ':leg_tone5:': '🦵🏿',
  ':man_in_business_suit_levitating_tone1:': '🕴️🏻',
  ':man_in_business_suit_levitating_light_skin_tone:': '🕴️🏻',
  ':levitate_tone1:': '🕴️🏻',
  ':man_in_business_suit_levitating_tone2:': '🕴️🏼',
  ':man_in_business_suit_levitating_medium_light_skin_tone:': '🕴️🏼',
  ':levitate_tone2:': '🕴️🏼',
  ':man_in_business_suit_levitating_tone3:': '🕴️🏽',
  ':man_in_business_suit_levitating_medium_skin_tone:': '🕴️🏽',
  ':levitate_tone3:': '🕴️🏽',
  ':man_in_business_suit_levitating_tone4:': '🕴️🏾',
  ':man_in_business_suit_levitating_medium_dark_skin_tone:': '🕴️🏾',
  ':levitate_tone4:': '🕴️🏾',
  ':man_in_business_suit_levitating_tone5:': '🕴️🏿',
  ':man_in_business_suit_levitating_dark_skin_tone:': '🕴️🏿',
  ':levitate_tone5:': '🕴️🏿',
  ':love_you_gesture_light_skin_tone:': '🤟🏻',
  ':love_you_gesture_tone1:': '🤟🏻',
  ':love_you_gesture_medium_light_skin_tone:': '🤟🏼',
  ':love_you_gesture_tone2:': '🤟🏼',
  ':love_you_gesture_medium_skin_tone:': '🤟🏽',
  ':love_you_gesture_tone3:': '🤟🏽',
  ':love_you_gesture_medium_dark_skin_tone:': '🤟🏾',
  ':love_you_gesture_tone4:': '🤟🏾',
  ':love_you_gesture_dark_skin_tone:': '🤟🏿',
  ':love_you_gesture_tone5:': '🤟🏿',
  ':mage_light_skin_tone:': '🧙🏻',
  ':mage_tone1:': '🧙🏻',
  ':mage_medium_light_skin_tone:': '🧙🏼',
  ':mage_tone2:': '🧙🏼',
  ':mage_medium_skin_tone:': '🧙🏽',
  ':mage_tone3:': '🧙🏽',
  ':mage_medium_dark_skin_tone:': '🧙🏾',
  ':mage_tone4:': '🧙🏾',
  ':mage_dark_skin_tone:': '🧙🏿',
  ':mage_tone5:': '🧙🏿',
  ':man_artist:': '👨‍🎨',
  ':man_astronaut:': '👨‍🚀',
  ':man_bald:': '👨‍🦲',
  ':man_cook:': '👨‍🍳',
  ':man_curly_haired:': '👨‍🦱',
  ':male_dancer_tone1:': '🕺🏻',
  ':man_dancing_tone1:': '🕺🏻',
  ':male_dancer_tone2:': '🕺🏼',
  ':man_dancing_tone2:': '🕺🏼',
  ':male_dancer_tone3:': '🕺🏽',
  ':man_dancing_tone3:': '🕺🏽',
  ':male_dancer_tone4:': '🕺🏾',
  ':man_dancing_tone4:': '🕺🏾',
  ':male_dancer_tone5:': '🕺🏿',
  ':man_dancing_tone5:': '🕺🏿',
  ':man_factory_worker:': '👨‍🏭',
  ':man_farmer:': '👨‍🌾',
  ':man_firefighter:': '👨‍🚒',
  ':man_in_manual_wheelchair:': '👨‍🦽',
  ':man_in_motorized_wheelchair:': '👨‍🦼',
  ':tuxedo_tone1:': '🤵🏻',
  ':man_in_tuxedo_tone1:': '🤵🏻',
  ':tuxedo_tone2:': '🤵🏼',
  ':man_in_tuxedo_tone2:': '🤵🏼',
  ':tuxedo_tone3:': '🤵🏽',
  ':man_in_tuxedo_tone3:': '🤵🏽',
  ':tuxedo_tone4:': '🤵🏾',
  ':man_in_tuxedo_tone4:': '🤵🏾',
  ':tuxedo_tone5:': '🤵🏿',
  ':man_in_tuxedo_tone5:': '🤵🏿',
  ':man_mechanic:': '👨‍🔧',
  ':man_office_worker:': '👨‍💼',
  ':man_red_haired:': '👨‍🦰',
  ':man_scientist:': '👨‍🔬',
  ':man_singer:': '👨‍🎤',
  ':man_student:': '👨‍🎓',
  ':man_teacher:': '👨‍🏫',
  ':man_technologist:': '👨‍💻',
  ':man_tone1:': '👨🏻',
  ':man_tone2:': '👨🏼',
  ':man_tone3:': '👨🏽',
  ':man_tone4:': '👨🏾',
  ':man_tone5:': '👨🏿',
  ':man_white_haired:': '👨‍🦳',
  ':man_with_gua_pi_mao_tone1:': '👲🏻',
  ':man_with_chinese_cap_tone1:': '👲🏻',
  ':man_with_gua_pi_mao_tone2:': '👲🏼',
  ':man_with_chinese_cap_tone2:': '👲🏼',
  ':man_with_gua_pi_mao_tone3:': '👲🏽',
  ':man_with_chinese_cap_tone3:': '👲🏽',
  ':man_with_gua_pi_mao_tone4:': '👲🏾',
  ':man_with_chinese_cap_tone4:': '👲🏾',
  ':man_with_gua_pi_mao_tone5:': '👲🏿',
  ':man_with_chinese_cap_tone5:': '👲🏿',
  ':man_with_probing_cane:': '👨‍🦯',
  ':men_holding_hands_light_skin_tone:': '👬🏻',
  ':men_holding_hands_tone1:': '👬🏻',
  ':men_holding_hands_medium_light_skin_tone:': '👬🏼',
  ':men_holding_hands_tone2:': '👬🏼',
  ':men_holding_hands_medium_skin_tone:': '👬🏽',
  ':men_holding_hands_tone3:': '👬🏽',
  ':men_holding_hands_medium_dark_skin_tone:': '👬🏾',
  ':men_holding_hands_tone4:': '👬🏾',
  ':men_holding_hands_dark_skin_tone:': '👬🏿',
  ':men_holding_hands_tone5:': '👬🏿',
  ':merperson_light_skin_tone:': '🧜🏻',
  ':merperson_tone1:': '🧜🏻',
  ':merperson_medium_light_skin_tone:': '🧜🏼',
  ':merperson_tone2:': '🧜🏼',
  ':merperson_medium_skin_tone:': '🧜🏽',
  ':merperson_tone3:': '🧜🏽',
  ':merperson_medium_dark_skin_tone:': '🧜🏾',
  ':merperson_tone4:': '🧜🏾',
  ':merperson_dark_skin_tone:': '🧜🏿',
  ':merperson_tone5:': '🧜🏿',
  ':sign_of_the_horns_tone1:': '🤘🏻',
  ':metal_tone1:': '🤘🏻',
  ':sign_of_the_horns_tone2:': '🤘🏼',
  ':metal_tone2:': '🤘🏼',
  ':sign_of_the_horns_tone3:': '🤘🏽',
  ':metal_tone3:': '🤘🏽',
  ':sign_of_the_horns_tone4:': '🤘🏾',
  ':metal_tone4:': '🤘🏾',
  ':sign_of_the_horns_tone5:': '🤘🏿',
  ':metal_tone5:': '🤘🏿',
  ':reversed_hand_with_middle_finger_extended_tone1:': '🖕🏻',
  ':middle_finger_tone1:': '🖕🏻',
  ':reversed_hand_with_middle_finger_extended_tone2:': '🖕🏼',
  ':middle_finger_tone2:': '🖕🏼',
  ':reversed_hand_with_middle_finger_extended_tone3:': '🖕🏽',
  ':middle_finger_tone3:': '🖕🏽',
  ':reversed_hand_with_middle_finger_extended_tone4:': '🖕🏾',
  ':middle_finger_tone4:': '🖕🏾',
  ':reversed_hand_with_middle_finger_extended_tone5:': '🖕🏿',
  ':middle_finger_tone5:': '🖕🏿',
  ':mother_christmas_tone1:': '🤶🏻',
  ':mrs_claus_tone1:': '🤶🏻',
  ':mother_christmas_tone2:': '🤶🏼',
  ':mrs_claus_tone2:': '🤶🏼',
  ':mother_christmas_tone3:': '🤶🏽',
  ':mrs_claus_tone3:': '🤶🏽',
  ':mother_christmas_tone4:': '🤶🏾',
  ':mrs_claus_tone4:': '🤶🏾',
  ':mother_christmas_tone5:': '🤶🏿',
  ':mrs_claus_tone5:': '🤶🏿',
  ':muscle_tone1:': '💪🏻',
  ':muscle_tone2:': '💪🏼',
  ':muscle_tone3:': '💪🏽',
  ':muscle_tone4:': '💪🏾',
  ':muscle_tone5:': '💪🏿',
  ':nail_care_tone1:': '💅🏻',
  ':nail_care_tone2:': '💅🏼',
  ':nail_care_tone3:': '💅🏽',
  ':nail_care_tone4:': '💅🏾',
  ':nail_care_tone5:': '💅🏿',
  ':nose_tone1:': '👃🏻',
  ':nose_tone2:': '👃🏼',
  ':nose_tone3:': '👃🏽',
  ':nose_tone4:': '👃🏾',
  ':nose_tone5:': '👃🏿',
  ':ok_hand_tone1:': '👌🏻',
  ':ok_hand_tone2:': '👌🏼',
  ':ok_hand_tone3:': '👌🏽',
  ':ok_hand_tone4:': '👌🏾',
  ':ok_hand_tone5:': '👌🏿',
  ':older_adult_light_skin_tone:': '🧓🏻',
  ':older_adult_tone1:': '🧓🏻',
  ':older_adult_medium_light_skin_tone:': '🧓🏼',
  ':older_adult_tone2:': '🧓🏼',
  ':older_adult_medium_skin_tone:': '🧓🏽',
  ':older_adult_tone3:': '🧓🏽',
  ':older_adult_medium_dark_skin_tone:': '🧓🏾',
  ':older_adult_tone4:': '🧓🏾',
  ':older_adult_dark_skin_tone:': '🧓🏿',
  ':older_adult_tone5:': '🧓🏿',
  ':older_man_tone1:': '👴🏻',
  ':older_man_tone2:': '👴🏼',
  ':older_man_tone3:': '👴🏽',
  ':older_man_tone4:': '👴🏾',
  ':older_man_tone5:': '👴🏿',
  ':grandma_tone1:': '👵🏻',
  ':older_woman_tone1:': '👵🏻',
  ':grandma_tone2:': '👵🏼',
  ':older_woman_tone2:': '👵🏼',
  ':grandma_tone3:': '👵🏽',
  ':older_woman_tone3:': '👵🏽',
  ':grandma_tone4:': '👵🏾',
  ':older_woman_tone4:': '👵🏾',
  ':grandma_tone5:': '👵🏿',
  ':older_woman_tone5:': '👵🏿',
  ':open_hands_tone1:': '👐🏻',
  ':open_hands_tone2:': '👐🏼',
  ':open_hands_tone3:': '👐🏽',
  ':open_hands_tone4:': '👐🏾',
  ':open_hands_tone5:': '👐🏿',
  ':palms_up_together_light_skin_tone:': '🤲🏻',
  ':palms_up_together_tone1:': '🤲🏻',
  ':palms_up_together_medium_light_skin_tone:': '🤲🏼',
  ':palms_up_together_tone2:': '🤲🏼',
  ':palms_up_together_medium_skin_tone:': '🤲🏽',
  ':palms_up_together_tone3:': '🤲🏽',
  ':palms_up_together_medium_dark_skin_tone:': '🤲🏾',
  ':palms_up_together_tone4:': '🤲🏾',
  ':palms_up_together_dark_skin_tone:': '🤲🏿',
  ':palms_up_together_tone5:': '🤲🏿',
  ':bicyclist_tone1:': '🚴🏻',
  ':person_biking_tone1:': '🚴🏻',
  ':bicyclist_tone2:': '🚴🏼',
  ':person_biking_tone2:': '🚴🏼',
  ':bicyclist_tone3:': '🚴🏽',
  ':person_biking_tone3:': '🚴🏽',
  ':bicyclist_tone4:': '🚴🏾',
  ':person_biking_tone4:': '🚴🏾',
  ':bicyclist_tone5:': '🚴🏿',
  ':person_biking_tone5:': '🚴🏿',
  ':bow_tone1:': '🙇🏻',
  ':person_bowing_tone1:': '🙇🏻',
  ':bow_tone2:': '🙇🏼',
  ':person_bowing_tone2:': '🙇🏼',
  ':bow_tone3:': '🙇🏽',
  ':person_bowing_tone3:': '🙇🏽',
  ':bow_tone4:': '🙇🏾',
  ':person_bowing_tone4:': '🙇🏾',
  ':bow_tone5:': '🙇🏿',
  ':person_bowing_tone5:': '🙇🏿',
  ':person_climbing_light_skin_tone:': '🧗🏻',
  ':person_climbing_tone1:': '🧗🏻',
  ':person_climbing_medium_light_skin_tone:': '🧗🏼',
  ':person_climbing_tone2:': '🧗🏼',
  ':person_climbing_medium_skin_tone:': '🧗🏽',
  ':person_climbing_tone3:': '🧗🏽',
  ':person_climbing_medium_dark_skin_tone:': '🧗🏾',
  ':person_climbing_tone4:': '🧗🏾',
  ':person_climbing_dark_skin_tone:': '🧗🏿',
  ':person_climbing_tone5:': '🧗🏿',
  ':cartwheel_tone1:': '🤸🏻',
  ':person_doing_cartwheel_tone1:': '🤸🏻',
  ':cartwheel_tone2:': '🤸🏼',
  ':person_doing_cartwheel_tone2:': '🤸🏼',
  ':cartwheel_tone3:': '🤸🏽',
  ':person_doing_cartwheel_tone3:': '🤸🏽',
  ':cartwheel_tone4:': '🤸🏾',
  ':person_doing_cartwheel_tone4:': '🤸🏾',
  ':cartwheel_tone5:': '🤸🏿',
  ':person_doing_cartwheel_tone5:': '🤸🏿',
  ':face_palm_tone1:': '🤦🏻',
  ':facepalm_tone1:': '🤦🏻',
  ':person_facepalming_tone1:': '🤦🏻',
  ':face_palm_tone2:': '🤦🏼',
  ':facepalm_tone2:': '🤦🏼',
  ':person_facepalming_tone2:': '🤦🏼',
  ':face_palm_tone3:': '🤦🏽',
  ':facepalm_tone3:': '🤦🏽',
  ':person_facepalming_tone3:': '🤦🏽',
  ':face_palm_tone4:': '🤦🏾',
  ':facepalm_tone4:': '🤦🏾',
  ':person_facepalming_tone4:': '🤦🏾',
  ':face_palm_tone5:': '🤦🏿',
  ':facepalm_tone5:': '🤦🏿',
  ':person_facepalming_tone5:': '🤦🏿',
  ':person_frowning_tone1:': '🙍🏻',
  ':person_frowning_tone2:': '🙍🏼',
  ':person_frowning_tone3:': '🙍🏽',
  ':person_frowning_tone4:': '🙍🏾',
  ':person_frowning_tone5:': '🙍🏿',
  ':no_good_tone1:': '🙅🏻',
  ':person_gesturing_no_tone1:': '🙅🏻',
  ':no_good_tone2:': '🙅🏼',
  ':person_gesturing_no_tone2:': '🙅🏼',
  ':no_good_tone3:': '🙅🏽',
  ':person_gesturing_no_tone3:': '🙅🏽',
  ':no_good_tone4:': '🙅🏾',
  ':person_gesturing_no_tone4:': '🙅🏾',
  ':no_good_tone5:': '🙅🏿',
  ':person_gesturing_no_tone5:': '🙅🏿',
  ':ok_woman_tone1:': '🙆🏻',
  ':person_gesturing_ok_tone1:': '🙆🏻',
  ':ok_woman_tone2:': '🙆🏼',
  ':person_gesturing_ok_tone2:': '🙆🏼',
  ':ok_woman_tone3:': '🙆🏽',
  ':person_gesturing_ok_tone3:': '🙆🏽',
  ':ok_woman_tone4:': '🙆🏾',
  ':person_gesturing_ok_tone4:': '🙆🏾',
  ':ok_woman_tone5:': '🙆🏿',
  ':person_gesturing_ok_tone5:': '🙆🏿',
  ':haircut_tone1:': '💇🏻',
  ':person_getting_haircut_tone1:': '💇🏻',
  ':haircut_tone2:': '💇🏼',
  ':person_getting_haircut_tone2:': '💇🏼',
  ':haircut_tone3:': '💇🏽',
  ':person_getting_haircut_tone3:': '💇🏽',
  ':haircut_tone4:': '💇🏾',
  ':person_getting_haircut_tone4:': '💇🏾',
  ':haircut_tone5:': '💇🏿',
  ':person_getting_haircut_tone5:': '💇🏿',
  ':massage_tone1:': '💆🏻',
  ':person_getting_massage_tone1:': '💆🏻',
  ':massage_tone2:': '💆🏼',
  ':person_getting_massage_tone2:': '💆🏼',
  ':massage_tone3:': '💆🏽',
  ':person_getting_massage_tone3:': '💆🏽',
  ':massage_tone4:': '💆🏾',
  ':person_getting_massage_tone4:': '💆🏾',
  ':massage_tone5:': '💆🏿',
  ':person_getting_massage_tone5:': '💆🏿',
  ':person_golfing_light_skin_tone:': '🏌️🏻',
  ':person_golfing_tone1:': '🏌️🏻',
  ':person_golfing_medium_light_skin_tone:': '🏌️🏼',
  ':person_golfing_tone2:': '🏌️🏼',
  ':person_golfing_medium_skin_tone:': '🏌️🏽',
  ':person_golfing_tone3:': '🏌️🏽',
  ':person_golfing_medium_dark_skin_tone:': '🏌️🏾',
  ':person_golfing_tone4:': '🏌️🏾',
  ':person_golfing_dark_skin_tone:': '🏌️🏿',
  ':person_golfing_tone5:': '🏌️🏿',
  ':person_in_bed_light_skin_tone:': '🛌🏻',
  ':person_in_bed_tone1:': '🛌🏻',
  ':person_in_bed_medium_light_skin_tone:': '🛌🏼',
  ':person_in_bed_tone2:': '🛌🏼',
  ':person_in_bed_medium_skin_tone:': '🛌🏽',
  ':person_in_bed_tone3:': '🛌🏽',
  ':person_in_bed_medium_dark_skin_tone:': '🛌🏾',
  ':person_in_bed_tone4:': '🛌🏾',
  ':person_in_bed_dark_skin_tone:': '🛌🏿',
  ':person_in_bed_tone5:': '🛌🏿',
  ':person_in_lotus_position_light_skin_tone:': '🧘🏻',
  ':person_in_lotus_position_tone1:': '🧘🏻',
  ':person_in_lotus_position_medium_light_skin_tone:': '🧘🏼',
  ':person_in_lotus_position_tone2:': '🧘🏼',
  ':person_in_lotus_position_medium_skin_tone:': '🧘🏽',
  ':person_in_lotus_position_tone3:': '🧘🏽',
  ':person_in_lotus_position_medium_dark_skin_tone:': '🧘🏾',
  ':person_in_lotus_position_tone4:': '🧘🏾',
  ':person_in_lotus_position_dark_skin_tone:': '🧘🏿',
  ':person_in_lotus_position_tone5:': '🧘🏿',
  ':person_in_steamy_room_light_skin_tone:': '🧖🏻',
  ':person_in_steamy_room_tone1:': '🧖🏻',
  ':person_in_steamy_room_medium_light_skin_tone:': '🧖🏼',
  ':person_in_steamy_room_tone2:': '🧖🏼',
  ':person_in_steamy_room_medium_skin_tone:': '🧖🏽',
  ':person_in_steamy_room_tone3:': '🧖🏽',
  ':person_in_steamy_room_medium_dark_skin_tone:': '🧖🏾',
  ':person_in_steamy_room_tone4:': '🧖🏾',
  ':person_in_steamy_room_dark_skin_tone:': '🧖🏿',
  ':person_in_steamy_room_tone5:': '🧖🏿',
  ':juggling_tone1:': '🤹🏻',
  ':juggler_tone1:': '🤹🏻',
  ':person_juggling_tone1:': '🤹🏻',
  ':juggling_tone2:': '🤹🏼',
  ':juggler_tone2:': '🤹🏼',
  ':person_juggling_tone2:': '🤹🏼',
  ':juggling_tone3:': '🤹🏽',
  ':juggler_tone3:': '🤹🏽',
  ':person_juggling_tone3:': '🤹🏽',
  ':juggling_tone4:': '🤹🏾',
  ':juggler_tone4:': '🤹🏾',
  ':person_juggling_tone4:': '🤹🏾',
  ':juggling_tone5:': '🤹🏿',
  ':juggler_tone5:': '🤹🏿',
  ':person_juggling_tone5:': '🤹🏿',
  ':person_kneeling_light_skin_tone:': '🧎🏻',
  ':person_kneeling_tone1:': '🧎🏻',
  ':person_kneeling_medium_light_skin_tone:': '🧎🏼',
  ':person_kneeling_tone2:': '🧎🏼',
  ':person_kneeling_medium_skin_tone:': '🧎🏽',
  ':person_kneeling_tone3:': '🧎🏽',
  ':person_kneeling_medium_dark_skin_tone:': '🧎🏾',
  ':person_kneeling_tone4:': '🧎🏾',
  ':person_kneeling_dark_skin_tone:': '🧎🏿',
  ':person_kneeling_tone5:': '🧎🏿',
  ':lifter_tone1:': '🏋️🏻',
  ':weight_lifter_tone1:': '🏋️🏻',
  ':person_lifting_weights_tone1:': '🏋️🏻',
  ':lifter_tone2:': '🏋️🏼',
  ':weight_lifter_tone2:': '🏋️🏼',
  ':person_lifting_weights_tone2:': '🏋️🏼',
  ':lifter_tone3:': '🏋️🏽',
  ':weight_lifter_tone3:': '🏋️🏽',
  ':person_lifting_weights_tone3:': '🏋️🏽',
  ':lifter_tone4:': '🏋️🏾',
  ':weight_lifter_tone4:': '🏋️🏾',
  ':person_lifting_weights_tone4:': '🏋️🏾',
  ':lifter_tone5:': '🏋️🏿',
  ':weight_lifter_tone5:': '🏋️🏿',
  ':person_lifting_weights_tone5:': '🏋️🏿',
  ':mountain_bicyclist_tone1:': '🚵🏻',
  ':person_mountain_biking_tone1:': '🚵🏻',
  ':mountain_bicyclist_tone2:': '🚵🏼',
  ':person_mountain_biking_tone2:': '🚵🏼',
  ':mountain_bicyclist_tone3:': '🚵🏽',
  ':person_mountain_biking_tone3:': '🚵🏽',
  ':mountain_bicyclist_tone4:': '🚵🏾',
  ':person_mountain_biking_tone4:': '🚵🏾',
  ':mountain_bicyclist_tone5:': '🚵🏿',
  ':person_mountain_biking_tone5:': '🚵🏿',
  ':handball_tone1:': '🤾🏻',
  ':person_playing_handball_tone1:': '🤾🏻',
  ':handball_tone2:': '🤾🏼',
  ':person_playing_handball_tone2:': '🤾🏼',
  ':handball_tone3:': '🤾🏽',
  ':person_playing_handball_tone3:': '🤾🏽',
  ':handball_tone4:': '🤾🏾',
  ':person_playing_handball_tone4:': '🤾🏾',
  ':handball_tone5:': '🤾🏿',
  ':person_playing_handball_tone5:': '🤾🏿',
  ':water_polo_tone1:': '🤽🏻',
  ':person_playing_water_polo_tone1:': '🤽🏻',
  ':water_polo_tone2:': '🤽🏼',
  ':person_playing_water_polo_tone2:': '🤽🏼',
  ':water_polo_tone3:': '🤽🏽',
  ':person_playing_water_polo_tone3:': '🤽🏽',
  ':water_polo_tone4:': '🤽🏾',
  ':person_playing_water_polo_tone4:': '🤽🏾',
  ':water_polo_tone5:': '🤽🏿',
  ':person_playing_water_polo_tone5:': '🤽🏿',
  ':person_with_pouting_face_tone1:': '🙎🏻',
  ':person_pouting_tone1:': '🙎🏻',
  ':person_with_pouting_face_tone2:': '🙎🏼',
  ':person_pouting_tone2:': '🙎🏼',
  ':person_with_pouting_face_tone3:': '🙎🏽',
  ':person_pouting_tone3:': '🙎🏽',
  ':person_with_pouting_face_tone4:': '🙎🏾',
  ':person_pouting_tone4:': '🙎🏾',
  ':person_with_pouting_face_tone5:': '🙎🏿',
  ':person_pouting_tone5:': '🙎🏿',
  ':raising_hand_tone1:': '🙋🏻',
  ':person_raising_hand_tone1:': '🙋🏻',
  ':raising_hand_tone2:': '🙋🏼',
  ':person_raising_hand_tone2:': '🙋🏼',
  ':raising_hand_tone3:': '🙋🏽',
  ':person_raising_hand_tone3:': '🙋🏽',
  ':raising_hand_tone4:': '🙋🏾',
  ':person_raising_hand_tone4:': '🙋🏾',
  ':raising_hand_tone5:': '🙋🏿',
  ':person_raising_hand_tone5:': '🙋🏿',
  ':rowboat_tone1:': '🚣🏻',
  ':person_rowing_boat_tone1:': '🚣🏻',
  ':rowboat_tone2:': '🚣🏼',
  ':person_rowing_boat_tone2:': '🚣🏼',
  ':rowboat_tone3:': '🚣🏽',
  ':person_rowing_boat_tone3:': '🚣🏽',
  ':rowboat_tone4:': '🚣🏾',
  ':person_rowing_boat_tone4:': '🚣🏾',
  ':rowboat_tone5:': '🚣🏿',
  ':person_rowing_boat_tone5:': '🚣🏿',
  ':runner_tone1:': '🏃🏻',
  ':person_running_tone1:': '🏃🏻',
  ':runner_tone2:': '🏃🏼',
  ':person_running_tone2:': '🏃🏼',
  ':runner_tone3:': '🏃🏽',
  ':person_running_tone3:': '🏃🏽',
  ':runner_tone4:': '🏃🏾',
  ':person_running_tone4:': '🏃🏾',
  ':runner_tone5:': '🏃🏿',
  ':person_running_tone5:': '🏃🏿',
  ':shrug_tone1:': '🤷🏻',
  ':person_shrugging_tone1:': '🤷🏻',
  ':shrug_tone2:': '🤷🏼',
  ':person_shrugging_tone2:': '🤷🏼',
  ':shrug_tone3:': '🤷🏽',
  ':person_shrugging_tone3:': '🤷🏽',
  ':shrug_tone4:': '🤷🏾',
  ':person_shrugging_tone4:': '🤷🏾',
  ':shrug_tone5:': '🤷🏿',
  ':person_shrugging_tone5:': '🤷🏿',
  ':person_standing_light_skin_tone:': '🧍🏻',
  ':person_standing_tone1:': '🧍🏻',
  ':person_standing_medium_light_skin_tone:': '🧍🏼',
  ':person_standing_tone2:': '🧍🏼',
  ':person_standing_medium_skin_tone:': '🧍🏽',
  ':person_standing_tone3:': '🧍🏽',
  ':person_standing_medium_dark_skin_tone:': '🧍🏾',
  ':person_standing_tone4:': '🧍🏾',
  ':person_standing_dark_skin_tone:': '🧍🏿',
  ':person_standing_tone5:': '🧍🏿',
  ':surfer_tone1:': '🏄🏻',
  ':person_surfing_tone1:': '🏄🏻',
  ':surfer_tone2:': '🏄🏼',
  ':person_surfing_tone2:': '🏄🏼',
  ':surfer_tone3:': '🏄🏽',
  ':person_surfing_tone3:': '🏄🏽',
  ':surfer_tone4:': '🏄🏾',
  ':person_surfing_tone4:': '🏄🏾',
  ':surfer_tone5:': '🏄🏿',
  ':person_surfing_tone5:': '🏄🏿',
  ':swimmer_tone1:': '🏊🏻',
  ':person_swimming_tone1:': '🏊🏻',
  ':swimmer_tone2:': '🏊🏼',
  ':person_swimming_tone2:': '🏊🏼',
  ':swimmer_tone3:': '🏊🏽',
  ':person_swimming_tone3:': '🏊🏽',
  ':swimmer_tone4:': '🏊🏾',
  ':person_swimming_tone4:': '🏊🏾',
  ':swimmer_tone5:': '🏊🏿',
  ':person_swimming_tone5:': '🏊🏿',
  ':information_desk_person_tone1:': '💁🏻',
  ':person_tipping_hand_tone1:': '💁🏻',
  ':information_desk_person_tone2:': '💁🏼',
  ':person_tipping_hand_tone2:': '💁🏼',
  ':information_desk_person_tone3:': '💁🏽',
  ':person_tipping_hand_tone3:': '💁🏽',
  ':information_desk_person_tone4:': '💁🏾',
  ':person_tipping_hand_tone4:': '💁🏾',
  ':information_desk_person_tone5:': '💁🏿',
  ':person_tipping_hand_tone5:': '💁🏿',
  ':walking_tone1:': '🚶🏻',
  ':person_walking_tone1:': '🚶🏻',
  ':walking_tone2:': '🚶🏼',
  ':person_walking_tone2:': '🚶🏼',
  ':walking_tone3:': '🚶🏽',
  ':person_walking_tone3:': '🚶🏽',
  ':walking_tone4:': '🚶🏾',
  ':person_walking_tone4:': '🚶🏾',
  ':walking_tone5:': '🚶🏿',
  ':person_walking_tone5:': '🚶🏿',
  ':man_with_turban_tone1:': '👳🏻',
  ':person_wearing_turban_tone1:': '👳🏻',
  ':man_with_turban_tone2:': '👳🏼',
  ':person_wearing_turban_tone2:': '👳🏼',
  ':man_with_turban_tone3:': '👳🏽',
  ':person_wearing_turban_tone3:': '👳🏽',
  ':man_with_turban_tone4:': '👳🏾',
  ':person_wearing_turban_tone4:': '👳🏾',
  ':man_with_turban_tone5:': '👳🏿',
  ':person_wearing_turban_tone5:': '👳🏿',
  ':pinching_hand_light_skin_tone:': '🤏🏻',
  ':pinching_hand_tone1:': '🤏🏻',
  ':pinching_hand_medium_light_skin_tone:': '🤏🏼',
  ':pinching_hand_tone2:': '🤏🏼',
  ':pinching_hand_medium_skin_tone:': '🤏🏽',
  ':pinching_hand_tone3:': '🤏🏽',
  ':pinching_hand_medium_dark_skin_tone:': '🤏🏾',
  ':pinching_hand_tone4:': '🤏🏾',
  ':pinching_hand_dark_skin_tone:': '🤏🏿',
  ':pinching_hand_tone5:': '🤏🏿',
  ':point_down_tone1:': '👇🏻',
  ':point_down_tone2:': '👇🏼',
  ':point_down_tone3:': '👇🏽',
  ':point_down_tone4:': '👇🏾',
  ':point_down_tone5:': '👇🏿',
  ':point_left_tone1:': '👈🏻',
  ':point_left_tone2:': '👈🏼',
  ':point_left_tone3:': '👈🏽',
  ':point_left_tone4:': '👈🏾',
  ':point_left_tone5:': '👈🏿',
  ':point_right_tone1:': '👉🏻',
  ':point_right_tone2:': '👉🏼',
  ':point_right_tone3:': '👉🏽',
  ':point_right_tone4:': '👉🏾',
  ':point_right_tone5:': '👉🏿',
  ':point_up_2_tone1:': '👆🏻',
  ':point_up_2_tone2:': '👆🏼',
  ':point_up_2_tone3:': '👆🏽',
  ':point_up_2_tone4:': '👆🏾',
  ':point_up_2_tone5:': '👆🏿',
  ':cop_tone1:': '👮🏻',
  ':police_officer_tone1:': '👮🏻',
  ':cop_tone2:': '👮🏼',
  ':police_officer_tone2:': '👮🏼',
  ':cop_tone3:': '👮🏽',
  ':police_officer_tone3:': '👮🏽',
  ':cop_tone4:': '👮🏾',
  ':police_officer_tone4:': '👮🏾',
  ':cop_tone5:': '👮🏿',
  ':police_officer_tone5:': '👮🏿',
  ':pray_tone1:': '🙏🏻',
  ':pray_tone2:': '🙏🏼',
  ':pray_tone3:': '🙏🏽',
  ':pray_tone4:': '🙏🏾',
  ':pray_tone5:': '🙏🏿',
  ':expecting_woman_tone1:': '🤰🏻',
  ':pregnant_woman_tone1:': '🤰🏻',
  ':expecting_woman_tone2:': '🤰🏼',
  ':pregnant_woman_tone2:': '🤰🏼',
  ':expecting_woman_tone3:': '🤰🏽',
  ':pregnant_woman_tone3:': '🤰🏽',
  ':expecting_woman_tone4:': '🤰🏾',
  ':pregnant_woman_tone4:': '🤰🏾',
  ':expecting_woman_tone5:': '🤰🏿',
  ':pregnant_woman_tone5:': '🤰🏿',
  ':prince_tone1:': '🤴🏻',
  ':prince_tone2:': '🤴🏼',
  ':prince_tone3:': '🤴🏽',
  ':prince_tone4:': '🤴🏾',
  ':prince_tone5:': '🤴🏿',
  ':princess_tone1:': '👸🏻',
  ':princess_tone2:': '👸🏼',
  ':princess_tone3:': '👸🏽',
  ':princess_tone4:': '👸🏾',
  ':princess_tone5:': '👸🏿',
  ':punch_tone1:': '👊🏻',
  ':punch_tone2:': '👊🏼',
  ':punch_tone3:': '👊🏽',
  ':punch_tone4:': '👊🏾',
  ':punch_tone5:': '👊🏿',
  ':gay_pride_flag:': '🏳️‍🌈',
  ':rainbow_flag:': '🏳️‍🌈',
  ':back_of_hand_tone1:': '🤚🏻',
  ':raised_back_of_hand_tone1:': '🤚🏻',
  ':back_of_hand_tone2:': '🤚🏼',
  ':raised_back_of_hand_tone2:': '🤚🏼',
  ':back_of_hand_tone3:': '🤚🏽',
  ':raised_back_of_hand_tone3:': '🤚🏽',
  ':back_of_hand_tone4:': '🤚🏾',
  ':raised_back_of_hand_tone4:': '🤚🏾',
  ':back_of_hand_tone5:': '🤚🏿',
  ':raised_back_of_hand_tone5:': '🤚🏿',
  ':raised_hands_tone1:': '🙌🏻',
  ':raised_hands_tone2:': '🙌🏼',
  ':raised_hands_tone3:': '🙌🏽',
  ':raised_hands_tone4:': '🙌🏾',
  ':raised_hands_tone5:': '🙌🏿',
  ':right_fist_tone1:': '🤜🏻',
  ':right_facing_fist_tone1:': '🤜🏻',
  ':right_fist_tone2:': '🤜🏼',
  ':right_facing_fist_tone2:': '🤜🏼',
  ':right_fist_tone3:': '🤜🏽',
  ':right_facing_fist_tone3:': '🤜🏽',
  ':right_fist_tone4:': '🤜🏾',
  ':right_facing_fist_tone4:': '🤜🏾',
  ':right_fist_tone5:': '🤜🏿',
  ':right_facing_fist_tone5:': '🤜🏿',
  ':santa_tone1:': '🎅🏻',
  ':santa_tone2:': '🎅🏼',
  ':santa_tone3:': '🎅🏽',
  ':santa_tone4:': '🎅🏾',
  ':santa_tone5:': '🎅🏿',
  ':selfie_tone1:': '🤳🏻',
  ':selfie_tone2:': '🤳🏼',
  ':selfie_tone3:': '🤳🏽',
  ':selfie_tone4:': '🤳🏾',
  ':selfie_tone5:': '🤳🏿',
  ':service_dog:': '🐕‍🦺',
  ':snowboarder_light_skin_tone:': '🏂🏻',
  ':snowboarder_tone1:': '🏂🏻',
  ':snowboarder_medium_light_skin_tone:': '🏂🏼',
  ':snowboarder_tone2:': '🏂🏼',
  ':snowboarder_medium_skin_tone:': '🏂🏽',
  ':snowboarder_tone3:': '🏂🏽',
  ':snowboarder_medium_dark_skin_tone:': '🏂🏾',
  ':snowboarder_tone4:': '🏂🏾',
  ':snowboarder_dark_skin_tone:': '🏂🏿',
  ':snowboarder_tone5:': '🏂🏿',
  ':superhero_light_skin_tone:': '🦸🏻',
  ':superhero_tone1:': '🦸🏻',
  ':superhero_medium_light_skin_tone:': '🦸🏼',
  ':superhero_tone2:': '🦸🏼',
  ':superhero_medium_skin_tone:': '🦸🏽',
  ':superhero_tone3:': '🦸🏽',
  ':superhero_medium_dark_skin_tone:': '🦸🏾',
  ':superhero_tone4:': '🦸🏾',
  ':superhero_dark_skin_tone:': '🦸🏿',
  ':superhero_tone5:': '🦸🏿',
  ':supervillain_light_skin_tone:': '🦹🏻',
  ':supervillain_tone1:': '🦹🏻',
  ':supervillain_medium_light_skin_tone:': '🦹🏼',
  ':supervillain_tone2:': '🦹🏼',
  ':supervillain_medium_skin_tone:': '🦹🏽',
  ':supervillain_tone3:': '🦹🏽',
  ':supervillain_medium_dark_skin_tone:': '🦹🏾',
  ':supervillain_tone4:': '🦹🏾',
  ':supervillain_dark_skin_tone:': '🦹🏿',
  ':supervillain_tone5:': '🦹🏿',
  ':-1_tone1:': '👎🏻',
  ':thumbdown_tone1:': '👎🏻',
  ':thumbsdown_tone1:': '👎🏻',
  ':-1_tone2:': '👎🏼',
  ':thumbdown_tone2:': '👎🏼',
  ':thumbsdown_tone2:': '👎🏼',
  ':-1_tone3:': '👎🏽',
  ':thumbdown_tone3:': '👎🏽',
  ':thumbsdown_tone3:': '👎🏽',
  ':-1_tone4:': '👎🏾',
  ':thumbdown_tone4:': '👎🏾',
  ':thumbsdown_tone4:': '👎🏾',
  ':-1_tone5:': '👎🏿',
  ':thumbdown_tone5:': '👎🏿',
  ':thumbsdown_tone5:': '👎🏿',
  ':+1_tone1:': '👍🏻',
  ':thumbup_tone1:': '👍🏻',
  ':thumbsup_tone1:': '👍🏻',
  ':+1_tone2:': '👍🏼',
  ':thumbup_tone2:': '👍🏼',
  ':thumbsup_tone2:': '👍🏼',
  ':+1_tone3:': '👍🏽',
  ':thumbup_tone3:': '👍🏽',
  ':thumbsup_tone3:': '👍🏽',
  ':+1_tone4:': '👍🏾',
  ':thumbup_tone4:': '👍🏾',
  ':thumbsup_tone4:': '👍🏾',
  ':+1_tone5:': '👍🏿',
  ':thumbup_tone5:': '👍🏿',
  ':thumbsup_tone5:': '👍🏿',
  ':united_nations:': '🇺🇳',
  ':vampire_light_skin_tone:': '🧛🏻',
  ':vampire_tone1:': '🧛🏻',
  ':vampire_medium_light_skin_tone:': '🧛🏼',
  ':vampire_tone2:': '🧛🏼',
  ':vampire_medium_skin_tone:': '🧛🏽',
  ':vampire_tone3:': '🧛🏽',
  ':vampire_medium_dark_skin_tone:': '🧛🏾',
  ':vampire_tone4:': '🧛🏾',
  ':vampire_dark_skin_tone:': '🧛🏿',
  ':vampire_tone5:': '🧛🏿',
  ':raised_hand_with_part_between_middle_and_ring_fingers_tone1:': '🖖🏻',
  ':vulcan_tone1:': '🖖🏻',
  ':raised_hand_with_part_between_middle_and_ring_fingers_tone2:': '🖖🏼',
  ':vulcan_tone2:': '🖖🏼',
  ':raised_hand_with_part_between_middle_and_ring_fingers_tone3:': '🖖🏽',
  ':vulcan_tone3:': '🖖🏽',
  ':raised_hand_with_part_between_middle_and_ring_fingers_tone4:': '🖖🏾',
  ':vulcan_tone4:': '🖖🏾',
  ':raised_hand_with_part_between_middle_and_ring_fingers_tone5:': '🖖🏿',
  ':vulcan_tone5:': '🖖🏿',
  ':wave_tone1:': '👋🏻',
  ':wave_tone2:': '👋🏼',
  ':wave_tone3:': '👋🏽',
  ':wave_tone4:': '👋🏾',
  ':wave_tone5:': '👋🏿',
  ':woman_and_man_holding_hands_light_skin_tone:': '👫🏻',
  ':woman_and_man_holding_hands_tone1:': '👫🏻',
  ':woman_and_man_holding_hands_medium_light_skin_tone:': '👫🏼',
  ':woman_and_man_holding_hands_tone2:': '👫🏼',
  ':woman_and_man_holding_hands_medium_skin_tone:': '👫🏽',
  ':woman_and_man_holding_hands_tone3:': '👫🏽',
  ':woman_and_man_holding_hands_medium_dark_skin_tone:': '👫🏾',
  ':woman_and_man_holding_hands_tone4:': '👫🏾',
  ':woman_and_man_holding_hands_dark_skin_tone:': '👫🏿',
  ':woman_and_man_holding_hands_tone5:': '👫🏿',
  ':woman_artist:': '👩‍🎨',
  ':woman_astronaut:': '👩‍🚀',
  ':woman_bald:': '👩‍🦲',
  ':woman_cook:': '👩‍🍳',
  ':woman_curly_haired:': '👩‍🦱',
  ':woman_factory_worker:': '👩‍🏭',
  ':woman_farmer:': '👩‍🌾',
  ':woman_firefighter:': '👩‍🚒',
  ':woman_in_manual_wheelchair:': '👩‍🦽',
  ':woman_in_motorized_wheelchair:': '👩‍🦼',
  ':woman_mechanic:': '👩‍🔧',
  ':woman_office_worker:': '👩‍💼',
  ':woman_red_haired:': '👩‍🦰',
  ':woman_scientist:': '👩‍🔬',
  ':woman_singer:': '👩‍🎤',
  ':woman_student:': '👩‍🎓',
  ':woman_teacher:': '👩‍🏫',
  ':woman_technologist:': '👩‍💻',
  ':woman_tone1:': '👩🏻',
  ':woman_tone2:': '👩🏼',
  ':woman_tone3:': '👩🏽',
  ':woman_tone4:': '👩🏾',
  ':woman_tone5:': '👩🏿',
  ':woman_white_haired:': '👩‍🦳',
  ':woman_with_headscarf_light_skin_tone:': '🧕🏻',
  ':woman_with_headscarf_tone1:': '🧕🏻',
  ':woman_with_headscarf_medium_light_skin_tone:': '🧕🏼',
  ':woman_with_headscarf_tone2:': '🧕🏼',
  ':woman_with_headscarf_medium_skin_tone:': '🧕🏽',
  ':woman_with_headscarf_tone3:': '🧕🏽',
  ':woman_with_headscarf_medium_dark_skin_tone:': '🧕🏾',
  ':woman_with_headscarf_tone4:': '🧕🏾',
  ':woman_with_headscarf_dark_skin_tone:': '🧕🏿',
  ':woman_with_headscarf_tone5:': '🧕🏿',
  ':woman_with_probing_cane:': '👩‍🦯',
  ':women_holding_hands_light_skin_tone:': '👭🏻',
  ':women_holding_hands_tone1:': '👭🏻',
  ':women_holding_hands_medium_light_skin_tone:': '👭🏼',
  ':women_holding_hands_tone2:': '👭🏼',
  ':women_holding_hands_medium_skin_tone:': '👭🏽',
  ':women_holding_hands_tone3:': '👭🏽',
  ':women_holding_hands_medium_dark_skin_tone:': '👭🏾',
  ':women_holding_hands_tone4:': '👭🏾',
  ':women_holding_hands_dark_skin_tone:': '👭🏿',
  ':women_holding_hands_tone5:': '👭🏿',
  ':blond-haired_man:': '👱‍♂️',
  ':blond-haired_woman:': '👱‍♀️',
  ':deaf_man:': '🧏‍♂️',
  ':deaf_woman:': '🧏‍♀️',
  ':fist_tone1:': '✊🏻',
  ':fist_tone2:': '✊🏼',
  ':fist_tone3:': '✊🏽',
  ':fist_tone4:': '✊🏾',
  ':fist_tone5:': '✊🏿',
  ':man_biking:': '🚴‍♂️',
  ':man_bowing:': '🙇‍♂️',
  ':man_cartwheeling:': '🤸‍♂️',
  ':man_climbing:': '🧗‍♂️',
  ':man_construction_worker:': '👷‍♂️',
  ':man_detective:': '🕵️‍♂️',
  ':man_elf:': '🧝‍♂️',
  ':man_facepalming:': '🤦‍♂️',
  ':man_fairy:': '🧚‍♂️',
  ':man_frowning:': '🙍‍♂️',
  ':man_genie:': '🧞‍♂️',
  ':man_gesturing_no:': '🙅‍♂️',
  ':man_gesturing_ok:': '🙆‍♂️',
  ':man_getting_face_massage:': '💆‍♂️',
  ':man_getting_haircut:': '💇‍♂️',
  ':man_golfing:': '🏌️‍♂️',
  ':man_guard:': '💂‍♂️',
  ':man_health_worker:': '👨‍⚕️',
  ':man_in_lotus_position:': '🧘‍♂️',
  ':man_in_steamy_room:': '🧖‍♂️',
  ':man_judge:': '👨‍⚖️',
  ':man_juggling:': '🤹‍♂️',
  ':man_kneeling:': '🧎‍♂️',
  ':man_lifting_weights:': '🏋️‍♂️',
  ':man_mage:': '🧙‍♂️',
  ':man_mountain_biking:': '🚵‍♂️',
  ':man_pilot:': '👨‍✈️',
  ':man_playing_handball:': '🤾‍♂️',
  ':man_playing_water_polo:': '🤽‍♂️',
  ':man_police_officer:': '👮‍♂️',
  ':man_pouting:': '🙎‍♂️',
  ':man_raising_hand:': '🙋‍♂️',
  ':man_rowing_boat:': '🚣‍♂️',
  ':man_running:': '🏃‍♂️',
  ':man_shrugging:': '🤷‍♂️',
  ':man_standing:': '🧍‍♂️',
  ':man_superhero:': '🦸‍♂️',
  ':man_supervillain:': '🦹‍♂️',
  ':man_surfing:': '🏄‍♂️',
  ':man_swimming:': '🏊‍♂️',
  ':man_tipping_hand:': '💁‍♂️',
  ':man_vampire:': '🧛‍♂️',
  ':man_walking:': '🚶‍♂️',
  ':man_wearing_turban:': '👳‍♂️',
  ':man_zombie:': '🧟‍♂️',
  ':men_with_bunny_ears_partying:': '👯‍♂️',
  ':men_wrestling:': '🤼♂️',
  ':mermaid:': '🧜‍♀️',
  ':merman:': '🧜‍♂️',
  ':basketball_player_tone1:': '⛹️🏻',
  ':person_with_ball_tone1:': '⛹️🏻',
  ':person_bouncing_ball_tone1:': '⛹️🏻',
  ':basketball_player_tone2:': '⛹️🏼',
  ':person_with_ball_tone2:': '⛹️🏼',
  ':person_bouncing_ball_tone2:': '⛹️🏼',
  ':basketball_player_tone3:': '⛹️🏽',
  ':person_with_ball_tone3:': '⛹️🏽',
  ':person_bouncing_ball_tone3:': '⛹️🏽',
  ':basketball_player_tone4:': '⛹️🏾',
  ':person_with_ball_tone4:': '⛹️🏾',
  ':person_bouncing_ball_tone4:': '⛹️🏾',
  ':basketball_player_tone5:': '⛹️🏿',
  ':person_with_ball_tone5:': '⛹️🏿',
  ':person_bouncing_ball_tone5:': '⛹️🏿',
  ':pirate_flag:': '🏴‍☠️',
  ':point_up_tone1:': '☝️🏻',
  ':point_up_tone2:': '☝️🏼',
  ':point_up_tone3:': '☝️🏽',
  ':point_up_tone4:': '☝️🏾',
  ':point_up_tone5:': '☝️🏿',
  ':raised_hand_tone1:': '✋🏻',
  ':raised_hand_tone2:': '✋🏼',
  ':raised_hand_tone3:': '✋🏽',
  ':raised_hand_tone4:': '✋🏾',
  ':raised_hand_tone5:': '✋🏿',
  ':v_tone1:': '✌️🏻',
  ':v_tone2:': '✌️🏼',
  ':v_tone3:': '✌️🏽',
  ':v_tone4:': '✌️🏾',
  ':v_tone5:': '✌️🏿',
  ':woman_biking:': '🚴‍♀️',
  ':woman_bowing:': '🙇‍♀️',
  ':woman_cartwheeling:': '🤸‍♀️',
  ':woman_climbing:': '🧗‍♀️',
  ':woman_construction_worker:': '👷‍♀️',
  ':woman_detective:': '🕵️‍♀️',
  ':woman_elf:': '🧝‍♀️',
  ':woman_facepalming:': '🤦‍♀️',
  ':woman_fairy:': '🧚‍♀️',
  ':woman_frowning:': '🙍‍♀️',
  ':woman_genie:': '🧞‍♀️',
  ':woman_gesturing_no:': '🙅‍♀️',
  ':woman_gesturing_ok:': '🙆‍♀️',
  ':woman_getting_face_massage:': '💆‍♀️',
  ':woman_getting_haircut:': '💇‍♀️',
  ':woman_golfing:': '🏌️‍♀️',
  ':woman_guard:': '💂‍♀️',
  ':woman_health_worker:': '👩‍⚕️',
  ':woman_in_lotus_position:': '🧘‍♀️',
  ':woman_in_steamy_room:': '🧖‍♀️',
  ':woman_judge:': '👩‍⚖️',
  ':woman_juggling:': '🤹‍♀️',
  ':woman_kneeling:': '🧎‍♀️',
  ':woman_lifting_weights:': '🏋️‍♀️',
  ':woman_mage:': '🧙‍♀️',
  ':woman_mountain_biking:': '🚵‍♀️',
  ':woman_pilot:': '👩‍✈️',
  ':woman_playing_handball:': '🤾‍♀️',
  ':woman_playing_water_polo:': '🤽‍♀️',
  ':woman_police_officer:': '👮‍♀️',
  ':woman_pouting:': '🙎‍♀️',
  ':woman_raising_hand:': '🙋‍♀️',
  ':woman_rowing_boat:': '🚣‍♀️',
  ':woman_running:': '🏃‍♀️',
  ':woman_shrugging:': '🤷‍♀️',
  ':woman_standing:': '🧍‍♀️',
  ':woman_superhero:': '🦸‍♀️',
  ':woman_supervillain:': '🦹‍♀️',
  ':woman_surfing:': '🏄‍♀️',
  ':woman_swimming:': '🏊‍♀️',
  ':woman_tipping_hand:': '💁‍♀️',
  ':woman_vampire:': '🧛‍♀️',
  ':woman_walking:': '🚶‍♀️',
  ':woman_wearing_turban:': '👳‍♀️',
  ':woman_zombie:': '🧟‍♀️',
  ':women_with_bunny_ears_partying:': '👯‍♀️',
  ':women_wrestling:': '🤼♀️',
  ':writing_hand_tone1:': '✍️🏻',
  ':writing_hand_tone2:': '✍️🏼',
  ':writing_hand_tone3:': '✍️🏽',
  ':writing_hand_tone4:': '✍️🏾',
  ':writing_hand_tone5:': '✍️🏿',
  ':keycap_asterisk:': '*️⃣',
  ':asterisk:': '*️⃣',
  ':eight:': '8️⃣',
  ':five:': '5️⃣',
  ':four:': '4️⃣',
  ':hash:': '#️⃣',
  ':man_bouncing_ball:': '⛹️‍♂️',
  ':nine:': '9️⃣',
  ':one:': '1️⃣',
  ':seven:': '7️⃣',
  ':six:': '6️⃣',
  ':three:': '3️⃣',
  ':two:': '2️⃣',
  ':woman_bouncing_ball:': '⛹️‍♀️',
  ':zero:': '0️⃣',
  ':100:': '💯',
  ':1234:': '🔢',
  ':8ball:': '🎱',
  ':a:': '🅰️',
  ':ab:': '🆎',
  ':abacus:': '🧮',
  ':abc:': '🔤',
  ':abcd:': '🔡',
  ':accept:': '🉑',
  ':adhesive_bandage:': '🩹',
  ':adult:': '🧑',
  ':aerial_tramway:': '🚡',
  ':airplane_arriving:': '🛬',
  ':airplane_departure:': '🛫',
  ':small_airplane:': '🛩️',
  ':airplane_small:': '🛩️',
  ':alien:': '👽',
  ':ambulance:': '🚑',
  ':amphora:': '🏺',
  ':angel:': '👼',
  ':anger:': '💢',
  ':right_anger_bubble:': '🗯️',
  ':anger_right:': '🗯️',
  ':angry:': '😠',
  ':anguished:': '😧',
  ':ant:': '🐜',
  ':apple:': '🍎',
  ':arrow_down_small:': '🔽',
  ':arrow_up_small:': '🔼',
  ':arrows_clockwise:': '🔃',
  ':arrows_counterclockwise:': '🔄',
  ':art:': '🎨',
  ':articulated_lorry:': '🚛',
  ':astonished:': '😲',
  ':athletic_shoe:': '👟',
  ':atm:': '🏧',
  ':auto_rickshaw:': '🛺',
  ':avocado:': '🥑',
  ':axe:': '🪓',
  ':b:': '🅱️',
  ':baby:': '👶',
  ':baby_bottle:': '🍼',
  ':baby_chick:': '🐤',
  ':baby_symbol:': '🚼',
  ':back:': '🔙',
  ':bacon:': '🥓',
  ':badger:': '🦡',
  ':badminton:': '🏸',
  ':bagel:': '🥯',
  ':baggage_claim:': '🛄',
  ':bald:': '🦲',
  ':ballet_shoes:': '🩰',
  ':balloon:': '🎈',
  ':ballot_box_with_ballot:': '🗳️',
  ':ballot_box:': '🗳️',
  ':bamboo:': '🎍',
  ':banana:': '🍌',
  ':banjo:': '🪕',
  ':bank:': '🏦',
  ':bar_chart:': '📊',
  ':barber:': '💈',
  ':basket:': '🧺',
  ':basketball:': '🏀',
  ':bat:': '🦇',
  ':bath:': '🛀',
  ':bathtub:': '🛁',
  ':battery:': '🔋',
  ':beach_with_umbrella:': '🏖️',
  ':beach:': '🏖️',
  ':bear:': '🐻',
  ':bearded_person:': '🧔',
  ':bed:': '🛏️',
  ':bee:': '🐝',
  ':beer:': '🍺',
  ':beers:': '🍻',
  ':beetle:': '🐞',
  ':beginner:': '🔰',
  ':bell:': '🔔',
  ':bellhop_bell:': '🛎️',
  ':bellhop:': '🛎️',
  ':bento:': '🍱',
  ':beverage_box:': '🧃',
  ':bike:': '🚲',
  ':bikini:': '👙',
  ':billed_cap:': '🧢',
  ':bird:': '🐦',
  ':birthday:': '🎂',
  ':black_heart:': '🖤',
  ':black_joker:': '🃏',
  ':black_square_button:': '🔲',
  ':person_with_blond_hair:': '👱',
  ':blond_haired_person:': '👱',
  ':blossom:': '🌼',
  ':blowfish:': '🐡',
  ':blue_book:': '📘',
  ':blue_car:': '🚙',
  ':blue_circle:': '🔵',
  ':blue_heart:': '💙',
  ':blue_square:': '🟦',
  ':blush:': '😊',
  ':boar:': '🐗',
  ':bomb:': '💣',
  ':bone:': '🦴',
  ':book:': '📖',
  ':bookmark:': '🔖',
  ':bookmark_tabs:': '📑',
  ':books:': '📚',
  ':boom:': '💥',
  ':boot:': '👢',
  ':bouquet:': '💐',
  ':archery:': '🏹',
  ':bow_and_arrow:': '🏹',
  ':bowl_with_spoon:': '🥣',
  ':bowling:': '🎳',
  ':boxing_gloves:': '🥊',
  ':boxing_glove:': '🥊',
  ':boy:': '👦',
  ':brain:': '🧠',
  ':bread:': '🍞',
  ':breast_feeding:': '🤱',
  ':bricks:': '🧱',
  ':bride_with_veil:': '👰',
  ':bridge_at_night:': '🌉',
  ':briefcase:': '💼',
  ':briefs:': '🩲',
  ':broccoli:': '🥦',
  ':broken_heart:': '💔',
  ':broom:': '🧹',
  ':brown_circle:': '🟤',
  ':brown_heart:': '🤎',
  ':brown_square:': '🟫',
  ':bug:': '🐛',
  ':bulb:': '💡',
  ':bullettrain_front:': '🚅',
  ':bullettrain_side:': '🚄',
  ':burrito:': '🌯',
  ':bus:': '🚌',
  ':busstop:': '🚏',
  ':bust_in_silhouette:': '👤',
  ':busts_in_silhouette:': '👥',
  ':butter:': '🧈',
  ':butterfly:': '🦋',
  ':cactus:': '🌵',
  ':cake:': '🍰',
  ':calendar:': '📆',
  ':spiral_calendar_pad:': '🗓️',
  ':calendar_spiral:': '🗓️',
  ':call_me_hand:': '🤙',
  ':call_me:': '🤙',
  ':calling:': '📲',
  ':camel:': '🐫',
  ':camera:': '📷',
  ':camera_with_flash:': '📸',
  ':camping:': '🏕️',
  ':candle:': '🕯️',
  ':candy:': '🍬',
  ':canned_food:': '🥫',
  ':kayak:': '🛶',
  ':canoe:': '🛶',
  ':capital_abcd:': '🔠',
  ':card_file_box:': '🗃️',
  ':card_box:': '🗃️',
  ':card_index:': '📇',
  ':carousel_horse:': '🎠',
  ':carrot:': '🥕',
  ':cat2:': '🐈',
  ':cat:': '🐱',
  ':cd:': '💿',
  ':chair:': '🪑',
  ':bottle_with_popping_cork:': '🍾',
  ':champagne:': '🍾',
  ':clinking_glass:': '🥂',
  ':champagne_glass:': '🥂',
  ':chart:': '💹',
  ':chart_with_downwards_trend:': '📉',
  ':chart_with_upwards_trend:': '📈',
  ':checkered_flag:': '🏁',
  ':cheese_wedge:': '🧀',
  ':cheese:': '🧀',
  ':cherries:': '🍒',
  ':cherry_blossom:': '🌸',
  ':chestnut:': '🌰',
  ':chicken:': '🐔',
  ':child:': '🧒',
  ':children_crossing:': '🚸',
  ':chipmunk:': '🐿️',
  ':chocolate_bar:': '🍫',
  ':chopsticks:': '🥢',
  ':christmas_tree:': '🎄',
  ':cinema:': '🎦',
  ':circus_tent:': '🎪',
  ':city_dusk:': '🌆',
  ':city_sunrise:': '🌇',
  ':city_sunset:': '🌇',
  ':cityscape:': '🏙️',
  ':cl:': '🆑',
  ':clap:': '👏',
  ':clapper:': '🎬',
  ':classical_building:': '🏛️',
  ':clipboard:': '📋',
  ':clock1030:': '🕥',
  ':clock10:': '🕙',
  ':clock1130:': '🕦',
  ':clock11:': '🕚',
  ':clock1230:': '🕧',
  ':clock12:': '🕛',
  ':clock130:': '🕜',
  ':clock1:': '🕐',
  ':clock230:': '🕝',
  ':clock2:': '🕑',
  ':clock330:': '🕞',
  ':clock3:': '🕒',
  ':clock430:': '🕟',
  ':clock4:': '🕓',
  ':clock530:': '🕠',
  ':clock5:': '🕔',
  ':clock630:': '🕡',
  ':clock6:': '🕕',
  ':clock730:': '🕢',
  ':clock7:': '🕖',
  ':clock830:': '🕣',
  ':clock8:': '🕗',
  ':clock930:': '🕤',
  ':clock9:': '🕘',
  ':mantlepiece_clock:': '🕰️',
  ':clock:': '🕰️',
  ':closed_book:': '📕',
  ':closed_lock_with_key:': '🔐',
  ':closed_umbrella:': '🌂',
  ':cloud_with_lightning:': '🌩️',
  ':cloud_lightning:': '🌩️',
  ':cloud_with_rain:': '🌧️',
  ':cloud_rain:': '🌧️',
  ':cloud_with_snow:': '🌨️',
  ':cloud_snow:': '🌨️',
  ':cloud_with_tornado:': '🌪️',
  ':cloud_tornado:': '🌪️',
  ':clown_face:': '🤡',
  ':clown:': '🤡',
  ':coat:': '🧥',
  ':cocktail:': '🍸',
  ':coconut:': '🥥',
  ':cold_face:': '🥶',
  ':cold_sweat:': '😰',
  ':compass:': '🧭',
  ':compression:': '🗜️',
  ':computer:': '💻',
  ':confetti_ball:': '🎊',
  ':confounded:': '😖',
  ':confused:': '😕',
  ':construction:': '🚧',
  ':building_construction:': '🏗️',
  ':construction_site:': '🏗️',
  ':construction_worker:': '👷',
  ':control_knobs:': '🎛️',
  ':convenience_store:': '🏪',
  ':cookie:': '🍪',
  ':cooking:': '🍳',
  ':cool:': '🆒',
  ':corn:': '🌽',
  ':couch_and_lamp:': '🛋️',
  ':couch:': '🛋️',
  ':couple:': '👫',
  ':couple_with_heart:': '💑',
  ':couplekiss:': '💏',
  ':cow2:': '🐄',
  ':cow:': '🐮',
  ':face_with_cowboy_hat:': '🤠',
  ':cowboy:': '🤠',
  ':crab:': '🦀',
  ':lower_left_crayon:': '🖍️',
  ':crayon:': '🖍️',
  ':credit_card:': '💳',
  ':crescent_moon:': '🌙',
  ':cricket:': '🦗',
  ':cricket_bat_ball:': '🏏',
  ':cricket_game:': '🏏',
  ':crocodile:': '🐊',
  ':croissant:': '🥐',
  ':crossed_flags:': '🎌',
  ':crown:': '👑',
  ':passenger_ship:': '🛳️',
  ':cruise_ship:': '🛳️',
  ':cry:': '😢',
  ':crying_cat_face:': '😿',
  ':crystal_ball:': '🔮',
  ':cucumber:': '🥒',
  ':cup_with_straw:': '🥤',
  ':cupcake:': '🧁',
  ':cupid:': '💘',
  ':curling_stone:': '🥌',
  ':curly_haired:': '🦱',
  ':currency_exchange:': '💱',
  ':curry:': '🍛',
  ':pudding:': '🍮',
  ':flan:': '🍮',
  ':custard:': '🍮',
  ':customs:': '🛃',
  ':cut_of_meat:': '🥩',
  ':cyclone:': '🌀',
  ':dagger_knife:': '🗡️',
  ':dagger:': '🗡️',
  ':dancer:': '💃',
  ':dango:': '🍡',
  ':dark_sunglasses:': '🕶️',
  ':dart:': '🎯',
  ':dash:': '💨',
  ':date:': '📅',
  ':deaf_person:': '🧏',
  ':deciduous_tree:': '🌳',
  ':deer:': '🦌',
  ':department_store:': '🏬',
  ':desert:': '🏜️',
  ':desktop_computer:': '🖥️',
  ':desktop:': '🖥️',
  ':spy:': '🕵️',
  ':sleuth_or_spy:': '🕵️',
  ':detective:': '🕵️',
  ':diamond_shape_with_a_dot_inside:': '💠',
  ':disappointed:': '😞',
  ':disappointed_relieved:': '😥',
  ':card_index_dividers:': '🗂️',
  ':dividers:': '🗂️',
  ':diving_mask:': '🤿',
  ':diya_lamp:': '🪔',
  ':dizzy:': '💫',
  ':dizzy_face:': '😵',
  ':dna:': '🧬',
  ':do_not_litter:': '🚯',
  ':dog2:': '🐕',
  ':dog:': '🐶',
  ':dollar:': '💵',
  ':dolls:': '🎎',
  ':dolphin:': '🐬',
  ':door:': '🚪',
  ':doughnut:': '🍩',
  ':dove_of_peace:': '🕊️',
  ':dove:': '🕊️',
  ':dragon:': '🐉',
  ':dragon_face:': '🐲',
  ':dress:': '👗',
  ':dromedary_camel:': '🐪',
  ':drool:': '🤤',
  ':drooling_face:': '🤤',
  ':drop_of_blood:': '🩸',
  ':droplet:': '💧',
  ':drum_with_drumsticks:': '🥁',
  ':drum:': '🥁',
  ':duck:': '🦆',
  ':dumpling:': '🥟',
  ':dvd:': '📀',
  ':email:': '📧',
  ':e-mail:': '📧',
  ':eagle:': '🦅',
  ':ear:': '👂',
  ':ear_of_rice:': '🌾',
  ':ear_with_hearing_aid:': '🦻',
  ':earth_africa:': '🌍',
  ':earth_americas:': '🌎',
  ':earth_asia:': '🌏',
  ':egg:': '🥚',
  ':eggplant:': '🍆',
  ':electric_plug:': '🔌',
  ':elephant:': '🐘',
  ':elf:': '🧝',
  ':end:': '🔚',
  ':envelope_with_arrow:': '📩',
  ':euro:': '💶',
  ':european_castle:': '🏰',
  ':european_post_office:': '🏤',
  ':evergreen_tree:': '🌲',
  ':exploding_head:': '🤯',
  ':expressionless:': '😑',
  ':eye:': '👁️',
  ':eyeglasses:': '👓',
  ':eyes:': '👀',
  ':face_vomiting:': '🤮',
  ':face_with_hand_over_mouth:': '🤭',
  ':face_with_monocle:': '🧐',
  ':face_with_raised_eyebrow:': '🤨',
  ':face_with_symbols_over_mouth:': '🤬',
  ':factory:': '🏭',
  ':fairy:': '🧚',
  ':falafel:': '🧆',
  ':fallen_leaf:': '🍂',
  ':family:': '👪',
  ':fax:': '📠',
  ':fearful:': '😨',
  ':paw_prints:': '🐾',
  ':feet:': '🐾',
  ':ferris_wheel:': '🎡',
  ':field_hockey:': '🏑',
  ':file_cabinet:': '🗄️',
  ':file_folder:': '📁',
  ':film_frames:': '🎞️',
  ':hand_with_index_and_middle_finger_crossed:': '🤞',
  ':fingers_crossed:': '🤞',
  ':flame:': '🔥',
  ':fire:': '🔥',
  ':fire_engine:': '🚒',
  ':fire_extinguisher:': '🧯',
  ':firecracker:': '🧨',
  ':fireworks:': '🎆',
  ':first_place_medal:': '🥇',
  ':first_place:': '🥇',
  ':first_quarter_moon:': '🌓',
  ':first_quarter_moon_with_face:': '🌛',
  ':fish:': '🐟',
  ':fish_cake:': '🍥',
  ':fishing_pole_and_fish:': '🎣',
  ':waving_black_flag:': '🏴',
  ':flag_black:': '🏴',
  ':waving_white_flag:': '🏳️',
  ':flag_white:': '🏳️',
  ':flags:': '🎏',
  ':flamingo:': '🦩',
  ':flashlight:': '🔦',
  ':floppy_disk:': '💾',
  ':flower_playing_cards:': '🎴',
  ':flushed:': '😳',
  ':flying_disc:': '🥏',
  ':flying_saucer:': '🛸',
  ':fog:': '🌫️',
  ':foggy:': '🌁',
  ':foot:': '🦶',
  ':football:': '🏈',
  ':footprints:': '👣',
  ':fork_and_knife:': '🍴',
  ':fork_and_knife_with_plate:': '🍽️',
  ':fork_knife_plate:': '🍽️',
  ':fortune_cookie:': '🥠',
  ':four_leaf_clover:': '🍀',
  ':fox_face:': '🦊',
  ':fox:': '🦊',
  ':frame_with_picture:': '🖼️',
  ':frame_photo:': '🖼️',
  ':free:': '🆓',
  ':baguette_bread:': '🥖',
  ':french_bread:': '🥖',
  ':fried_shrimp:': '🍤',
  ':fries:': '🍟',
  ':frog:': '🐸',
  ':frowning:': '😦',
  ':full_moon:': '🌕',
  ':full_moon_with_face:': '🌝',
  ':game_die:': '🎲',
  ':garlic:': '🧄',
  ':gem:': '💎',
  ':genie:': '🧞',
  ':ghost:': '👻',
  ':gift:': '🎁',
  ':gift_heart:': '💝',
  ':giraffe:': '🦒',
  ':girl:': '👧',
  ':globe_with_meridians:': '🌐',
  ':gloves:': '🧤',
  ':goal_net:': '🥅',
  ':goal:': '🥅',
  ':goat:': '🐐',
  ':goggles:': '🥽',
  ':gorilla:': '🦍',
  ':grapes:': '🍇',
  ':green_apple:': '🍏',
  ':green_book:': '📗',
  ':green_circle:': '🟢',
  ':green_heart:': '💚',
  ':green_square:': '🟩',
  ':grimacing:': '😬',
  ':grin:': '😁',
  ':grinning:': '😀',
  ':guardsman:': '💂',
  ':guard:': '💂',
  ':guide_dog:': '🦮',
  ':guitar:': '🎸',
  ':gun:': '🔫',
  ':hamburger:': '🍔',
  ':hammer:': '🔨',
  ':hamster:': '🐹',
  ':raised_hand_with_fingers_splayed:': '🖐️',
  ':hand_splayed:': '🖐️',
  ':handbag:': '👜',
  ':shaking_hands:': '🤝',
  ':handshake:': '🤝',
  ':hatched_chick:': '🐥',
  ':hatching_chick:': '🐣',
  ':face_with_head_bandage:': '🤕',
  ':head_bandage:': '🤕',
  ':headphones:': '🎧',
  ':hear_no_evil:': '🙉',
  ':heart_decoration:': '💟',
  ':heart_eyes:': '😍',
  ':heart_eyes_cat:': '😻',
  ':heartbeat:': '💓',
  ':heartpulse:': '💗',
  ':heavy_dollar_sign:': '💲',
  ':hedgehog:': '🦔',
  ':helicopter:': '🚁',
  ':herb:': '🌿',
  ':hibiscus:': '🌺',
  ':high_brightness:': '🔆',
  ':high_heel:': '👠',
  ':hiking_boot:': '🥾',
  ':hindu_temple:': '🛕',
  ':hippopotamus:': '🦛',
  ':hockey:': '🏒',
  ':hole:': '🕳️',
  ':house_buildings:': '🏘️',
  ':homes:': '🏘️',
  ':honey_pot:': '🍯',
  ':horse:': '🐴',
  ':horse_racing:': '🏇',
  ':hospital:': '🏥',
  ':hot_face:': '🥵',
  ':hot_pepper:': '🌶️',
  ':hot_dog:': '🌭',
  ':hotdog:': '🌭',
  ':hotel:': '🏨',
  ':house:': '🏠',
  ':derelict_house_building:': '🏚️',
  ':house_abandoned:': '🏚️',
  ':house_with_garden:': '🏡',
  ':hugging_face:': '🤗',
  ':hugging:': '🤗',
  ':hushed:': '😯',
  ':ice_cream:': '🍨',
  ':ice_cube:': '🧊',
  ':icecream:': '🍦',
  ':id:': '🆔',
  ':ideograph_advantage:': '🉐',
  ':imp:': '👿',
  ':inbox_tray:': '📥',
  ':incoming_envelope:': '📨',
  ':innocent:': '😇',
  ':iphone:': '📱',
  ':desert_island:': '🏝️',
  ':island:': '🏝️',
  ':izakaya_lantern:': '🏮',
  ':jack_o_lantern:': '🎃',
  ':japan:': '🗾',
  ':japanese_castle:': '🏯',
  ':japanese_goblin:': '👺',
  ':japanese_ogre:': '👹',
  ':jeans:': '👖',
  ':jigsaw:': '🧩',
  ':joy:': '😂',
  ':joy_cat:': '😹',
  ':joystick:': '🕹️',
  ':kaaba:': '🕋',
  ':kangaroo:': '🦘',
  ':old_key:': '🗝️',
  ':key2:': '🗝️',
  ':key:': '🔑',
  ':keycap_ten:': '🔟',
  ':kimono:': '👘',
  ':kiss:': '💋',
  ':kissing:': '😗',
  ':kissing_cat:': '😽',
  ':kissing_closed_eyes:': '😚',
  ':kissing_heart:': '😘',
  ':kissing_smiling_eyes:': '😙',
  ':kite:': '🪁',
  ':kiwifruit:': '🥝',
  ':kiwi:': '🥝',
  ':knife:': '🔪',
  ':koala:': '🐨',
  ':koko:': '🈁',
  ':lab_coat:': '🥼',
  ':label:': '🏷️',
  ':lacrosse:': '🥍',
  ':large_blue_diamond:': '🔷',
  ':large_orange_diamond:': '🔶',
  ':last_quarter_moon:': '🌗',
  ':last_quarter_moon_with_face:': '🌜',
  ':satisfied:': '😆',
  ':laughing:': '😆',
  ':leafy_green:': '🥬',
  ':leaves:': '🍃',
  ':ledger:': '📒',
  ':left_fist:': '🤛',
  ':left_facing_fist:': '🤛',
  ':left_luggage:': '🛅',
  ':leg:': '🦵',
  ':lemon:': '🍋',
  ':leopard:': '🐆',
  ':level_slider:': '🎚️',
  ':man_in_business_suit_levitating:': '🕴️',
  ':levitate:': '🕴️',
  ':light_rail:': '🚈',
  ':link:': '🔗',
  ':lion:': '🦁',
  ':lion_face:': '🦁',
  ':lips:': '👄',
  ':lipstick:': '💄',
  ':lizard:': '🦎',
  ':llama:': '🦙',
  ':lobster:': '🦞',
  ':lock:': '🔒',
  ':lock_with_ink_pen:': '🔏',
  ':lollipop:': '🍭',
  ':loud_sound:': '🔊',
  ':loudspeaker:': '📢',
  ':love_hotel:': '🏩',
  ':love_letter:': '💌',
  ':love_you_gesture:': '🤟',
  ':low_brightness:': '🔅',
  ':luggage:': '🧳',
  ':liar:': '🤥',
  ':lying_face:': '🤥',
  ':mag:': '🔍',
  ':mag_right:': '🔎',
  ':mage:': '🧙',
  ':magnet:': '🧲',
  ':mahjong:': '🀄',
  ':mailbox:': '📫',
  ':mailbox_closed:': '📪',
  ':mailbox_with_mail:': '📬',
  ':mailbox_with_no_mail:': '📭',
  ':man:': '👨',
  ':male_dancer:': '🕺',
  ':man_dancing:': '🕺',
  ':man_in_tuxedo:': '🤵',
  ':man_with_gua_pi_mao:': '👲',
  ':man_with_chinese_cap:': '👲',
  ':mango:': '🥭',
  ':mans_shoe:': '👞',
  ':manual_wheelchair:': '🦽',
  ':world_map:': '🗺️',
  ':map:': '🗺️',
  ':maple_leaf:': '🍁',
  ':karate_uniform:': '🥋',
  ':martial_arts_uniform:': '🥋',
  ':mask:': '😷',
  ':mate:': '🧉',
  ':meat_on_bone:': '🍖',
  ':mechanical_arm:': '🦾',
  ':mechanical_leg:': '🦿',
  ':sports_medal:': '🏅',
  ':medal:': '🏅',
  ':mega:': '📣',
  ':melon:': '🍈',
  ':menorah:': '🕎',
  ':mens:': '🚹',
  ':merperson:': '🧜',
  ':sign_of_the_horns:': '🤘',
  ':metal:': '🤘',
  ':metro:': '🚇',
  ':microbe:': '🦠',
  ':studio_microphone:': '🎙️',
  ':microphone2:': '🎙️',
  ':microphone:': '🎤',
  ':microscope:': '🔬',
  ':reversed_hand_with_middle_finger_extended:': '🖕',
  ':middle_finger:': '🖕',
  ':military_medal:': '🎖️',
  ':glass_of_milk:': '🥛',
  ':milk:': '🥛',
  ':milky_way:': '🌌',
  ':minibus:': '🚐',
  ':minidisc:': '💽',
  ':mobile_phone_off:': '📴',
  ':money_mouth_face:': '🤑',
  ':money_mouth:': '🤑',
  ':money_with_wings:': '💸',
  ':moneybag:': '💰',
  ':monkey:': '🐒',
  ':monkey_face:': '🐵',
  ':monorail:': '🚝',
  ':moon_cake:': '🥮',
  ':mortar_board:': '🎓',
  ':mosque:': '🕌',
  ':mosquito:': '🦟',
  ':motorbike:': '🛵',
  ':motor_scooter:': '🛵',
  ':motorboat:': '🛥️',
  ':racing_motorcycle:': '🏍️',
  ':motorcycle:': '🏍️',
  ':motorized_wheelchair:': '🦼',
  ':motorway:': '🛣️',
  ':mount_fuji:': '🗻',
  ':mountain_cableway:': '🚠',
  ':mountain_railway:': '🚞',
  ':snow_capped_mountain:': '🏔️',
  ':mountain_snow:': '🏔️',
  ':mouse2:': '🐁',
  ':mouse:': '🐭',
  ':three_button_mouse:': '🖱️',
  ':mouse_three_button:': '🖱️',
  ':movie_camera:': '🎥',
  ':moyai:': '🗿',
  ':mother_christmas:': '🤶',
  ':mrs_claus:': '🤶',
  ':muscle:': '💪',
  ':mushroom:': '🍄',
  ':musical_keyboard:': '🎹',
  ':musical_note:': '🎵',
  ':musical_score:': '🎼',
  ':mute:': '🔇',
  ':nail_care:': '💅',
  ':name_badge:': '📛',
  ':sick:': '🤢',
  ':nauseated_face:': '🤢',
  ':nazar_amulet:': '🧿',
  ':necktie:': '👔',
  ':nerd_face:': '🤓',
  ':nerd:': '🤓',
  ':neutral_face:': '😐',
  ':new:': '🆕',
  ':new_moon:': '🌑',
  ':new_moon_with_face:': '🌚',
  ':rolled_up_newspaper:': '🗞️',
  ':newspaper2:': '🗞️',
  ':newspaper:': '📰',
  ':ng:': '🆖',
  ':night_with_stars:': '🌃',
  ':no_bell:': '🔕',
  ':no_bicycles:': '🚳',
  ':no_entry_sign:': '🚫',
  ':no_mobile_phones:': '📵',
  ':no_mouth:': '😶',
  ':no_pedestrians:': '🚷',
  ':no_smoking:': '🚭',
  ':non-potable_water:': '🚱',
  ':nose:': '👃',
  ':notebook:': '📓',
  ':notebook_with_decorative_cover:': '📔',
  ':spiral_note_pad:': '🗒️',
  ':notepad_spiral:': '🗒️',
  ':notes:': '🎶',
  ':nut_and_bolt:': '🔩',
  ':o2:': '🅾️',
  ':ocean:': '🌊',
  ':stop_sign:': '🛑',
  ':octagonal_sign:': '🛑',
  ':octopus:': '🐙',
  ':oden:': '🍢',
  ':office:': '🏢',
  ':oil_drum:': '🛢️',
  ':oil:': '🛢️',
  ':ok:': '🆗',
  ':ok_hand:': '👌',
  ':older_adult:': '🧓',
  ':older_man:': '👴',
  ':grandma:': '👵',
  ':older_woman:': '👵',
  ':om_symbol:': '🕉️',
  ':on:': '🔛',
  ':oncoming_automobile:': '🚘',
  ':oncoming_bus:': '🚍',
  ':oncoming_police_car:': '🚔',
  ':oncoming_taxi:': '🚖',
  ':one_piece_swimsuit:': '🩱',
  ':onion:': '🧅',
  ':open_file_folder:': '📂',
  ':open_hands:': '👐',
  ':open_mouth:': '😮',
  ':orange_book:': '📙',
  ':orange_circle:': '🟠',
  ':orange_heart:': '🧡',
  ':orange_square:': '🟧',
  ':orangutan:': '🦧',
  ':otter:': '🦦',
  ':outbox_tray:': '📤',
  ':owl:': '🦉',
  ':ox:': '🐂',
  ':oyster:': '🦪',
  ':package:': '📦',
  ':page_facing_up:': '📄',
  ':page_with_curl:': '📃',
  ':pager:': '📟',
  ':lower_left_paintbrush:': '🖌️',
  ':paintbrush:': '🖌️',
  ':palm_tree:': '🌴',
  ':palms_up_together:': '🤲',
  ':pancakes:': '🥞',
  ':panda_face:': '🐼',
  ':paperclip:': '📎',
  ':linked_paperclips:': '🖇️',
  ':paperclips:': '🖇️',
  ':parachute:': '🪂',
  ':national_park:': '🏞️',
  ':park:': '🏞️',
  ':parking:': '🅿️',
  ':parrot:': '🦜',
  ':partying_face:': '🥳',
  ':passport_control:': '🛂',
  ':peach:': '🍑',
  ':peacock:': '🦚',
  ':shelled_peanut:': '🥜',
  ':peanuts:': '🥜',
  ':pear:': '🍐',
  ':lower_left_ballpoint_pen:': '🖊️',
  ':pen_ballpoint:': '🖊️',
  ':lower_left_fountain_pen:': '🖋️',
  ':pen_fountain:': '🖋️',
  ':memo:': '📝',
  ':pencil:': '📝',
  ':penguin:': '🐧',
  ':pensive:': '😔',
  ':dancers:': '👯',
  ':people_with_bunny_ears_partying:': '👯',
  ':wrestlers:': '🤼',
  ':wrestling:': '🤼',
  ':people_wrestling:': '🤼',
  ':performing_arts:': '🎭',
  ':persevere:': '😣',
  ':bicyclist:': '🚴',
  ':person_biking:': '🚴',
  ':bow:': '🙇',
  ':person_bowing:': '🙇',
  ':person_climbing:': '🧗',
  ':cartwheel:': '🤸',
  ':person_doing_cartwheel:': '🤸',
  ':face_palm:': '🤦',
  ':facepalm:': '🤦',
  ':person_facepalming:': '🤦',
  ':fencer:': '🤺',
  ':fencing:': '🤺',
  ':person_fencing:': '🤺',
  ':person_frowning:': '🙍',
  ':no_good:': '🙅',
  ':person_gesturing_no:': '🙅',
  ':ok_woman:': '🙆',
  ':person_gesturing_ok:': '🙆',
  ':haircut:': '💇',
  ':person_getting_haircut:': '💇',
  ':massage:': '💆',
  ':person_getting_massage:': '💆',
  ':golfer:': '🏌️',
  ':person_golfing:': '🏌️',
  ':person_in_lotus_position:': '🧘',
  ':person_in_steamy_room:': '🧖',
  ':juggling:': '🤹',
  ':juggler:': '🤹',
  ':person_juggling:': '🤹',
  ':person_kneeling:': '🧎',
  ':lifter:': '🏋️',
  ':weight_lifter:': '🏋️',
  ':person_lifting_weights:': '🏋️',
  ':mountain_bicyclist:': '🚵',
  ':person_mountain_biking:': '🚵',
  ':handball:': '🤾',
  ':person_playing_handball:': '🤾',
  ':water_polo:': '🤽',
  ':person_playing_water_polo:': '🤽',
  ':person_with_pouting_face:': '🙎',
  ':person_pouting:': '🙎',
  ':raising_hand:': '🙋',
  ':person_raising_hand:': '🙋',
  ':rowboat:': '🚣',
  ':person_rowing_boat:': '🚣',
  ':runner:': '🏃',
  ':person_running:': '🏃',
  ':shrug:': '🤷',
  ':person_shrugging:': '🤷',
  ':person_standing:': '🧍',
  ':surfer:': '🏄',
  ':person_surfing:': '🏄',
  ':swimmer:': '🏊',
  ':person_swimming:': '🏊',
  ':information_desk_person:': '💁',
  ':person_tipping_hand:': '💁',
  ':walking:': '🚶',
  ':person_walking:': '🚶',
  ':man_with_turban:': '👳',
  ':person_wearing_turban:': '👳',
  ':petri_dish:': '🧫',
  ':pie:': '🥧',
  ':pig2:': '🐖',
  ':pig:': '🐷',
  ':pig_nose:': '🐽',
  ':pill:': '💊',
  ':pinching_hand:': '🤏',
  ':pineapple:': '🍍',
  ':table_tennis:': '🏓',
  ':ping_pong:': '🏓',
  ':pizza:': '🍕',
  ':worship_symbol:': '🛐',
  ':place_of_worship:': '🛐',
  ':pleading_face:': '🥺',
  ':point_down:': '👇',
  ':point_left:': '👈',
  ':point_right:': '👉',
  ':point_up_2:': '👆',
  ':police_car:': '🚓',
  ':cop:': '👮',
  ':police_officer:': '👮',
  ':poodle:': '🐩',
  ':shit:': '💩',
  ':hankey:': '💩',
  ':poo:': '💩',
  ':poop:': '💩',
  ':popcorn:': '🍿',
  ':post_office:': '🏣',
  ':postal_horn:': '📯',
  ':postbox:': '📮',
  ':potable_water:': '🚰',
  ':potato:': '🥔',
  ':pouch:': '👝',
  ':poultry_leg:': '🍗',
  ':pound:': '💷',
  ':pouting_cat:': '😾',
  ':pray:': '🙏',
  ':prayer_beads:': '📿',
  ':expecting_woman:': '🤰',
  ':pregnant_woman:': '🤰',
  ':pretzel:': '🥨',
  ':prince:': '🤴',
  ':princess:': '👸',
  ':printer:': '🖨️',
  ':probing_cane:': '🦯',
  ':film_projector:': '📽️',
  ':projector:': '📽️',
  ':punch:': '👊',
  ':purple_circle:': '🟣',
  ':purple_heart:': '💜',
  ':purple_square:': '🟪',
  ':purse:': '👛',
  ':pushpin:': '📌',
  ':put_litter_in_its_place:': '🚮',
  ':rabbit2:': '🐇',
  ':rabbit:': '🐰',
  ':raccoon:': '🦝',
  ':racing_car:': '🏎️',
  ':race_car:': '🏎️',
  ':racehorse:': '🐎',
  ':radio:': '📻',
  ':radio_button:': '🔘',
  ':rage:': '😡',
  ':railway_car:': '🚃',
  ':railroad_track:': '🛤️',
  ':railway_track:': '🛤️',
  ':rainbow:': '🌈',
  ':back_of_hand:': '🤚',
  ':raised_back_of_hand:': '🤚',
  ':raised_hands:': '🙌',
  ':ram:': '🐏',
  ':ramen:': '🍜',
  ':rat:': '🐀',
  ':razor:': '🪒',
  ':receipt:': '🧾',
  ':red_car:': '🚗',
  ':red_circle:': '🔴',
  ':red_envelope:': '🧧',
  ':red_haired:': '🦰',
  ':red_square:': '🟥',
  ':regional_indicator_a:': '🇦',
  ':regional_indicator_b:': '🇧',
  ':regional_indicator_c:': '🇨',
  ':regional_indicator_d:': '🇩',
  ':regional_indicator_e:': '🇪',
  ':regional_indicator_f:': '🇫',
  ':regional_indicator_g:': '🇬',
  ':regional_indicator_h:': '🇭',
  ':regional_indicator_i:': '🇮',
  ':regional_indicator_j:': '🇯',
  ':regional_indicator_k:': '🇰',
  ':regional_indicator_l:': '🇱',
  ':regional_indicator_m:': '🇲',
  ':regional_indicator_n:': '🇳',
  ':regional_indicator_o:': '🇴',
  ':regional_indicator_p:': '🇵',
  ':regional_indicator_q:': '🇶',
  ':regional_indicator_r:': '🇷',
  ':regional_indicator_s:': '🇸',
  ':regional_indicator_t:': '🇹',
  ':regional_indicator_u:': '🇺',
  ':regional_indicator_v:': '🇻',
  ':regional_indicator_w:': '🇼',
  ':regional_indicator_x:': '🇽',
  ':regional_indicator_y:': '🇾',
  ':regional_indicator_z:': '🇿',
  ':relieved:': '😌',
  ':reminder_ribbon:': '🎗️',
  ':repeat:': '🔁',
  ':repeat_one:': '🔂',
  ':restroom:': '🚻',
  ':revolving_hearts:': '💞',
  ':rhinoceros:': '🦏',
  ':rhino:': '🦏',
  ':ribbon:': '🎀',
  ':rice:': '🍚',
  ':rice_ball:': '🍙',
  ':rice_cracker:': '🍘',
  ':rice_scene:': '🎑',
  ':right_fist:': '🤜',
  ':right_facing_fist:': '🤜',
  ':ring:': '💍',
  ':ringed_planet:': '🪐',
  ':robot_face:': '🤖',
  ':robot:': '🤖',
  ':rocket:': '🚀',
  ':rolling_on_the_floor_laughing:': '🤣',
  ':rofl:': '🤣',
  ':roll_of_paper:': '🧻',
  ':roller_coaster:': '🎢',
  ':face_with_rolling_eyes:': '🙄',
  ':rolling_eyes:': '🙄',
  ':rooster:': '🐓',
  ':rose:': '🌹',
  ':rosette:': '🏵️',
  ':rotating_light:': '🚨',
  ':round_pushpin:': '📍',
  ':rugby_football:': '🏉',
  ':running_shirt_with_sash:': '🎽',
  ':sa:': '🈂️',
  ':safety_pin:': '🧷',
  ':safety_vest:': '🦺',
  ':sake:': '🍶',
  ':green_salad:': '🥗',
  ':salad:': '🥗',
  ':salt:': '🧂',
  ':sandal:': '👡',
  ':sandwich:': '🥪',
  ':santa:': '🎅',
  ':sari:': '🥻',
  ':satellite:': '📡',
  ':satellite_orbital:': '🛰️',
  ':sauropod:': '🦕',
  ':saxophone:': '🎷',
  ':scarf:': '🧣',
  ':school:': '🏫',
  ':school_satchel:': '🎒',
  ':scooter:': '🛴',
  ':scorpion:': '🦂',
  ':scream:': '😱',
  ':scream_cat:': '🙀',
  ':scroll:': '📜',
  ':seat:': '💺',
  ':second_place_medal:': '🥈',
  ':second_place:': '🥈',
  ':see_no_evil:': '🙈',
  ':seedling:': '🌱',
  ':selfie:': '🤳',
  ':paella:': '🥘',
  ':shallow_pan_of_food:': '🥘',
  ':shark:': '🦈',
  ':shaved_ice:': '🍧',
  ':sheep:': '🐑',
  ':shell:': '🐚',
  ':shield:': '🛡️',
  ':ship:': '🚢',
  ':shirt:': '👕',
  ':shopping_bags:': '🛍️',
  ':shopping_trolley:': '🛒',
  ':shopping_cart:': '🛒',
  ':shorts:': '🩳',
  ':shower:': '🚿',
  ':shrimp:': '🦐',
  ':shushing_face:': '🤫',
  ':signal_strength:': '📶',
  ':six_pointed_star:': '🔯',
  ':skateboard:': '🛹',
  ':ski:': '🎿',
  ':skeleton:': '💀',
  ':skull:': '💀',
  ':skunk:': '🦨',
  ':sled:': '🛷',
  ':sleeping:': '😴',
  ':sleeping_accommodation:': '🛌',
  ':sleepy:': '😪',
  ':slightly_frowning_face:': '🙁',
  ':slight_frown:': '🙁',
  ':slightly_smiling_face:': '🙂',
  ':slight_smile:': '🙂',
  ':slot_machine:': '🎰',
  ':sloth:': '🦥',
  ':small_blue_diamond:': '🔹',
  ':small_orange_diamond:': '🔸',
  ':small_red_triangle:': '🔺',
  ':small_red_triangle_down:': '🔻',
  ':smile:': '😄',
  ':smile_cat:': '😸',
  ':smiley:': '😃',
  ':smiley_cat:': '😺',
  ':smiling_face_with_3_hearts:': '🥰',
  ':smiling_imp:': '😈',
  ':smirk:': '😏',
  ':smirk_cat:': '😼',
  ':smoking:': '🚬',
  ':snail:': '🐌',
  ':snake:': '🐍',
  ':sneeze:': '🤧',
  ':sneezing_face:': '🤧',
  ':snowboarder:': '🏂',
  ':soap:': '🧼',
  ':sob:': '😭',
  ':socks:': '🧦',
  ':softball:': '🥎',
  ':soon:': '🔜',
  ':sos:': '🆘',
  ':sound:': '🔉',
  ':space_invader:': '👾',
  ':spaghetti:': '🍝',
  ':sparkler:': '🎇',
  ':sparkling_heart:': '💖',
  ':speak_no_evil:': '🙊',
  ':speaker:': '🔈',
  ':speaking_head_in_silhouette:': '🗣️',
  ':speaking_head:': '🗣️',
  ':speech_balloon:': '💬',
  ':left_speech_bubble:': '🗨️',
  ':speech_left:': '🗨️',
  ':speedboat:': '🚤',
  ':spider:': '🕷️',
  ':spider_web:': '🕸️',
  ':sponge:': '🧽',
  ':spoon:': '🥄',
  ':squeeze_bottle:': '🧴',
  ':squid:': '🦑',
  ':stadium:': '🏟️',
  ':star2:': '🌟',
  ':star_struck:': '🤩',
  ':stars:': '🌠',
  ':station:': '🚉',
  ':statue_of_liberty:': '🗽',
  ':steam_locomotive:': '🚂',
  ':stethoscope:': '🩺',
  ':stew:': '🍲',
  ':straight_ruler:': '📏',
  ':strawberry:': '🍓',
  ':stuck_out_tongue:': '😛',
  ':stuck_out_tongue_closed_eyes:': '😝',
  ':stuck_out_tongue_winking_eye:': '😜',
  ':stuffed_pita:': '🥙',
  ':stuffed_flatbread:': '🥙',
  ':sun_with_face:': '🌞',
  ':sunflower:': '🌻',
  ':sunglasses:': '😎',
  ':sunrise:': '🌅',
  ':sunrise_over_mountains:': '🌄',
  ':superhero:': '🦸',
  ':supervillain:': '🦹',
  ':sushi:': '🍣',
  ':suspension_railway:': '🚟',
  ':swan:': '🦢',
  ':sweat:': '😓',
  ':sweat_drops:': '💦',
  ':sweat_smile:': '😅',
  ':sweet_potato:': '🍠',
  ':symbols:': '🔣',
  ':synagogue:': '🕍',
  ':syringe:': '💉',
  ':t_rex:': '🦖',
  ':taco:': '🌮',
  ':tada:': '🎉',
  ':takeout_box:': '🥡',
  ':tanabata_tree:': '🎋',
  ':tangerine:': '🍊',
  ':taxi:': '🚕',
  ':tea:': '🍵',
  ':teddy_bear:': '🧸',
  ':telephone_receiver:': '📞',
  ':telescope:': '🔭',
  ':tennis:': '🎾',
  ':test_tube:': '🧪',
  ':thermometer:': '🌡️',
  ':face_with_thermometer:': '🤒',
  ':thermometer_face:': '🤒',
  ':thinking_face:': '🤔',
  ':thinking:': '🤔',
  ':third_place_medal:': '🥉',
  ':third_place:': '🥉',
  ':thought_balloon:': '💭',
  ':thread:': '🧵',
  ':-1:': '👎',
  ':thumbdown:': '👎',
  ':thumbsdown:': '👎',
  ':+1:': '👍',
  ':thumbup:': '👍',
  ':thumbsup:': '👍',
  ':ticket:': '🎫',
  ':admission_tickets:': '🎟️',
  ':tickets:': '🎟️',
  ':tiger2:': '🐅',
  ':tiger:': '🐯',
  ':tired_face:': '😫',
  ':toilet:': '🚽',
  ':tokyo_tower:': '🗼',
  ':tomato:': '🍅',
  ':tone1:': '🏻',
  ':tone2:': '🏼',
  ':tone3:': '🏽',
  ':tone4:': '🏾',
  ':tone5:': '🏿',
  ':tongue:': '👅',
  ':toolbox:': '🧰',
  ':hammer_and_wrench:': '🛠️',
  ':tools:': '🛠️',
  ':tooth:': '🦷',
  ':top:': '🔝',
  ':tophat:': '🎩',
  ':trackball:': '🖲️',
  ':tractor:': '🚜',
  ':traffic_light:': '🚥',
  ':train2:': '🚆',
  ':train:': '🚋',
  ':tram:': '🚊',
  ':triangular_flag_on_post:': '🚩',
  ':triangular_ruler:': '📐',
  ':trident:': '🔱',
  ':triumph:': '😤',
  ':trolleybus:': '🚎',
  ':trophy:': '🏆',
  ':tropical_drink:': '🍹',
  ':tropical_fish:': '🐠',
  ':truck:': '🚚',
  ':trumpet:': '🎺',
  ':tulip:': '🌷',
  ':whisky:': '🥃',
  ':tumbler_glass:': '🥃',
  ':turkey:': '🦃',
  ':turtle:': '🐢',
  ':tv:': '📺',
  ':twisted_rightwards_arrows:': '🔀',
  ':two_hearts:': '💕',
  ':two_men_holding_hands:': '👬',
  ':two_women_holding_hands:': '👭',
  ':u5272:': '🈹',
  ':u5408:': '🈴',
  ':u55b6:': '🈺',
  ':u6307:': '🈯',
  ':u6708:': '🈷️',
  ':u6709:': '🈶',
  ':u6e80:': '🈵',
  ':u7121:': '🈚',
  ':u7533:': '🈸',
  ':u7981:': '🈲',
  ':u7a7a:': '🈳',
  ':unamused:': '😒',
  ':underage:': '🔞',
  ':unicorn_face:': '🦄',
  ':unicorn:': '🦄',
  ':unlock:': '🔓',
  ':up:': '🆙',
  ':upside_down_face:': '🙃',
  ':upside_down:': '🙃',
  ':vampire:': '🧛',
  ':vertical_traffic_light:': '🚦',
  ':vhs:': '📼',
  ':vibration_mode:': '📳',
  ':video_camera:': '📹',
  ':video_game:': '🎮',
  ':violin:': '🎻',
  ':volcano:': '🌋',
  ':volleyball:': '🏐',
  ':vs:': '🆚',
  ':raised_hand_with_part_between_middle_and_ring_fingers:': '🖖',
  ':vulcan:': '🖖',
  ':waffle:': '🧇',
  ':waning_crescent_moon:': '🌘',
  ':waning_gibbous_moon:': '🌖',
  ':wastebasket:': '🗑️',
  ':water_buffalo:': '🐃',
  ':watermelon:': '🍉',
  ':wave:': '👋',
  ':waxing_crescent_moon:': '🌒',
  ':waxing_gibbous_moon:': '🌔',
  ':wc:': '🚾',
  ':weary:': '😩',
  ':wedding:': '💒',
  ':whale2:': '🐋',
  ':whale:': '🐳',
  ':white_flower:': '💮',
  ':white_haired:': '🦳',
  ':white_heart:': '🤍',
  ':white_square_button:': '🔳',
  ':white_sun_behind_cloud:': '🌥️',
  ':white_sun_cloud:': '🌥️',
  ':white_sun_behind_cloud_with_rain:': '🌦️',
  ':white_sun_rain_cloud:': '🌦️',
  ':white_sun_with_small_cloud:': '🌤️',
  ':white_sun_small_cloud:': '🌤️',
  ':wilted_flower:': '🥀',
  ':wilted_rose:': '🥀',
  ':wind_blowing_face:': '🌬️',
  ':wind_chime:': '🎐',
  ':wine_glass:': '🍷',
  ':wink:': '😉',
  ':wolf:': '🐺',
  ':woman:': '👩',
  ':woman_with_headscarf:': '🧕',
  ':womans_clothes:': '👚',
  ':womans_flat_shoe:': '🥿',
  ':womans_hat:': '👒',
  ':womens:': '🚺',
  ':woozy_face:': '🥴',
  ':worried:': '😟',
  ':wrench:': '🔧',
  ':yarn:': '🧶',
  ':yawning_face:': '🥱',
  ':yellow_circle:': '🟡',
  ':yellow_heart:': '💛',
  ':yellow_square:': '🟨',
  ':yen:': '💴',
  ':yo_yo:': '🪀',
  ':yum:': '😋',
  ':zany_face:': '🤪',
  ':zebra:': '🦓',
  ':zipper_mouth_face:': '🤐',
  ':zipper_mouth:': '🤐',
  ':zombie:': '🧟',
  ':zzz:': '💤',
  ':airplane:': '✈️',
  ':alarm_clock:': '⏰',
  ':alembic:': '⚗️',
  ':anchor:': '⚓',
  ':aquarius:': '♒',
  ':aries:': '♈',
  ':arrow_backward:': '◀️',
  ':arrow_double_down:': '⏬',
  ':arrow_double_up:': '⏫',
  ':arrow_down:': '⬇️',
  ':arrow_forward:': '▶️',
  ':arrow_heading_down:': '⤵️',
  ':arrow_heading_up:': '⤴️',
  ':arrow_left:': '⬅️',
  ':arrow_lower_left:': '↙️',
  ':arrow_lower_right:': '↘️',
  ':arrow_right:': '➡️',
  ':arrow_right_hook:': '↪️',
  ':arrow_up:': '⬆️',
  ':arrow_up_down:': '↕️',
  ':arrow_upper_left:': '↖️',
  ':arrow_upper_right:': '↗️',
  ':asterisk_symbol:': '*️',
  ':atom_symbol:': '⚛️',
  ':atom:': '⚛️',
  ':ballot_box_with_check:': '☑️',
  ':bangbang:': '‼️',
  ':baseball:': '⚾',
  ':umbrella_on_ground:': '⛱️',
  ':beach_umbrella:': '⛱️',
  ':biohazard_sign:': '☣️',
  ':biohazard:': '☣️',
  ':black_circle:': '⚫',
  ':black_large_square:': '⬛',
  ':black_medium_small_square:': '◾',
  ':black_medium_square:': '◼️',
  ':black_nib:': '✒️',
  ':black_small_square:': '▪️',
  ':cancer:': '♋',
  ':capricorn:': '♑',
  ':chains:': '⛓️',
  ':chess_pawn:': '♟️',
  ':church:': '⛪',
  ':cloud:': '☁️',
  ':clubs:': '♣️',
  ':coffee:': '☕',
  ':coffin:': '⚰️',
  ':comet:': '☄️',
  ':congratulations:': '㊗️',
  ':copyright:': '©️',
  ':latin_cross:': '✝️',
  ':cross:': '✝️',
  ':crossed_swords:': '⚔️',
  ':curly_loop:': '➰',
  ':diamonds:': '♦️',
  ':digit_eight:': '8️',
  ':digit_five:': '5️',
  ':digit_four:': '4️',
  ':digit_nine:': '9️',
  ':digit_one:': '1️',
  ':digit_seven:': '7️',
  ':digit_six:': '6️',
  ':digit_three:': '3️',
  ':digit_two:': '2️',
  ':digit_zero:': '0️',
  ':eight_pointed_black_star:': '✴️',
  ':eight_spoked_asterisk:': '✳️',
  ':eject_symbol:': '⏏️',
  ':eject:': '⏏️',
  ':envelope:': '✉️',
  ':exclamation:': '❗',
  ':fast_forward:': '⏩',
  ':female_sign:': '♀️',
  ':ferry:': '⛴️',
  ':fist:': '✊',
  ':fleur-de-lis:': '⚜️',
  ':fountain:': '⛲',
  ':white_frowning_face:': '☹️',
  ':frowning2:': '☹️',
  ':fuelpump:': '⛽',
  ':gear:': '⚙️',
  ':gemini:': '♊',
  ':golf:': '⛳',
  ':grey_exclamation:': '❕',
  ':grey_question:': '❔',
  ':hammer_and_pick:': '⚒️',
  ':hammer_pick:': '⚒️',
  ':heart:': '❤️',
  ':heavy_heart_exclamation_mark_ornament:': '❣️',
  ':heart_exclamation:': '❣️',
  ':hearts:': '♥️',
  ':heavy_check_mark:': '✔️',
  ':heavy_division_sign:': '➗',
  ':heavy_minus_sign:': '➖',
  ':heavy_multiplication_x:': '✖️',
  ':heavy_plus_sign:': '➕',
  ':helmet_with_white_cross:': '⛑️',
  ':helmet_with_cross:': '⛑️',
  ':hotsprings:': '♨️',
  ':hourglass:': '⌛',
  ':hourglass_flowing_sand:': '⏳',
  ':ice_skate:': '⛸️',
  ':infinity:': '♾️',
  ':information_source:': 'ℹ️',
  ':interrobang:': '⁉️',
  ':keyboard:': '⌨️',
  ':left_right_arrow:': '↔️',
  ':leftwards_arrow_with_hook:': '↩️',
  ':leo:': '♌',
  ':libra:': '♎',
  ':loop:': '➿',
  ':m:': 'Ⓜ️',
  ':male_sign:': '♂️',
  ':medical_symbol:': '⚕️',
  ':mountain:': '⛰️',
  ':negative_squared_cross_mark:': '❎',
  ':no_entry:': '⛔',
  ':o:': '⭕',
  ':ophiuchus:': '⛎',
  ':orthodox_cross:': '☦️',
  ':part_alternation_mark:': '〽️',
  ':partly_sunny:': '⛅',
  ':double_vertical_bar:': '⏸️',
  ':pause_button:': '⏸️',
  ':peace_symbol:': '☮️',
  ':peace:': '☮️',
  ':pencil2:': '✏️',
  ':basketball_player:': '⛹️',
  ':person_with_ball:': '⛹️',
  ':person_bouncing_ball:': '⛹️',
  ':pick:': '⛏️',
  ':pisces:': '♓',
  ':play_pause:': '⏯️',
  ':point_up:': '☝️',
  ':pound_symbol:': '#️',
  ':question:': '❓',
  ':radioactive_sign:': '☢️',
  ':radioactive:': '☢️',
  ':raised_hand:': '✋',
  ':record_button:': '⏺️',
  ':recycle:': '♻️',
  ':registered:': '®️',
  ':relaxed:': '☺️',
  ':rewind:': '⏪',
  ':sagittarius:': '♐',
  ':sailboat:': '⛵',
  ':scales:': '⚖️',
  ':scissors:': '✂️',
  ':scorpius:': '♏',
  ':secret:': '㊙️',
  ':shamrock:': '☘️',
  ':shinto_shrine:': '⛩️',
  ':skier:': '⛷️',
  ':skull_and_crossbones:': '☠️',
  ':skull_crossbones:': '☠️',
  ':snowflake:': '❄️',
  ':snowman2:': '☃️',
  ':snowman:': '⛄',
  ':soccer:': '⚽',
  ':spades:': '♠️',
  ':sparkle:': '❇️',
  ':sparkles:': '✨',
  ':star:': '⭐',
  ':star_and_crescent:': '☪️',
  ':star_of_david:': '✡️',
  ':stop_button:': '⏹️',
  ':stopwatch:': '⏱️',
  ':sunny:': '☀️',
  ':taurus:': '♉',
  ':telephone:': '☎️',
  ':tent:': '⛺',
  ':thunder_cloud_and_rain:': '⛈️',
  ':thunder_cloud_rain:': '⛈️',
  ':timer_clock:': '⏲️',
  ':timer:': '⏲️',
  ':tm:': '™️',
  ':next_track:': '⏭️',
  ':track_next:': '⏭️',
  ':previous_track:': '⏮️',
  ':track_previous:': '⏮️',
  ':umbrella2:': '☂️',
  ':umbrella:': '☔',
  ':funeral_urn:': '⚱️',
  ':urn:': '⚱️',
  ':v:': '✌️',
  ':virgo:': '♍',
  ':warning:': '⚠️',
  ':watch:': '⌚',
  ':wavy_dash:': '〰️',
  ':wheel_of_dharma:': '☸️',
  ':wheelchair:': '♿',
  ':white_check_mark:': '✅',
  ':white_circle:': '⚪',
  ':white_large_square:': '⬜',
  ':white_medium_small_square:': '◽',
  ':white_medium_square:': '◻️',
  ':white_small_square:': '▫️',
  ':writing_hand:': '✍️',
  ':x:': '❌',
  ':yin_yang:': '☯️',
  ':zap:': '⚡',
};
export default emojis;
